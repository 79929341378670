// React
import React, { useState } from "react";
import { Flex, Box, Text, Icon, useColorModeValue } from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
// Components
import '../../../../css/main.css';

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function LogoutCard({ nowComponent, setNowComponent, logout }) {
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    // 닫기 애니메이션 닫을 컴포넌트에 ${effect} 
    const [effect, setEffect] = useState('mount1');
    const onClickBtn = () => {
        setEffect('unmount');
        setTimeout(() => {
            setNowComponent('');
        }, 400)
    };
    //슬라이드 내려서 닫기
    let startX = 0;
    let startY = 0;

    const handleTouchStart = (e) => {
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
    };

    const handleTouchEnd = (e) => {
        const endX = e.changedTouches[0].clientX;
        const endY = e.changedTouches[0].clientY;

        const deltaX = Math.abs(endX - startX);
        const deltaY = endY - startY;

        if (deltaX < 100 && deltaY > 50) {
            setEffect('unmount');
            setTimeout(() => {
                setNowComponent('');
            }, 400)
        }
    };


    return (
        <div className={`slide-up-container ${effect} ${nowComponent === '' ? 'slide-up-content-closed' : ''}`}>
            <div className="slide-up-content" >
                <Box
                    onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}
                    width='100%'
                    height='100vh'
                    position='relative'
                    align='center'
                >
                    <Icon className="backArrow" onClick={() => onClickBtn('')} color='black' as={BsArrowLeft} />
                    <Flex position='relative' top='20vh' w='90%' direction='column'>

                        <Text
                            className="logoutTitleText">
                            종료 하시겠습니까?
                        </Text>
                        <Box
                            className="logoutBtn"
                            _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                            _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                            _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                            shadow={shadow}
                             backgroundColor= 'rgba(22, 204, 234, 1)' 
                            border='1px solid rgba(217, 217, 217, 1)'
                            textColor='white'
                            align='center'
                            mx='auto'
                            onClick={() => { logout() }}
                        >
                            < Text
                                className="logoutBtnText"
                            >
                                네
                            </Text>
                        </Box>
                    </Flex>
                </Box>
            </div>
        </div>
    );
}
