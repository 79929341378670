// React
import React, { useState, useEffect } from "react";
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Box, Modal, ModalOverlay, ModalContent, Image, Button } from "@chakra-ui/react";
import { BsArrowLeft, BsBag } from "react-icons/bs";
import eternalsCatBlack from "../../../../../assets/img/service/eternalsCatBlack.png"
import Card from "../../../../../components/card/Card";
/* global BigInt */

// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function ProductModal({ titleText, onClose, productInfo, handleAddToCart, totalDiscountAmount, totalDiscountPercent, setStoreComponent, totalQuantity, optionDetail, optionGroup, optionMapping, handleAddToOption, orderOption, productOptionLists, notice }) {

  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const [productAmount, setProductAmount] = useState(0);
  const [noDiscountAmount, setNoDiscountAmount] = useState(0);
  useEffect(() => {
    setNoDiscountAmount(productInfo.price);
    if (totalDiscountAmount) {
      setProductAmount(Number(productInfo.price) - Number(totalDiscountAmount) > 0 ? (Number(productInfo.price) - Number(totalDiscountAmount)) : 0)
    } else if (totalDiscountPercent) {
      setProductAmount((Number(productInfo.price) * (100 - Number(totalDiscountPercent)) / 100) > 0 ? (parseInt((Number(productInfo.price) * (100 - Number(totalDiscountPercent)) / 100))) : 0)
    } else {
      setProductAmount(productInfo.price);
    }
    if (orderOption.length > 0) {
      for (var i = 0; i < orderOption.length; i++) {
        const amount = Number(orderOption[i].additional_price);
        if (amount > 0) {
          setProductAmount((prev) => BigInt(prev) + BigInt(amount));
          setNoDiscountAmount((previous) => BigInt(previous) + BigInt(amount));
        }
      }
    }
  }, [orderOption])

  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalOverlay />
        <ModalContent
          className="table-box"
          my='auto'
          width='100%'
          minH='100vh'
          pb='10vh'
          border='1px solid'
          borderColor='white'>
          <Flex
            position='fixed' height='5%'
            backgroundColor='white'
            borderBottom='1px solid gray' left='0%'
            fontSize='150%' align='center' justify='space-between' width='100%' px='5%' >
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} w='19px' h='24px' onClick={onClose} color='black' as={BsArrowLeft} />
            <Text fontWeight='700' fontSize='20px'>
              {titleText}
            </Text>
            <Box height="100%" position="relative" display="inline-block"
              mt='5%'
              onClick={() => {
                onClose();
                setStoreComponent('detail');
              }}
            >
              <Icon as={BsBag} zIndex="1" boxSize="24px" />
              <Text
                color="red"
                zIndex="2"
                position="absolute"
                top="-5px"
                right="-5px"
                fontSize="12px"
                bg="white"
                borderRadius="50%"
                px="5px"
              >
                {(totalQuantity).toString() || 0}
              </Text>
            </Box>

          </Flex>
          <Box mt='5vh'>
            <Box>
              {
                productInfo.image ?
                  <Image src={`data:image/*;base64,${productInfo.image}`} alt='' w='100%' h='100%' maxH='40vh' />
                  :
                  <Image src={eternalsCatBlack} alt='' w='100%' h='100%' maxH='40vh' />
              }
              <Flex width="100%" direction='column' fontSize='20px' textAlign='center' my='10%'>
                <Box borderBottom='1px solid gray' >
                  <Text
                    whiteSpace='nowrap'
                    textOverflow='ellipsis'
                    fontWeight='700'
                    mb='3%'
                    isTruncated>
                    {productInfo.name}
                  </Text>
                  <Flex justify='space-between' width='90%' mx='auto' fontSize='16px' fontWeight='700'>
                    <Text>
                      가격
                    </Text>
                    <Text>
                      {(productInfo.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}원
                    </Text>
                  </Flex>
                </Box>

                <Box>
                  {
                    productOptionLists && productOptionLists.length > 0 ?
                      <>
                        <Text textAlign='left' width='90%'
                          mx='auto' my='1%' fontSize='17px' fontWeight='700'>
                          옵션선택
                        </Text>
                        {
                          productOptionLists.map((group, index) => (
                            <Box
                              key={index}
                              fontSize='16px'

                              style={notice && group.option_group_type === 'required' ?
                                { border: '1px solid red' }
                                :
                                {}
                              }

                              borderBottom='1px solid gray'
                              py='10px'>
                              <Flex direction='column' textAlign='left' width='90%'
                                mx='auto'>
                                <Text>
                                  {
                                    group.option_group_type === 'required' ?
                                      "* " + group.option_group_name
                                      :
                                      group.option_group_name
                                  }
                                </Text>
                                <Box textColor='black'>
                                  <Flex direction='column'>
                                    {
                                      optionDetail && optionDetail
                                        .filter(e => e.option_group_id === group.option_group_id)
                                        .map((option, ind) => (
                                          <Flex justify='space-between' key={ind}
                                            onClick={() => handleAddToOption(option, group)}
                                          >
                                            <label>
                                              <input
                                                type={group.option_group_type === 'required' ? 'radio' : 'checkbox'}
                                                value={option.option_id}
                                                checked={!!orderOption.find(e => e.option_id === option.option_id)}
                                                onChange={(e) => e.stopPropagation()}
                                              >
                                              </input>
                                            </label>
                                            <Text>
                                              {option.option_name}
                                            </Text>
                                            <Text>
                                              +{(option.additional_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                                            </Text>
                                          </Flex>
                                        ))
                                    }
                                  </Flex>
                                </Box>
                              </Flex>
                            </Box>
                          ))
                        }
                      </>
                      :
                      null
                  }

                </Box>
                {
                  totalDiscountAmount || totalDiscountPercent ?
                    <Box width='100%' height='100%'>
                      <Flex textAlign='center' my='3%' justify='space-evenly' width='90%' mx='auto'>
                        <Text fontSize='16px' mr='auto' fontWeight='700'>
                          할인 금액 :
                        </Text>
                        <Text
                          ml='auto'
                          color='gray'
                          textDecoration='line-through'
                          fontSize='16px'
                          fontWeight='700'
                        >
                          &nbsp;{(noDiscountAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0} &nbsp;
                        </Text>
                        <Text
                          color='#E09EF5'
                          fontSize='16px'
                          fontWeight='700'
                        >
                          {
                            totalDiscountAmount ?
                              `- ${Number(totalDiscountAmount).toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`
                              :
                              `${Number(totalDiscountPercent)}%`
                          }
                        </Text>
                      </Flex>
                    </Box>
                    :
                    null
                }
                <Flex justify='space-between' fontSize='24px'
                  fontWeight='700'
                  width='90%'
                  mx='auto'
                  my='10px'>
                  <Text>
                    최종 금액
                  </Text>
                  <Text>
                    {
                      (productAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원"
                    }
                  </Text>
                </Flex>
              </Flex>
              < Flex
                align='center'
                width='95%'
                height='6vh'
                mx='auto'
                justify='space-between'
                position='fixed'
                bottom='5%'
                left='2.5%'
              >
                <Button
                  height='100%'
                  backgroundColor='#A54BFF'
                  width='19%'
                  borderRadius='0px'
                  disabled={productInfo.stock === '0' ? true : false}
                  onClick={() => {
                    handleAddToCart(productInfo, 'off')
                  }}>
                  <Icon as={BsBag} color='white' />
                </Button>
                <Button
                  height='100%'
                  backgroundColor='black'
                  width='79%'
                  borderRadius='0px'
                  textColor='white'
                  disabled={productInfo.stock === '0' ? true : false}
                  onClick={() => {
                    handleAddToCart(productInfo, 'on')
                  }}>
                  {
                    productInfo.stock === '0' ?
                      "품절"
                      :
                      "바로 주문"
                  }
                </Button>
              </Flex>

            </Box>
          </Box>
        </ModalContent>
      </Modal >
    </div >
  );
}