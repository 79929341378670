// React
import React from "react";
import {  Box, Image, Text} from "@chakra-ui/react";
// Components
import '../../../../css/main.css';
import loading2 from "../../../../assets/img/service/loading2.gif"

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function AccountCheck({ setAccountComponent }) {

    return (
        <>
            <Box
                width='100vw'
                height='100vh'
                align='center'
                top='0'
                position='absolute'
                mx='auto'
                textAlign='left'
                backgroundColor='white'
                zIndex='5000'
            >

                <Box align='center'
                    mx='auto'
                    mt='30vh'>
                    <Text
                        className="accountNoticeText"
                        textColor='#004A56'
                    >
                        계좌를 확인 중입니다.
                    </Text>

                    <Image src={loading2} width='270px' height='146px' onClick={() => setAccountComponent('finish')} />
                </Box>

            </Box >
        </>

    );
}
