// React
import React, { useState } from "react";
import { Flex, Box, Image, Text, Input, InputGroup, Icon } from "@chakra-ui/react";
// Components
import '../../../css/main.css';
import '../../../css/Page.css';

import Card from "../../../components/card/Card.js";
import smile from "../../../assets/img/service/smile.png"
import qrSmall from "../../../assets/img/service/qrSmallImage.png"
//external
import { BsArrowLeft } from "react-icons/bs";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function FriendCard({ setNowComponent, userList, userInfo, recentFriend, setTransferReceiver }) {
   
    const [searchList, setSearchList] = useState([]);

    // input data
    const [inputs, setInputs] = useState({
        friend: ""
    });
    // input variable
    const { friend } = inputs;
    function onChange(e) {
        if (e.target.value !== '<') {
            const { value, name } = e.target;
            setInputs({
                ...inputs,
                [name]: value,
            });
        } // if else end
    } // fn onChange end
    function findFriend() {
        const filteredList = userList.filter((member) =>
            member.name.toLowerCase().includes(friend.toLowerCase())
        );
        setSearchList(filteredList);
    }

    return (
        <>
            <Box
                align='center'
                w='90%'
                mx='auto'
                mt='3vh'
                textAlign='left'
            >

                <Box height={friend ? '30vh' : '50vh'}>
                    <Flex direction='column' height='20vh' position='relative'>
                        <Icon className="insideBackArrow" onClick={() => setNowComponent('')} color='black' as={BsArrowLeft} />
                        <Text fontSize='20px' fontWeight='700' mr='auto'>
                            누구에게 보낼까요?
                        </Text>
                        <InputGroup my='2vh' size='md'>
                            <Input
                                w='100%'
                                borderRadius='0'
                                border="1px solid" // 밑줄 두께 조절
                                borderColor="#008399" // 밑줄 색상 조절
                                variant='auth'
                                fontSize='md'
                                fontWeight='400'
                                size='md'
                                id="friend"
                                name="friend"
                                value={friend}
                                onChange={onChange}
                                onKeyUp={findFriend}>
                            </Input>
                        </InputGroup>

                        <Flex height='3vh' position='relative'>
                            <Image src={qrSmall} w='25px' h='23px' />
                            <Text
                                fontSize='14px'
                                fontWeight='400'
                                decoration='underline'
                                onClick={() => { setNowComponent('qrScan') }}
                            >
                                QR 스캔하여 보내기
                            </Text>
                        </Flex>
                    </Flex>



                    {
                        friend ?
                            null
                            :
                            <Flex direction='column' height='24vh' mt={{ base: '3vh', md: '7vh' }} position='relative'>
                                <Text fontSize='14px' fontWeight='700'>
                                    최근 송금
                                </Text>
                                <Box h={{ base: '20vh' }} minH={'85px'} overflowY='auto' className='screen-box'>
                                    {
                                        recentFriend.map((item, index) => (
                                            <Card
                                                h={{ base: '40px' }}
                                                borderRadius='5'
                                                alignSelf='center'
                                                mx='auto'
                                                mb='1%'
                                                p='1%'
                                                width='100%'
                                                key={index}
                                                isTruncated
                                                onClick={() => {
                                                    setTransferReceiver(item);
                                                    setNowComponent('payment');
                                                }}
                                            >
                                                <Flex align='center' fontSize='80%'>
                                                    <Image w='27px' h='27px' src={smile} mr='3%' />
                                                    <Flex direction='column' width='80%'>
                                                        <Flex justifyContent='space-between'>
                                                            <Text >
                                                                {item.name}
                                                            </Text>
                                                            <Text>
                                                                {item.email}
                                                            </Text>
                                                        </Flex>
                                                        <Text textAlign='right'>
                                                            {(item.phone)}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            </Card>
                                        ))
                                    }
                                </Box>
                            </Flex>
                    }
                </Box>

                {
                    friend ?
                        <Box height='60%'>
                            <Text fontSize='14px' fontWeight='700'>
                                검색내역
                            </Text>
                            <Box height='55vh' overflowY='auto' className='screen-box' minH='130px'>
                                {
                                    searchList.map((item, index) => (
                                        <Card
                                            h='40px'
                                            borderRadius='5'
                                            alignSelf='center'
                                            mx='auto'
                                            mb='1%'
                                            p='1%'
                                            width='100%'
                                            key={index}
                                            isTruncated
                                            setTransferReceiver
                                            onClick={() => {
                                                setTransferReceiver(item);
                                                setNowComponent('payment');
                                            }}
                                        >
                                            <Flex align='center' fontSize='80%' >
                                                <Image w='27px' h='27px' src={smile} mr='3%' />
                                                <Flex direction='column' width='80%'>
                                                    <Flex justifyContent='space-between'>
                                                        <Text >
                                                            {item.name}
                                                        </Text>
                                                        <Text>
                                                            {item.email}
                                                        </Text>
                                                    </Flex>
                                                    <Text textAlign='right'>
                                                        {(item.phone)}...
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Card>
                                    ))
                                }
                            </Box>
                        </Box>
                        :
                        <>
                            <Box height='40vh'>
                                <Text fontSize='14px' fontWeight='700'>
                                    직원
                                </Text>
                                <Box height='35vh' overflowY='auto' className='screen-box'>
                                    {
                                        userList.map((item, index) => (
                                            <Card
                                                h='40px'
                                                borderRadius='5'
                                                alignSelf='center'
                                                mx='auto'
                                                mb='1%'
                                                p='1%'
                                                width='100%'
                                                key={index}
                                                isTruncated
                                                onClick={() => {
                                                    setTransferReceiver(item);
                                                    setNowComponent('payment');
                                                }}
                                            >
                                                <Flex align='center' fontSize='80%' >
                                                    <Image w='27px' h='27px' src={smile} mr='3%' />
                                                    <Flex direction='column' width='80%'>
                                                        <Flex justifyContent='space-between'>
                                                            <Text >
                                                                {item.name}
                                                            </Text>
                                                            <Text>
                                                                {item.email}
                                                            </Text>
                                                        </Flex>
                                                        <Text textAlign='right'>
                                                            {(item.phone)}
                                                        </Text>
                                                    </Flex>
                                                </Flex>
                                            </Card>
                                        ))
                                    }
                                </Box>
                            </Box>
                        </>
                }
            </Box>
        </>
    );
}
