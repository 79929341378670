// React
import React, { useState } from "react";
import { Flex, Box, Text, Icon } from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
// Components
import '../../../../../css/main.css';
import SeatTable from "../table/SeatTable";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function BookCard({ onClose, BookModalOpen, mySeatList, setSearchModalOpen, setSearchUserInfo }) {

    // 닫기 애니메이션 닫을 컴포넌트에 ${effect} 
    const [effect, setEffect] = useState('mount1');
    const onClickBtn = () => {
        setEffect('unmount');
        setTimeout(() => {
            onClose();
        }, 400)
    };
    //슬라이드 내려서 닫기
    let startX = 0;
    let startY = 0;

    const handleTouchStart = (e) => {
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
    };

    const handleTouchEnd = (e) => {
        const endX = e.changedTouches[0].clientX;
        const endY = e.changedTouches[0].clientY;

        const deltaX = Math.abs(endX - startX);
        const deltaY = endY - startY;

        if (deltaX < 100 && deltaY > 50) {
            setEffect('unmount');
            setTimeout(() => {
                onClose();
            }, 400)
        }
    };





    return (
        <div className={`slide-up-container ${effect} ${BookModalOpen ? 'slide-up-content-closed' : ''}`}>
            <div className="slide-up-content" >
                <Box
                    onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}
                    width='100%'
                    height='100%'
                    overflow='hidden'
                    position='relative'
                    align='center'
                >
                    <Icon className="backArrow" onClick={() => onClickBtn()} color='black' as={BsArrowLeft} />
                    <Flex position='relative' top='7vh' w='90%' direction='column'>
                        <Text
                            className="assetMainText"
                            mr='auto'
                            mt='3vh'
                        >
                            예약 내역
                        </Text>
                    </Flex>

                    <Box position='relative' top='10vh' w='90%' >
                        <SeatTable
                            SeatList={mySeatList}
                            setSearchModalOpen={setSearchModalOpen} setSearchUserInfo={setSearchUserInfo}
                        />
                    </Box>

                </Box>
            </div>
        </div>
    );
}
