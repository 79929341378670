// React
import React from "react";
import { Flex, Box, Image, Text} from "@chakra-ui/react";
// Components
import '../../../../css/main.css';
import charging1 from "../../../../assets/img/service/charging1.gif"

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function MintLoding({ }) {
    // A variable to use function variables from store.js


    return (
        <>
            <Box width='100%'
                height='100%'
                align='center'
                position='relative'
            >

                <Flex className="mintLoadingBoldText"
                    align='center'
                    justify='center'
                    mt='20vh'
                    mb='10vh'
                    textColor='#004A56'
                    h='5vh'
                >
                    <Text>
                        mint
                    </Text>
                    <Text className="mintLoadingText">
                        &nbsp;(충전)&nbsp;
                    </Text>
                    <Text>
                        중 입니다.
                    </Text>
                </Flex>
                <Image src={charging1}className="mintLoadingImage" />
            </Box>
        </>

    );
}
