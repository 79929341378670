// React
import React from "react";
import { Flex, Box, Text, Button, Icon } from "@chakra-ui/react";
// Components
import '../../../../css/main.css';
import { BsArrowLeft } from "react-icons/bs";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function ChooseMethod({ setNowComponent, userInfo }) {


    return (
        <>

            <Icon className="backArrow" onClick={() => setNowComponent('')} color='black' as={BsArrowLeft} />
            <Box
                width='100%'
                height='100%'
                align='center'
                position='relative'
            >
                <Flex
                    className="mintTitleBox"
                    direction='column' align='center'>
                    <Flex >
                        <Text
                            className="mintMainText"
                        >
                            KRCD를 mint(
                        </Text>
                        <Text
                            className="mintMainSmallText"
                        >
                            충전
                        </Text>
                        <Text
                            className="mintMainText"
                        >
                            ) 해볼까요?
                        </Text>
                    </Flex>

                    <Button
                        mt='15vh'
                        mb='30px'
                        backgroundColor='#16CCEA'
                        className='mintMenuBtn'
                        onClick={() => { setNowComponent('mintInsert') }}
                    >
                        <Text
                            color='white'
                        >
                            입금 mint
                        </Text>
                    </Button>
                    <Button

                        backgroundColor='#11B1CC'
                        className='mintMenuBtn'
                        onClick={() => { setNowComponent('developing') }}
                        // pointerEvents='none'
                    >
                        <Flex
                            textColor='white'
                            direction='column'
                        >
                            <Text
                            >
                                계좌 결제 mint
                            </Text>
                            <Text
                                className="mintDevelopingText">
                                (개발중)
                            </Text>
                        </Flex>
                    </Button>


                </Flex >

            </Box >
        </>

    );
}
