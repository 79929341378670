// React
import React from "react";
import {  Text, Icon, Flex, Button, Modal, useColorModeValue, ModalContent } from "@chakra-ui/react";
// Components
import '../../../../css/Page.css';
import { BsXLg, BsCircleFill } from "react-icons/bs";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
*/
export default function SearchModal({ onClose, searchUserInfo }) {
    // Variables for Card CSS Styling
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
    );
    const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.200" }
    );

    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    return (
        <div className='modal'>
            <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
                <ModalContent
                    my='auto'
                    className='sirenOrderModal'
                    border='1px solid'
                    borderColor='white'
                    backgroundColor='rgba(229, 244, 255, 1)'
                    backgroundRepeat='no-repeat'
                    bgSize='cover'
                    bgPosition='10%'
                    textColor='black'
                >
                    <Flex>
                        <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
                    </Flex>

                    <Flex
                        className='modalMainCloseText'
                        width='100%'
                        mb='3%'
                        align='center'
                        direction='column'
                    >
                        <Text
                        >
                            [ {searchUserInfo.name} 정보 ]
                        </Text>
                    </Flex>

                    <Flex
                        mt='5%'
                        className='modalMainInsideDefaultText'
                        justifyContent='space-evenly'
                    >
                        <Flex
                            direction='column'
                            width='80%'
                        >
                            <Flex justifyContent='space-between'>
                                <Flex align='center'>
                                    <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                                    <Text>
                                        층
                                    </Text>
                                </Flex>
                                <Text
                                    fontWeight='700'
                                    textColor='rgba(2, 58, 142, 1)'
                                >
                                    {searchUserInfo.flore}
                                </Text>
                            </Flex>
                            <Flex justifyContent='space-between'>
                                <Flex align='center'>
                                    <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                                    <Text
                                    >
                                        좌석명
                                    </Text>
                                </Flex>
                                <Text
                                    fontWeight='700'
                                    textColor='rgba(2, 58, 142, 1)'
                                >
                                    {searchUserInfo.seat_name}
                                </Text>
                            </Flex>
                            <Flex justifyContent='space-between'>
                                <Flex align='center'>
                                    <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                                    <Text
                                    >
                                        시작시간
                                    </Text>
                                </Flex>
                                <Text
                                    fontWeight='700'
                                    textColor='rgba(2, 58, 142, 1)'
                                >
                                    {searchUserInfo.start_date} {searchUserInfo.start_time}
                                </Text>
                            </Flex>
                            <Flex justifyContent='space-between'>
                                <Flex align='center'>
                                    <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                                    <Text
                                    >
                                        종료시간
                                    </Text>
                                </Flex>
                                <Text
                                    fontWeight='700'
                                    textColor='rgba(2, 58, 142, 1)'
                                >
                                    {searchUserInfo.finish_date? searchUserInfo.finish_date: "종료 전 입니다."} {searchUserInfo.finish_date? searchUserInfo.finish_time:""}
                                </Text>
                            </Flex>
                        </Flex>

                    </Flex>

                    <Flex
                        mt='auto'
                        mb='3vh'
                        justify='space-around'
                    >

                        <Button
                            className='modalMainSmallBtn'
                            _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                            _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                            _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                            shadow={shadow}
                            backgroundColor='rgba(22, 204, 234, 1)'
                            border='1px solid rgba(217, 217, 217, 1)'
                            textColor='white'
                            align='center'
                            onClick={() => {
                                onClose();
                            }}
                        >
                            < Text
                                className='modalMainSmallBtnText'
                                mx='auto'
                            >
                                확인
                            </Text>
                        </Button>

                    </Flex>

                </ModalContent>
            </Modal>
        </div>
    );
}