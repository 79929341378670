// React
import React from "react";

// External Libraries
import { Button, Flex, FormControl, Icon, Input, InputGroup, InputRightElement, Text, useColorModeValue } from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

export default function StepOneUser({ onChange, password, passwordConfirm, email, name, emailButtonText, emailInputCheck, emailVerify, emailCode }) {

    const textColorSecondary = "gray.400";

    const [show, setShow] = React.useState(false);
    const [show2, setShow2] = React.useState(false);
    const handleClick = () => setShow(!show);
    const handleClick2 = () => setShow2(!show2);
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    return (
        <>
            <Flex
                zIndex='1'
                direction='column'
                mb="20px">
                <FormControl>
                    <Flex direction='column'>
                        <Flex justify='space-around'>

                            {/* <InputGroup width='100%'> */}

                            <Input
                                className="signUpOneEmailInput signUpInput"
                                isRequired={true}
                                variant='auth'
                                type='text'
                                placeholder='Email'
                                borderRadius='0'
                                id="email"
                                name="email"
                                value={email}
                                onChange={onChange} />
                            {/* <InputRightElement className="signUpOneEmailBtn" alignItems='center'> */}
                            {
                                emailButtonText === "이메일\n오류"
                                    ?
                                    <Button
                                        className="signUpOneEmailBtnSize"
                                        backgroundColor='rgba(216, 230, 255, 1)'
                                        boxShadow={shadow} align='center'
                                        my='auto'
                                        borderRadius='0'>
                                        <Text align='center' color='red'>
                                            {emailButtonText.split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index !== array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                        </Text>
                                    </Button>
                                    :
                                    emailButtonText === '완료' ?
                                        <Button
                                            className="signUpOneEmailBtnSize"
                                            backgroundColor='rgba(216, 230, 255, 1)'
                                            my='auto'
                                            boxShadow={shadow} align='center' borderRadius='0' disabled='disabled' >
                                            <Text align='center' color='rgba(0, 139, 255, 1)'>
                                                {emailButtonText.split('\n').map((line, index, array) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        {index !== array.length - 1 && <br />}
                                                    </React.Fragment>
                                                ))}
                                            </Text>
                                        </Button>
                                        :
                                        <Button
                                            className="signUpOneEmailBtnSize"
                                            backgroundColor='rgba(216, 230, 255, 1)'
                                            boxShadow={shadow} align='center'
                                            my='auto' borderRadius='0' onClick={emailInputCheck}>
                                            <Text align='center' color='rgba(0, 139, 255, 1)'>
                                                {emailButtonText.split('\n').map((line, index, array) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        {index !== array.length - 1 && <br />}
                                                    </React.Fragment>
                                                ))}
                                            </Text>
                                        </Button>
                            }
                            {/* </InputRightElement>
                            </InputGroup> */}


                        </Flex>
                    </Flex>
                    {
                        emailCode !== '' ?
                            <>
                                <Text
                                    className="signUpPwdText"
                                >
                                    인증번호를 입력하세요.
                                </Text>
                                <InputGroup className="signUpPwdBox" >
                                    <Input
                                        className="signUpOnePwdInput signUpInput"
                                        isRequired={true}
                                        fontSize='sm'
                                        placeholder='code'
                                        type="text"
                                        variant='auth'
                                        id="emailVerify"
                                        name="emailVerify"
                                        value={emailVerify}
                                        borderRadius='0'
                                        onChange={onChange} />
                                </InputGroup>
                            </>

                            :
                            null
                    }
                    <Input
                        className="signUpOneNameInput signUpInput"
                        isRequired={true}
                        variant='auth'
                        fontSize='sm'
                        type='text'
                        placeholder='Name'
                        fontWeight='500'
                        id="name"
                        name="name"
                        value={name}
                        borderRadius='0'
                        onChange={onChange} />

                    <Text
                        className="signUpPwdText"
                    >
                        비밀번호 8글자 이상 입력하세요.
                    </Text>
                    <InputGroup className="signUpPwdBox" >
                        <Input
                            className="signUpOnePwdInput signUpInput"
                            isRequired={true}
                            fontSize='sm'
                            placeholder='Password'
                            type={show ? "text" : "password"}
                            variant='auth'
                            id="password"
                            name="password"
                            value={password}
                            borderRadius='0'
                            onChange={onChange} />
                        <InputRightElement display='flex' className="signUpPwdEye" alignItems='center' mt='0px'
                        >
                            <Icon
                                color={textColorSecondary}
                                _hover={{ cursor: "pointer" }}
                                as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                onClick={handleClick} />
                        </InputRightElement>
                    </InputGroup>
                    <InputGroup className="signUpPwdBox">
                        <Input
                            className="signUpOnePwdInput signUpInput"
                            isRequired={true}
                            fontSize='sm'
                            placeholder='Password Confirm'
                            type={show2 ? "text" : "password"}
                            variant='auth'
                            id="passwordConfirm"
                            name="passwordConfirm"
                            value={passwordConfirm}
                            borderRadius='0'
                            onChange={onChange} />
                        <InputRightElement display='flex' className="signUpPwdEye" alignItems='center' mt='0px'>
                            <Icon
                                color={textColorSecondary}
                                _hover={{ cursor: "pointer" }}
                                as={show2 ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                onClick={handleClick2} />
                        </InputRightElement>
                    </InputGroup>

                </FormControl>
            </Flex >
        </>
    )

}