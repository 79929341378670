import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './css/App.css';
import './css/phone/Android.css';
import './css/phone/Iphone.css';

// External Libraries
//component
import LoginPage from './views/login';
import ServiceView from './views/service';
import FindPwd from './views/findPwd';
import SignupView from './views/signUp';
import LodingFirst from './views/LodingFirst.js';
import veta from './assets/img/service/veta_banana.png';
import Mobile from './views/Mobile.js';
function App() {
  const [viewComponent, setViewComponent] = useState('');
  const [token, setToken] = useState(localStorage.getItem('jwtToken'));
  const [sirenOrder, setSirenOrder] = useState();
  const [sirenPopUp, setSirenPopUp] = useState(false);
  const [sseConnect, setSseConnect] = useState(false);
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }


  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    const checkPWA = () => {
      const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
      const isPWA = isStandalone || document.referrer.startsWith('android-app://');

      setIsPWA(isPWA);
    };

    checkPWA();
    window.addEventListener('resize', checkPWA); // In case the display mode changes
    return () => window.removeEventListener('resize', checkPWA);
  }, []);



  /// 화며배율
  const [mobile, setMobile] = useState(window.innerWidth <= 768);
  useState(() => {
    setMobile(window.innerWidth <= 768);
  }, [window.innerWidth])
  function mobileCheck() {
    setMobile(window.innerWidth <= 768);
  }

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);
  const handleBeforeInstallPrompt = (event) => {
    event.preventDefault();
    setDeferredPrompt(event);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // console.log("agreed");
        } else {
          // console.log("ignored");
        }

        // setDeferredPrompt(null);
      });
    }
  };

  /////

  useEffect(() => {
    // sbchoi Add
    if (typeof Notification !== 'undefined') {
      if (Notification.permission === "default") {
        Notification.requestPermission()
      };
    }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
    }
  }, []); // 빈 배열을 전달하여 한 번만 실행되도록 설정
  useEffect(() => {
    var text = window.location.href;
    if (text.includes('3000')) {
      text = text.replace('3000', '7050');
    }
    const url = text + "api/order/reg/" + token;
    var eventSource = new EventSource(url);
    eventSource.addEventListener("order", (event) => {
      if (sirenPopUp) {
        setSirenPopUp(false);
        setSirenOrder();
      }
      var message = JSON.parse(event.data);
      setSirenOrder(message);
      setSirenPopUp(true);

      const body = (
        message.status === 'ongoing' ?
          "주문이 접수되었습니다."
          :
          message.status === 'complete' ?
            "상품이 완료되었습니다."
            :
            message.status === 'reject' ?
              "주문이 취소되었습니다."
              :
              "주문을 확인해주세요."
      );

      const notifTitle = "VETA PAY";
      const options = {
        body: body,
        icon: veta, // 아이콘 URL을 추가할 수 있습니다.
      };

      // 브라우저 알림 권한 요청
      if (Notification.permission === "granted") {
        navigator.serviceWorker.getRegistration().then(reg => {
          if (reg) {
            reg.showNotification(notifTitle, options);
          }
        });
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            navigator.serviceWorker.getRegistration().then(reg => {
              if (reg) {
                reg.showNotification(notifTitle, options);
              }
            });
          }
        });
      }
      // new Notification(notifTitle, options);

    });
    eventSource.addEventListener("connect", (event) => {
      let message = event.data;
      console.log("Welcome!" + message);
    });
    eventSource.addEventListener("error", function (event) {
      if (eventSource.readyState === EventSource.CLOSED) {
        // 재연결 로직 구현
        setTimeout(() => {
          eventSource = new EventSource(url);
        }, 3000); // 3초 후에 다시 연결 시도
      }
    });
    // return () => {
    //   eventSource.close(); // 컴포넌트가 언마운트될 때 SSE 연결을 닫습니다.
    // };
    return () => {

    };
    // }, [sseConnect, token])
  }, [sseConnect])

  useEffect(() => {
    const timer = setTimeout(() => {
      // 일정 시간이 지난 후 /api/로 이동
      if (viewComponent === '') {
        setViewComponent('Login');
      }
    }, 1000); // 1초 (1000ms) 설정

    // 컴포넌트가 언마운트될 때 타이머를 정리
    return () => clearTimeout(timer);
  }, [viewComponent]);

  // 일단 뒤로가기 막기
  window.history.pushState(null, null, null);
  //
  return (
    <>
      {
        // !isPWA ?
        !mobile || deferredPrompt?

          <Mobile deferredPrompt={deferredPrompt} handleInstallClick={handleInstallClick} />
          :
          viewComponent === 'Login' ?
            <LoginPage setViewComponent={setViewComponent} token={token} setToken={setToken} setSseConnect={setSseConnect} viewComponent={viewComponent} />
            :
            viewComponent === 'Service' ?
              <ServiceView setViewComponent={setViewComponent} viewComponent={viewComponent} token={token} setToken={setToken} sirenOrder={sirenOrder} sirenPopUp={sirenPopUp} setSirenPopUp={setSirenPopUp} />
              :
              viewComponent === 'FindPwd' ?
                <FindPwd setViewComponent={setViewComponent} />
                :
                viewComponent === 'SignUp' ?

                  <SignupView setViewComponent={setViewComponent} />
                  :
                  viewComponent === 'LodingSecond' ?

                    <LodingFirst setViewComponent={setViewComponent} />
                    :
                    <LodingFirst setViewComponent={setViewComponent} />

      }
    </>
  );
}

export default App;
