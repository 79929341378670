// React
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Flex, Box, Text, Icon } from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
// Components
import '../../../../css/main.css';
import QRCode from "react-qr-code";
import QRPayFinish from "./QRPayFinish";
import ScanLoading from "./ScanLoading";
import SecondPwd from "../confirm/SecondPwd";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function CodeCard({ nowComponent, setNowComponent, userInfo }) {
    const [codeComponent, setCodeComponent] = useState('');
    const [secondPwdCheck, setSecondPwdCheck] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [amount] = useState('');

    // A variable to use function variables from store.js
    const initialTime = 180; // 초 단위로 설정 (3분은 180초)
    const [time, setTime] = useState(initialTime);
    useEffect(() => {
        if (time === 0 && codeComponent !== 'confirm' && codeComponent !== 'finish') {
            setNowComponent('');
        }
    }, [time])

    useEffect(() => {
        // 1초마다 타이머 감소
        const timerInterval = setInterval(() => {
            setTime(prevTime => prevTime - 1);
        }, 1000);

        // 컴포넌트가 언마운트되거나 타이머가 종료되면 clearInterval 호출
        return () => clearInterval(timerInterval);
    }, []); // 빈 배열을 전달하여 최초 렌더링시에만 useEffect 실행

    // 초를 분:초 형태로 변환
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    useEffect(() => {
        if (secondPwdCheck) {
            axios.post('/api/account/transfer', {
                amount: amount.replace(/[^0-9]/g, "").replaceAll(',', ''),
                target: 'address'
            }, {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))}`,
            }).then((response) => {         // api return
                setCodeComponent('finish');
                setSecondPwdCheck(false);
            }).catch(error => {
                setAlertText('잠시 후 다시 시도해주세요.');
                setCodeComponent('');
                setSecondPwdCheck(false);
            })
        }
    }, [secondPwdCheck])


    return (
        codeComponent === 'finish' ?
            <QRPayFinish amount={amount} setNowComponent={setNowComponent} />
            :
            codeComponent === 'second' ?
                <SecondPwd setComponent={setCodeComponent} userInfo={userInfo} setSecondPwdCheck={setSecondPwdCheck} />
                :
                //     codeComponent === 'cancel' ?
                //         <CodeCancel setCodeComponent={setCodeComponent} setNowComponent={setNowComponent} />
                //         :
                codeComponent === 'loading' ?
                    <ScanLoading />
                    :
                    // codeComponent === 'confirm' ?
                    //     <CodeConfirm setCodeComponent={setCodeComponent} amount={amount} store={store} />
                    //     :
                    <div className={`slide-up-container ${nowComponent === '' ? 'slide-up-content-closed' : ''}`}>
                        <div className="slide-up-content">
                            <Box
                                width='100%'
                                height='75vh'
                                position='stikey'
                                align='center'
                            >
                                <Icon className="backArrow" onClick={() => setNowComponent('')} color='black' as={BsArrowLeft} />
                                {/* <Button
                                            onClick={() => setCodeComponent('confirm')}>
                                            test
                                        </Button> */}

                                <Box position='relative' top='15vh' w='90%'>
                                    <QRCode size='70%'  value={userInfo.address ? userInfo.address : userInfo.name} className="qrCodeImage" />
                                </Box>
                                <Flex position='relative' justify='center'
                                    className="qrScanCardText qrScanCardFirstTop">

                                    <Text >
                                        유효시간
                                    </Text>
                                    <Text mx='2vw' color='#D9D9D9'>
                                        |
                                    </Text>
                                    <Text color='#0094FF'>
                                        {formatTime(time)}
                                    </Text>
                                </Flex>
                                <Flex position='relative' justify='center'
                                    className="qrScanCardText qrScanCardSecondTop">
                                    {
                                        alertText === '' ?
                                            <Text>
                                                {/* 생성된 QR을 보여주세요. */}
                                            </Text>
                                            :
                                            <Text
                                                className="alertText"
                                                color='red'>
                                                {alertText}
                                            </Text>
                                    }
                                </Flex>
                            </Box>

                            <Flex
                                className="qrButtomBox"
                                backgroundColor='rgba(70, 209, 232, 0.38)'
                                borderRadius="20px 20px 0 0"
                                align='center'
                            >
                                <Box
                                    mx='auto'
                                    height='10vh'
                                    width='80%'
                                >
                                    <Flex
                                        justifyContent='space-around'
                                        align='flex-end'
                                        position='sticky'
                                    >
                                        <Flex
                                            mb='auto'
                                        >
                                            <Text
                                                color='#008399'
                                                className="mainBalanceText"
                                            >
                                                Balance
                                            </Text>
                                        </Flex>

                                        <Text
                                            className="mainAmountText"
                                        >
                                            {userInfo.amount && userInfo.amount !== 'error' ?
                                                (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                                :
                                                0
                                            }
                                        </Text>
                                        <Text
                                            className="mainKRDCText"
                                        >
                                            VETA
                                        </Text>
                                    </Flex>
                                </Box>
                            </Flex>
                        </div>
                    </div>
    );
}