// React
import React, { useState } from "react";
import { Flex, Box, Text, Icon } from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
// Components
import '../../../../css/main.css';
import TransactionTable from "../../table/TransactionTable.js";
import TransactionDetailCard from "./TransactionDetailCard.js";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function TransactionCard({ setNowComponent, userInfo, transactionList, nowComponent,setTxUpdate }) {
    // 닫기 애니메이션 닫을 컴포넌트에 ${effect} 
    const [effect, setEffect] = useState('');
    const [totalEffect, setTotalEffect] = useState('');
    const onClickBtn = () => {
        setTotalEffect('unmount');
        setTimeout(() => {
            setNowComponent('');
        }, 400)
    };

    const [txComponent, setTxComponent] = useState('');
    const [txDetail, setTxDetail] = useState([]);

    // 화면 아래로 내려서 닫기
    let startX = 0;
    let startY = 0;

    const handleTouchStart = (e) => {
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
        setEffect('');
    };

    const handleTouchEnd = (e) => {
        const endX = e.changedTouches[0].clientX;
        const endY = e.changedTouches[0].clientY;

        const deltaX = Math.abs(endX - startX);
        const deltaY = endY - startY;

        if (deltaX < 100 && deltaY > 50) {
            if (txComponent === 'detail') {
                setEffect('unmount');
                setTimeout(() => {
                    setTxComponent('');
                }, 400)
            }
        }
    };

    return (
        <div className={`slide-up-container ${totalEffect} ${nowComponent === '' ? 'slide-up-content-closed' : ''}`}>
            <div className="slide-up-content">
                <Box
                    onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}
                    width='100%'
                    height='100%'
                    position='relative'
                    align='center'
                >
                    <Icon className="backArrow" onClick={() => onClickBtn('')} color='black' as={BsArrowLeft} />
                    <Flex position='relative' top='7vh' w='90%' direction='column'>

                        <Text
                            mt='3vh'
                            className="assetMainText"
                            mr='auto'>
                            거래 내역
                        </Text>
                    </Flex>
                    {
                        txComponent === 'detail' ?
                            <Box  position='relative' top='10vh' w='90%' className={`${effect}`}>
                                <TransactionDetailCard userInfo={userInfo} transactionInfo={txDetail} setTxComponent={setTxComponent} setTxUpdate={setTxUpdate}/>
                            </Box>
                            :
                            <Box position='relative' top='10vh' w='90%'>
                                <TransactionTable
                                    userInfo={userInfo}
                                    transactionList={transactionList}
                                    setTxComponent={setTxComponent}
                                    setTxDetail={setTxDetail}
                                />
                            </Box>
                    }
                </Box>
            </div>
        </div>
    );
}
