// React
import React, { useMemo } from "react";
// Components
// Assets
import TransactionTableContent from "./TransactionTableContent.js";
import { columnsDataTransaction } from "../../../variables/transaction/columnsDataTransaction";
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function TransactionTable({ transactionList, userInfo,setTxComponent,setTxDetail }) {
    const columns = useMemo(() => columnsDataTransaction, [columnsDataTransaction]);
    const tableData = useMemo(() => transactionList, [transactionList]);
    return (
        <>
            <TransactionTableContent columns={columns} dFlag={3} data={tableData} userInfo={userInfo}
                setTxComponent={setTxComponent}
                setTxDetail={setTxDetail}
            />
        </>
    );
}
