import React, { useState, useEffect, useRef } from "react";
import { Flex, Box, Text, Icon } from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
import axios from "axios";
import { Button } from "react-bootstrap";
import ScanPay from "./ScanPay";
import { Html5Qrcode } from "html5-qrcode";

export default function ScanCard({ nowComponent, setNowComponent, userInfo, setTransferReceiver, transferReceiver }) {
    const [scanComponent, setScanComponent] = useState('');
    const [alertText, setAlertText] = useState('');
    const [videoInputDevices, setVideoInputDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const qrCodeScannerRef = useRef(null);

    const handleScanSuccess = async (decodedText) => {
        if (decodedText) {
            try {
                const response = await axios.post("/api/account/search", {
                    account_address: decodedText
                });
                if (!response.data.error) {
                    setScanComponent('scanPay');
                    setTransferReceiver({
                        account_address: response.data.account_address,
                        name: response.data.name
                    });
                } else {
                    console.error(response.data.error);
                }
            } catch (error) {
                setAlertText(error.message);
            }
        }
    };

    const handleScanError = (errorMessage) => {
        //console.error(errorMessage);
        //setAlertText('다시 시도해주세요.');
    };

    const startScanner = async (deviceId) => {
        try {
            if (qrCodeScannerRef.current && qrCodeScannerRef.current.getState() !== 0) { // 0: stopped
                await qrCodeScannerRef.current.stop();
            }

            qrCodeScannerRef.current = new Html5Qrcode("qr-reader");

            await qrCodeScannerRef.current.start(
                { deviceId: { exact: deviceId } },
                {
                    fps: 10,
                    qrbox: { width: 250, height: 250 }
                },
                handleScanSuccess,
                handleScanError
            );
        } catch (error) {
            //console.error(error);
            //setAlertText('스캐너를 시작하는 데 실패했습니다. 다시 시도해주세요.');
        }
    };

    useEffect(() => {
        const getDevices = async () => {
            const devices = await Html5Qrcode.getCameras();
            console.log("devices")
            console.log(devices);
            setVideoInputDevices(devices);
            if (devices.length > 0) {
                setSelectedDeviceId(devices[0].id);
                startScanner(devices[0].id);
            }
        };

        getDevices();

        return () => {
            if (qrCodeScannerRef.current) {
                qrCodeScannerRef.current.stop().then(() => {
                    qrCodeScannerRef.current.clear();
                }).catch((err) => {
                    console.error("스캐너를 중지하는 데 실패했습니다.", err);
                });
            }
        };
    }, []);

    const toggleCamera = () => {
        const currentIndex = videoInputDevices.findIndex(device => device.id === selectedDeviceId);
        const nextIndex = (currentIndex + 1) % videoInputDevices.length;
        const nextDeviceId = videoInputDevices[nextIndex].id;
        setSelectedDeviceId(nextDeviceId);
        startScanner(nextDeviceId);
    };
    return (
        scanComponent === 'scanPay' ?
            <ScanPay scanComponent={scanComponent} setScanComponent={setScanComponent} setNowComponent={setNowComponent} userInfo={userInfo} transferReceiver={transferReceiver} />
            :
            <div className={`slide-up-container ${nowComponent === '' ? 'slide-up-content-closed' : ''}`}>
                <div className="slide-up-content">
                    <Box
                        width='100%'
                        height='95vh'
                        position='stikey'
                        align='center'
                    >
                        <Icon className="backArrow" onClick={() => setNowComponent('')} color='black' as={BsArrowLeft} />

                        <Flex position='relative' top='10vh' w='100%' direction='column'>
                            <Flex
                                width='300px'
                                mx='auto'
                                mb='20px'
                                justify='space-around'
                            >
                                <Box backgroundColor='rgba(22, 204, 234, 1)'>
                                    <Button
                                        onClick={toggleCamera}
                                        className="qrScanCardBtn"
                                    >
                                        <Text
                                            color='white'
                                        >
                                            카메라 전환
                                        </Text>
                                    </Button>
                                </Box>
                                <Box backgroundColor='rgba(22, 204, 234, 1)'>
                                    <Button
                                        onClick={() => { setNowComponent('qrCode') }}
                                        className="qrScanCardBtn"
                                    >
                                        <Text color='white'>
                                            QR 코드 생성
                                        </Text>
                                    </Button>
                                </Box>
                            </Flex>
                            <Box
                                className="qrScanBox"
                                mx='auto'
                                backgroundColor='rgba(0, 0, 0, 0.3)'
                            >
                                <div id="qr-reader" style={{ width: '100%' }}></div>
                            </Box>
                            {
                                alertText !== '' ?
                                    <Text
                                        mx='auto'
                                        color='red'
                                        className="qrScanCardText"
                                        my='3vh'
                                    >
                                        {alertText}
                                    </Text>
                                    :
                                    <Text
                                        mx='auto'
                                        className="qrScanCardText"
                                        my='3vh'
                                    >
                                        코드를 스캔해주세요.
                                    </Text>
                            }

                        </Flex>
                        <Flex
                            className="qrButtomBox"
                            backgroundColor='rgba(70, 209, 232, 0.38)'
                            borderRadius="20px 20px 0 0"
                            align='center'
                        >
                            <Box mx='auto' height='10vh' width='80%'>
                                <Flex
                                    justifyContent='space-around'
                                    align='flex-end'
                                    position='sticky'
                                >
                                    <Flex mb='auto'>
                                        <Text color='#008399' className="mainBalanceText">
                                            Balance
                                        </Text>
                                    </Flex>
                                    <Text className="mainAmountText">
                                        {userInfo.amount && userInfo.amount !== 'error' ?
                                            (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                            :
                                            0
                                        }
                                    </Text>
                                    <Text className="mainKRDCText">
                                        VETA
                                    </Text>
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                </div>
            </div>
    );
}
