// React
import React, { useState } from "react";
import { Box} from "@chakra-ui/react";
// Components
import ChooseMethod from "./mint/ChooseMethod";
import '../../../css/main.css';

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function MintCard({ setNowComponent, userInfo }) {

    const [mintComponent, setMintComponent] = useState('');

    return (
        <>
            <Box
                width='100%'
                height='100%'
                align='center'
                position='relative'
            >
                {
                    mintComponent === '' ?
                        <ChooseMethod
                            userInfo={userInfo}
                            setNowComponent={setNowComponent}
                            mintComponent={mintComponent}
                            setMintComponent={setMintComponent}
                        />
                        :
                        null
                }


            </Box>
        </>

    );
}
