// React
import React, { useState, useEffect, useRef } from "react";
import { Flex, Box, Text, FormControl, Icon } from "@chakra-ui/react";
// Components
import '../../../../css/main.css';
import { BsArrowLeft } from "react-icons/bs";
import CircleInput from '../../../../components/secondPwd/secondPwd'
import axios from "axios";
import CryptoJS from 'crypto-js';

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function SecondPwd({ setComponent, userInfo, setSecondPwdCheck }) {
    function hashVisitorId(visitorId) {
        return CryptoJS.SHA256(visitorId).toString(CryptoJS.enc.Hex);
    }

    const [alertText, setAlertText] = useState('');
    const [alertAni, setAlertAni] = useState('');
    const inputRefs = useRef([]);
    const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);

    const handleInputChange = (index, value) => {
        setAlertText('');
        setAlertAni('');
        const newInputValues = [...inputValues];
        newInputValues[index] = value;

        // 입력이 있으면 다음 input으로 포커스 이동
        if (value && index < inputRefs.current.length - 1) {
            if (inputValues[index + 1] !== '') {
                newInputValues[index + 1] = '';
            }
            inputRefs.current[index + 1]?.focus();
        }

        setInputValues(newInputValues);
    };
    //  backspace 처리
    // 입력 포커스 이동 핸들링
    const handleKeyUp = (index, event) => {
        if (index !== 0 && event.keyCode === 8) { // Move focus to previous on backspace
            var passwordValues = [...inputValues];
            passwordValues[--index] = '';
            setInputValues(passwordValues);
            inputRefs.current[index].focus();
        }
    };

    //검증 단계
    useEffect(() => {
        const combinedString = inputValues.join('');
        if (inputValues[5] !== '') {
            for (var i = 0; i < 6; i++) {
                if (inputValues[i] === '' || inputValues[i].length !== 1) {
                    setInputValues(['', '', '', '', '', '']);
                    inputRefs.current[0].focus();
                    setAlertText('비밀번호를 확인하세요.')
                    setAlertAni('alertText');
                    return;
                }
            }
        }
        if (combinedString.length === 6) {
            axios.post('/api/user/second', {
                email: userInfo.email,
                inputValues: hashVisitorId(combinedString)
            })
                .then((response) => {
                    if (response.data === 1) {
                        setSecondPwdCheck(true);
                        setComponent('loading');
                        onclose(false);
                    } else {
                        setInputValues(['', '', '', '', '', '']);
                        inputRefs.current[0].focus();
                        setAlertText('비밀번호를 확인하세요.')
                        setAlertAni('alertText');
                    }
                })
                .catch((error) => {
                    setInputValues(['', '', '', '', '', '']);
                    inputRefs.current[0].focus();
                    setAlertText('다시 시도해주세요.')
                    setAlertAni('alertText');
                })


        }
    }, [inputValues])


    function clearDigit(index) {
        let passwordValues = [...inputValues];
        passwordValues[index] = '';
        setInputValues(passwordValues);
        inputRefs.current[index].focus();
    }



    return (
        <>
            <Box
                width='100vw'
                height='100vh'
                align='center'
                top='0'
                position='absolute'
                mx='auto'
                textAlign='left'
                backgroundColor='white'
                zIndex='5000'
            >
                <Icon className="backArrow" onClick={() => setComponent('')} color='black' as={BsArrowLeft} />
                <Flex
                    direction='column'>
                    <Flex width='90%' mx='auto' direction='column'>

                        <Text
                            mt='10vh'
                            className="secondBigText"
                        >
                            결제 비밀번호
                        </Text>
                    </Flex>
                    <Flex mt='5vh' width='80%' mx='auto'>
                        <Text
                            className="secondSmallText"
                        >
                            비밀번호 6자리를 입력해 주세요.
                        </Text>
                    </Flex>

                </Flex>


                <Box width='80%' align='center'
                    mx='auto'
                    mt='15vh'
                    p='2%'>
                    {
                        alertText ?
                            <Text
                                color='red'
                                className={`secondMiddleText ${alertAni}`}
                            >
                                {alertText}
                            </Text>
                            :
                            <Text
                                className="secondMiddleText"
                            >
                                결제 비밀번호 입력
                            </Text>
                    }
                    <FormControl mt='2vh'>
                        {inputValues.map((value, index) => (
                            <CircleInput
                                className={`secondPwd ${alertAni}`}
                                key={index}
                                type="number"
                                maxLength="1"
                                value={value}
                                hasValue={value !== ''}
                                onKeyUp={(e) => handleKeyUp(index, e)}
                                inputMode="numeric"
                                pattern="\d*"
                                onClick={() => { clearDigit(index) }}
                                onChange={(e) => handleInputChange(index, e.target.value)}
                                ref={(el) => (inputRefs.current[index] = el)}
                            />
                        ))}
                    </FormControl>
                </Box>

            </Box >
        </>

    );
}
