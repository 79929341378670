// React
import React from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Modal, Button, ModalContent } from "@chakra-ui/react";
import '../../../../../css/Page.css';
import { BsXLg } from "react-icons/bs";
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function SirenOrderModal({ onClose, nowDetailDate, userInfo, sirenOrder,setNowComponent }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          my='auto'
          className='sirenOrderModal'
          border='1px solid'
          borderColor='white'
          backgroundColor='rgba(229, 244, 255, 1)'
          textColor='black'
        >
          <Flex position='absolute' top='1vh' left='90%'>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalMainCloseText'
            mt='10%'
            mb='5%'
            align='center'
            direction='column'
          >
            <Text
            >
              [ VETA ORDER ]
            </Text>
          </Flex>

          <Flex
            mt='10%'
            className='modalMainInsideDefaultText'
            justifyContent='space-between'
          >
            <Flex
              direction='column'
              width='100%'
              align='center'
            >
              {
                sirenOrder.order.map((order, index) => {
                  return (
                    <Flex width='50%' justify='space-evenly' key={index}>
                      <Text>
                        {order.product_name}
                      </Text>
                      <Text>
                        {order.product_quantity}
                      </Text>

                    </Flex>
                  )
                })
              }


              {
                sirenOrder.status === 'reject' ?
                  <Text>
                    요청하신 주문이 거절되어 환불처리 되었습니다.
                  </Text>
                  :
                  sirenOrder.status === 'ongoing' ?
                    <Text>
                      요청하신 주문이 접수되어 제작중입니다.
                    </Text>
                    :
                    <Text>
                      메뉴가 준비되었습니다.
                    </Text>
              }
            </Flex>


          </Flex>

          <Flex
            mt='auto'
            justify='space-around'
          >
            <Button
              className='modalMainSmallBtn'
              _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              shadow={shadow}
              backgroundColor='rgba(22, 204, 234, 1)'
              border='1px solid rgba(217, 217, 217, 1)'
              textColor='white'
              align='center'
              onClick={() => { 
                onClose(); 
                setNowComponent('cafe');
              }}
            >
              < Text
                className='modalMainSmallBtnText'
                mx='auto'
              >
                확인
              </Text>
            </Button>

          </Flex>

        </ModalContent>
      </Modal>
    </div>
  );
}