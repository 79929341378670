// React
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Flex, Box, Text, Icon, Input } from "@chakra-ui/react";
import { BsArrowLeft, BsPencilSquare } from "react-icons/bs";
// Components
import '../../../../../css/main.css';
import { Button } from "reactstrap";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function MyCard({ onClose, userInfo, myModalOpen, setUserUpdate, mySeatInfo, finishSeat }) {
    const [updateAs, setUpdateAs] = useState(false);
    const [inputs, setInputs] = useState({
        account_name: ""
    });
    const { account_name } = inputs;
    useEffect(() => {
        setInputs({
            account_name: ""
        });
    }, [])
    function onChange(e) {
        // focus input value
        const { value, name } = e.target;
        // inputs value change
        setInputs({
            ...inputs,
            [name]: value,
        });
    } // fn onChange end
    function updateAsName(e) {
        e.preventDefault();
        if (account_name.length > 0 && account_name.length <= 6) {
            axios.post('/api/user/accountName', {
                account_name: account_name,
            })
                .then((response) => {
                    if (response.data.status === '1') {
                        alert("변경이 완료되었습니다.");
                        setUserUpdate(true);
                        setUpdateAs(false);
                        setInputs({
                            account_name: ""
                        });
                    } else {
                        alert(response.data.error);
                    }
                })
                .catch((error) => {
                    alert("다시 시도해주세요.")
                })
        } else if (!account_name || account_name.length === 0) {
            if (window.confirm('닉네임을 초기화 하시겠습니까?')) {
                axios.post('/api/user/accountNameReset')
                    .then((response) => {
                        if (response.data.status === '1') {
                            alert("변경이 완료되었습니다.");
                            setUserUpdate(true);
                            setUpdateAs(false);
                            setInputs({
                                account_name: ""
                            });
                        }
                    })
                    .catch((error) => {
                        alert("다시 시도해주세요.")
                    })
            }
        }
    }
    // 닫기 애니메이션 닫을 컴포넌트에 ${effect} 
    const [effect, setEffect] = useState('mount1');
    const onClickBtn = () => {
        setEffect('unmount');
        setTimeout(() => {
            onClose();
        }, 400)
    };
    //슬라이드 내려서 닫기
    let startX = 0;
    let startY = 0;

    const handleTouchStart = (e) => {
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
    };

    const handleTouchEnd = (e) => {
        const endX = e.changedTouches[0].clientX;
        const endY = e.changedTouches[0].clientY;

        const deltaX = Math.abs(endX - startX);
        const deltaY = endY - startY;

        if (deltaX < 100 && deltaY > 50) {
            setEffect('unmount');
            setTimeout(() => {
                onClose();
            }, 400)
        }
    };





    return (
        <div className={`slide-up-container ${effect} ${myModalOpen ? 'slide-up-content-closed' : ''}`}>
            <div className="slide-up-content" >
                <Box
                    onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}
                    width='100%'
                    height='100%'
                    position='relative'
                    align='center'
                >
                    <Icon className="backArrow" onClick={() => onClickBtn()} color='black' as={BsArrowLeft} />
                    <Flex position='relative' top='7vh' w='90%' direction='column'>
                        <Text
                            className="assetMainText"
                            mr='auto'
                            mt='3vh'
                        >
                            내 정보
                        </Text>
                    </Flex>
                    <Box position='relative' top='15vh' w='90%' >
                        <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                            <Text>
                                Email
                            </Text>
                            <Text>
                                {userInfo.email}
                            </Text>
                        </Flex>
                        <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                            <Text>
                                Name
                            </Text>
                            <Text>
                                {userInfo.name}
                            </Text>
                        </Flex>



                        <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                            <Text>
                                NickName
                            </Text>
                            {
                                updateAs ?
                                    <>
                                        <Input
                                            type='text'
                                            autoComplete='off'
                                            id="account_name"
                                            maxLength='6'
                                            placeholder="6글자 이내"
                                            name="account_name"
                                            onChange={onChange}
                                            width='50%'>
                                        </Input>
                                        <Button
                                            onClick={(e) => { updateAsName(e) }}>
                                            OK
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Text ml='auto'>
                                            {userInfo.account_name}
                                        </Text>
                                        <Icon as={BsPencilSquare} color='gray' size='17px' ml='10px' onClick={() => setUpdateAs(true)} />
                                    </>
                            }
                        </Flex>
                    </Box>


                    <Box position='relative' top='15vh' w='90%' >
                        <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                            <Text >
                                Office
                            </Text>
                            <Flex>

                                <Text >
                                    {mySeatInfo.using === '1' ? mySeatInfo.flore + "층 " + mySeatInfo.seat_name : "미사용"}
                                </Text>
                                {
                                    mySeatInfo.using === '1' ?
                                        <Button
                                            style={{ width: '50px', marginLeft: '20px', backgroundColor: '#008BFF', color: 'white', borderRadius: '10px' }}
                                            onClick={(e) => { finishSeat(e) }}>
                                            반납
                                        </Button>
                                        :
                                        null
                                }
                            </Flex>
                        </Flex>
                        <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                            <Text>
                                Phone
                            </Text>

                            <Text ml='auto'>
                                {userInfo.phone ? userInfo.phone : "미등록"}
                            </Text>

                        </Flex>
                        <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                            <Text>
                                Account
                            </Text>
                            <Text ml='auto'>
                                {(userInfo.address).substring(0, 10) + '...'}
                            </Text>
                        </Flex>
                        <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                            <Text>
                                &nbsp;
                            </Text>
                        </Flex>
                        <Flex height='20%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                            <Text color='#008BFF' fontSize='17px' mb='10px' fontWeight='700'>
                                VETA
                            </Text>
                            <Flex direction='column' mr='30px'>
                                <Text align='right' >
                                    {userInfo.amount ?
                                        (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                        :
                                        0
                                    }
                                </Text>
                                <Text fontSize='10px' align='right'>
                                    ￦ {userInfo.amount ?
                                        (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                        :
                                        0
                                    }
                                </Text>
                            </Flex>
                        </Flex>
                    </Box>

                </Box>
            </div>
        </div>
    );
}
