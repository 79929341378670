// React
import React, { useEffect, useState } from "react";
import { Box, Text, Icon, Flex } from "@chakra-ui/react";
// Components
import '../../../css/main.css';
import ItemCard from "./store/ItemCard";
import Card from "../../../components/card/Card.js";
import { BsCart4 } from "react-icons/bs";
// Assets
import ProductModal from "./store/modal/ProductModal.js";
import BuyListCard from "./store/BuyListCard.js";
import SecondPwd from "./store/SecondPwd.js";
import PaymentCard from "./store/PaymentCard.js";
import SelectCard from "./store/SelectCard.js";
import OrderDetail from "./store/OrderDetail.js";


/* global BigInt */

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function CafeCard({ userInfo, setSecondPwdCheck, setSecondInput, secondInput, storeList, setStoreInfo, storeInfo, setStoreRoll, bnpl,
    discountMessage, totalDiscountAmount, totalDiscountPercent, categoryList, bigCategoryList, itemList, setItemList, setDiscountMessage, setTotalDiscountAmount, setTotalDiscountPercent, setCategoryList, setBigCategoryList,
    optionDetail, optionGroup, optionMapping, discountList
}) {

    const [storeComponent, setStoreComponent] = useState('');
    const [productModalOpen, setProductModalOpen] = useState(false);

    const [productInfo, setProductInfo] = useState();
    const [buyList, setBuyList] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [quantity] = useState(1);
    const [creditStatus, setCreditStatus] = useState("0");
    const [takeoutStatus, setTakeoutStatus] = useState("0");
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [orderOk, setOrderOk] = useState(false);
    const [orderOption, setOrderOption] = useState([]);
    const [productOptionLists, setProductOptionLists] = useState([]);

    const [notice, setNotice] = useState(false);


    useEffect(() => {
        setTotalQuantity(0);
        setTotalPrice(0);
        for (let i = 0; i < buyList.length; i++) {
            if (totalDiscountAmount > 0) {
                setTotalPrice((prevTotal) => BigInt(prevTotal) + (BigInt(buyList[i].price) - BigInt(totalDiscountAmount) > BigInt(0) ? BigInt(buyList[i].price) - BigInt(totalDiscountAmount) : BigInt(0)) * BigInt(buyList[i].quantity));
            } else if (totalDiscountPercent > 0) {
                setTotalPrice((prevTotal) => BigInt(prevTotal) + ((BigInt(buyList[i].price) * (100 - BigInt(totalDiscountPercent))) / 100) * BigInt(buyList[i].quantity));
            } else {
                setTotalPrice((prevTotal) => BigInt(prevTotal) + BigInt(buyList[i].price) * BigInt(buyList[i].quantity));
            }
            setTotalQuantity((prevTotal) => BigInt(prevTotal) + BigInt(buyList[i].quantity));
        }
    }, [buyList])

    function handleIncreaseQuantity(index) {

        const updatedBuyList = [...buyList];
        updatedBuyList[index].quantity++;
        setBuyList(updatedBuyList);

    };

    function handleDecreaseQuantity(index, item) {
        // 수량이 1 미만으로 내려가지 않도록 확인
        if (item.quantity > 1) {
            const updatedBuyList = [...buyList];
            updatedBuyList[index].quantity--;
            setBuyList(updatedBuyList);
        }
    };
    function handleAddToCart(item, status) {
        const requiredGroups = productOptionLists.filter(
            (group) => group.option_group_type === 'required'
        );

        if (requiredGroups.length > 0) {

            const isValid = requiredGroups.every((group) =>
                orderOption.some((opt) => opt.option_group_id === group.option_group_id)
            );

            if (!isValid) {
                setNotice(true);
                return;
            }
        }


        bigCategoryList.forEach((cat) => {
            if (item.category_big_id === cat.category_big_id) {
                item.category_big_name = cat.category_big_name;
            }
        })
        var totalPrice = Number(item.price);
        if (orderOption.length > 0) {

            const totalOptionPrice = orderOption.reduce((amount, option) => {
                return amount + Number(option.additional_price);
            }, 0);

            totalPrice += totalOptionPrice;
        }
        const existingItemIndex = buyList.findIndex((buyItem) =>
            buyItem.item.product_number === item.product_number &&
            JSON.stringify(buyItem.selectedOptions) === JSON.stringify(orderOption)
        );

        if (existingItemIndex !== -1) {
            // 동일한 옵션의 상품이 이미 존재할 경우 수량 증가
            const updatedBuyList = [...buyList];
            updatedBuyList[existingItemIndex].quantity += 1;
            setBuyList(updatedBuyList);
        } else {
            // 옵션이 다르면 새로운 상품으로 추가
            setBuyList((prevList) => [...prevList, { item, price: totalPrice, quantity, selectedOptions: orderOption }]);
        }
        setOrderOption([]);
        setProductModalOpen(false);
        if (status === 'on') {
            setStoreComponent('detail');
        }
        setNotice(false);
    };

    function handleRemoveItem(indexToRemove, item) {
        setBuyList((prevList) => prevList.filter((_, index) => index !== indexToRemove));
    };
    // function removeAllBuyList() {
    //     setBuyList([]);
    // };
    const takeoutChange = (event) => {
        setTakeoutStatus(event.target.value);
    };
    const creditChange = (event) => {
        setCreditStatus(event.target.value);
    };
    function handleAddToOption(item, group) {

        if (group.option_group_type === 'required') {
            // 같은 그룹의 옵션들을 제외하고 새로운 옵션만 유지
            setOrderOption((prevList) =>
                prevList.filter((option) => option.option_group_id !== group.option_group_id)
                    .concat(item)
            );
        } else {
            // 체크박스일 경우: 이미 선택된 옵션을 다시 클릭하면 선택 해제
            const existingItemIndex = orderOption.findIndex((option) => option.option_id === item.option_id);
            if (existingItemIndex === -1) {
                setOrderOption((prevList) => [...prevList, item]);
            } else {
                setOrderOption((prevList) => prevList.filter((option) => option.option_id !== item.option_id));
            }
        }
    };

    useEffect(() => {
        setNotice(false);
        if (productInfo) {
            var list = [];
            const mappedItems = optionMapping.filter(mapping =>
                mapping.product_number === productInfo.product_number
            );
            for (let i = 0; i < mappedItems.length; i++) {
                const matchingGroup = optionGroup.find(e =>
                    e.option_group_id === mappedItems[i].option_group_id
                );
                if (matchingGroup) {
                    list.push({
                        "option_group_id": matchingGroup.option_group_id,
                        "option_group_name": matchingGroup.option_group_name,
                        "option_group_type": matchingGroup.option_group_type
                    });
                }
            }
            setProductOptionLists(list);
        }
    }, [productInfo])
    return (
        <>
            <Box
                width='100%'
                height='100%'
                overflow='hidden'
                align='center'
                position='sticky'
            >

                {productModalOpen && <ProductModal titleText='상품 정보' productInfo={productInfo} handleAddToCart={handleAddToCart} userInfo={userInfo} onClose={() => setProductModalOpen(false)} totalDiscountAmount={totalDiscountAmount} totalDiscountPercent={totalDiscountPercent} setStoreComponent={setStoreComponent} totalQuantity={totalQuantity}
                    optionDetail={optionDetail} handleAddToOption={handleAddToOption} orderOption={orderOption}
                    optionGroup={optionGroup} optionMapping={optionMapping} productOptionLists={productOptionLists} setProductOptionLists={setProductOptionLists} notice={notice} />}
                {
                    storeComponent === 'detail' ?
                        <BuyListCard takeoutChange={takeoutChange} setTakeoutStatus={setTakeoutStatus} bnpl={bnpl} totalDiscountAmount={totalDiscountAmount} totalDiscountPercent={totalDiscountPercent}
                            takeoutStatus={takeoutStatus} buyList={buyList} totalPrice={totalPrice} handleRemoveItem={handleRemoveItem} handleIncreaseQuantity={handleIncreaseQuantity} handleDecreaseQuantity={handleDecreaseQuantity}
                            setStoreComponent={setStoreComponent} storeInfo={storeInfo} creditStatus={creditStatus} creditChange={creditChange} setCreditStatus={setCreditStatus} userInfo={userInfo}
                        />
                        :
                        storeComponent === 'second' ?
                            <SecondPwd setSecondPwdCheck={setSecondPwdCheck} setComponent={setStoreComponent} userInfo={userInfo} secondInput={secondInput} setSecondInput={setSecondInput} />
                            :
                            storeComponent === 'loading' ?
                                <PaymentCard takeoutStatus={takeoutStatus}
                                    setNowComponent={setStoreComponent} totalDiscountAmount={totalDiscountAmount} totalDiscountPercent={totalDiscountPercent}
                                    setOrderOk={setOrderOk} creditStatus={creditStatus} totalPrice={totalPrice}
                                    buyList={buyList} setBuyList={setBuyList} secondInput={secondInput} setSecondInput={setSecondInput} userInfo={userInfo} storeInfo={storeInfo} discountMessage={discountMessage} />
                                :
                                storeComponent === 'store' ?
                                    <ItemCard
                                        setStoreComponent={setStoreComponent}
                                        setProductModalOpen={setProductModalOpen}
                                        setProductInfo={setProductInfo}
                                        storeInfo={storeInfo}
                                        setTotalDiscountAmount={setTotalDiscountAmount}
                                        setTotalDiscountPercent={setTotalDiscountPercent}
                                        totalDiscountAmount={totalDiscountAmount} totalDiscountPercent={totalDiscountPercent} setDiscountMessage={setDiscountMessage}
                                        categoryList={categoryList} setCategoryList={setCategoryList}
                                        bigCategoryList={bigCategoryList} setBigCategoryList={setBigCategoryList}
                                        handleAddToCart={handleAddToCart}
                                        itemList={itemList} setItemList={setItemList}
                                    />
                                    :
                                    storeComponent === 'order' ?
                                        <OrderDetail
                                            userInfo={userInfo}
                                            storeInfo={storeInfo}
                                            setOrderOk={setOrderOk}
                                            orderOk={orderOk}
                                            setStoreComponent={setStoreComponent}
                                            optionDetail={optionDetail}
                                            discountList={discountList}
                                        />
                                        :
                                        <SelectCard setStoreRoll={setStoreRoll} storeList={storeList} setStoreComponent={setStoreComponent} setStoreInfo={setStoreInfo} />
                }
                {
                    storeComponent === 'store' && buyList.length !== 0 ?
                        <Card
                            mt='2vh' h='7vh' w='90%' alignSelf='center' mx='auto'
                            backgroundColor='rgba(70, 209, 232, 1)'
                            onClick={() => setStoreComponent('detail')}>
                            <Flex justifyContent='space-between' color='white'>
                                <>
                                    <Flex>
                                        <Icon as={BsCart4} />
                                        <Text>
                                            {(totalQuantity).toString() || 0}
                                        </Text>
                                    </Flex>
                                    <Flex>
                                        <Text>
                                            {(totalPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                        </Text>
                                        <Text>
                                            VETA
                                        </Text>
                                    </Flex>
                                </>
                            </Flex>
                        </Card>
                        :
                        null
                }



            </Box >
        </>

    );
}
