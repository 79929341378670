export const columnsDataSeat = [
  {
    Header: "좌석",
    accessor: "seat_number",
  },
  {
    Header: "시작시간",
    accessor: "start_date",
  },
  {
    Header: "종료시간",
    accessor: "finish_date",
  }
];
