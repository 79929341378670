// React
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Flex, Text, Button, Input } from "@chakra-ui/react";
// Components
import '../../../../../css/main.css';
import CryptoJS from 'crypto-js';

export default function Password({ userInfo, setAssetComponent, setUserUpdate }) {
    const [alertText, setAlertText] = useState('');
    const [alertAni, setAlertAni] = useState('');
    const [inputs, setInputs] = useState({
        pastPwd: "",
        pwd: "",
        pwdCheck: ""
    });
    const { pastPwd, pwd, pwdCheck } = inputs;
    useEffect(() => {

        setInputs({
            pastPwd: "",
            pwd: "",
            pwdCheck: ""
        });
    }, [])
    function onChange(e) {
        setAlertText('');
        setAlertAni('');
        // focus input value
        const { value, name } = e.target;
        // inputs value change
        setInputs({
            ...inputs,
            [name]: value,
        });
    } // fn onChange end
    function hashVisitorId(visitorId) {
        return CryptoJS.SHA256(visitorId).toString(CryptoJS.enc.Hex);
    }
    function updatePwd(e) {
        e.preventDefault();
        if (pastPwd && (pwd === pwdCheck)) {
            axios.post('/api/user/pwdChange', {
                pastPwd: hashVisitorId(pastPwd),
                pwd: hashVisitorId(pwd)
            })
                .then((response) => {
                    if (response.data.status === '1') {
                        alert("비밀번호가 변경되었습니다.");
                        setAssetComponent('');
                        setUserUpdate(true);
                    } else if (response.data.status === '2') {
                        setAlertText('네트워크 문제로 다시 시도해주세요.');
                        setAlertAni('alertText');
                    } else if (response.data.status === '0') {
                        setAlertText('비밀번호를 확인하세요.');
                        setAlertAni('alertText');
                    }

                })
                .catch((error) => {
                    // alert(error);
                })
        } else {
            setAlertText('올바른 비밀번호를 입력해주세요.');
            setAlertAni('alertText');
        }

    }

    return (
        <>

            <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center' direction='column'>
                <Text mr='auto'>
                    기존 Password
                </Text>
                <Input
                    className={`${alertAni}`}
                    type='password'
                    autoComplete='off'
                    id="pastPwd"
                    minLength='8'
                    placeholder="Password"
                    name="pastPwd"
                    onChange={onChange}
                    width='100%'>
                </Input>


            </Flex>
            <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center' direction='column'>
                <Text mr='auto'>
                    변경 Password
                </Text>
                <Input
                    className={`${alertAni}`}
                    type='password'
                    autocomplete="new-password"
                    id="pwd"
                    minLength='8'
                    placeholder="At least 8 characters"
                    name="pwd"
                    onChange={onChange}
                    width='100%'>
                </Input>


            </Flex>
            <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center' direction='column'>
                <Text mr='auto'>
                    Password 확인
                </Text>
                <Input
                    className={`${alertAni}`}
                    type='password'
                    autocomplete="new-password"
                    id="pwdCheck"
                    minLength='8'
                    placeholder="password"
                    name="pwdCheck"
                    onChange={onChange}
                    width='100%'>
                </Input>
            </Flex>
            <Flex height='30%' mt='10%' p='2%' direction='column'>
                <Text color='red'>
                    &nbsp;{alertText}  &nbsp;
                </Text>
                <Button
                    mx='auto'
                    border='1px solid'
                    borderColor='#C1B9B9'
                    borderRadius='0px'
                    width='40%'
                    height='5vh'
                    minH='40px'
                    className="mainText"
                    onClick={(e) => { updatePwd(e) }}
                >
                    변경하기
                </Button>
            </Flex>
        </>
    );
}