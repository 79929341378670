// React
import React, { useState } from "react";
import { Flex, Box, Text, Button, Icon } from "@chakra-ui/react";
// Components
import { BsArrowLeft } from "react-icons/bs";
import '../../../../css/main.css';
import AccountCheck from "../account/AccountCheck";
import AccountFinish from "../account/AccountFinish";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function ChooseAccount({ setNowComponent, userInfo }) {
    const [accountComponent, setAccountComponent] = useState('');

    return (
        accountComponent === 'check' ?
            <AccountCheck setAccountComponent={setAccountComponent} />
            :
            accountComponent === 'finish' ?
                <AccountFinish setAccountComponent={setAccountComponent} userInfo={userInfo} />
                :
                <>

                    <Icon className="backArrow" onClick={() => setNowComponent('mint')} color='black' as={BsArrowLeft} />
                    <Box
                        width='100%'
                        height='100%'
                        align='center'
                        position='relative'
                    >


                        <Flex
                       className="mintTitleBox"
                            direction='column' align='center'>
                            <Flex>
                                <Text
                                    className="mintMainText"
                                >
                                    KRCD를 mint(
                                </Text>
                                <Text
                                    className="mintMainSmallText"
                                >
                                    충전
                                </Text>
                                <Text
                                    className="mintMainText"
                                >
                                    ) 해볼까요?
                                </Text>
                            </Flex>

                            <Flex direction='column'>

                                <Box
                                    className="assetBankCard"
                                    mt='15vh'
                                    textColor='white'
                                >
                                    <Text>
                                        어디은행
                                    </Text>
                                    <Text>
                                        010-1414-1414
                                    </Text>
                                </Box>
                                <Box
                                    className="assetBankChangeBtn"
                                    mt='1vh'
                                    textColor='white'
                                    ml='auto'
                                    onClick={() => { setAccountComponent('check') }}
                                >
                                    <Text mt='2px'>


                                        변경
                                    </Text>
                                </Box>
                            </Flex>
                        </Flex>


                        <Button
                            className="buttomBtnText underbarButton"
                            position='fixed'
                            top='95vh'
                            left='0'
                            backgroundColor='#46D1E8'
                            height='5vh'
                            borderRadius='0px'
                            onClick={() => setNowComponent('mintStart')}
                        >
                            <Text
                                color='white'
                            >
                                mint 하기
                            </Text>
                        </Button>
                    </Box >
                </>

    );
}
