// React
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Flex, Box, Text, Button, Icon, Input } from "@chakra-ui/react";
// Components
import { BsArrowLeft } from "react-icons/bs";
import '../../../../css/main.css';
import MintLoding from "./MintLoding";
import MintFinish from "./MintFinish";
import SecondPwd from "../confirm/SecondPwd";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function MintStart({ setNowComponent, userInfo }) {
    const [mintComponent, setMintComponent] = useState('');
    const [secondPwdCheck, setSecondPwdCheck] = useState(false);
    const [alertText, setAlertText] = useState('');

    // A variable to use function variables from store.js
    const [inputs, setInputs] = useState({
        amount: ""
    });
    // input variable
    const { amount } = inputs;
    function onChange(e) {
        if (e.target.value !== '<') {
            const pureKrc = e.target.value.replace(/[^0-9]/g, "").replaceAll(',', '');
            if (pureKrc > 300000) {
                setInputs({
                    ...inputs,
                    amount: '300000'.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                });
            } else {
                setInputs({
                    ...inputs,
                    amount: pureKrc.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                });
            }
        } // if else end

    } // fn onChange end

    useEffect(() => {
        if (secondPwdCheck) {
            axios.post('/api/account/transfer', {
                amount: amount.replace(/[^0-9]/g, "").replaceAll(',', ''),
                target: 'address'
            }, {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))}`,
            }).then((response) => {         // api return
                setMintComponent('finish');
                setSecondPwdCheck(false);
            }).catch(error => {
                setAlertText('잠시 후 다시 시도해주세요.');
                setMintComponent('');  
                setSecondPwdCheck(false);
            })
        }
    }, [secondPwdCheck])




    function plusOne() {
        setInputs(prevInputs => {
            const currentAmount = parseInt(prevInputs.amount.replace(/,/g, '')) || 0;
            if (currentAmount + 10000 <= 300000) {
                const newAmount = (currentAmount + 10000).toLocaleString();
                return {
                    ...prevInputs,
                    amount: newAmount
                };
            } else {
                return {
                    ...prevInputs,
                    amount: '300000'.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                };
            }
        });
    }
    function plusThree() {
        setInputs(prevInputs => {
            const currentAmount = parseInt(prevInputs.amount.replace(/,/g, '')) || 0;
            if (currentAmount + 30000 <= 300000) {
                const newAmount = (currentAmount + 30000).toLocaleString();
                return {
                    ...prevInputs,
                    amount: newAmount
                };
            } else {
                return {
                    ...prevInputs,
                    amount: '300000'.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                };
            }
        });
    }
    function plusFive() {
        setInputs(prevInputs => {
            const currentAmount = parseInt(prevInputs.amount.replace(/,/g, '')) || 0;
            if (currentAmount + 50000 <= 300000) {
                const newAmount = (currentAmount + 50000).toLocaleString();
                return {
                    ...prevInputs,
                    amount: newAmount
                };
            } else {
                return {
                    ...prevInputs,
                    amount: '300000'.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                };
            }
        });
    }
    function plusSeven() {
        setInputs(prevInputs => {
            const currentAmount = parseInt(prevInputs.amount.replace(/,/g, '')) || 0;
            if (currentAmount + 70000 <= 300000) {
                const newAmount = (currentAmount + 70000).toLocaleString();
                return {
                    ...prevInputs,
                    amount: newAmount
                };
            } else {
                return {
                    ...prevInputs,
                    amount: '300000'.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                };
            }
        });
    }
    function plusTen() {
        setInputs(prevInputs => {
            const currentAmount = parseInt(prevInputs.amount.replace(/,/g, '')) || 0;
            if (currentAmount + 100000 <= 300000) {
                const newAmount = (currentAmount + 100000).toLocaleString();
                return {
                    ...prevInputs,
                    amount: newAmount
                };
            } else {
                return {
                    ...prevInputs,
                    amount: '300000'.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                };
            }
        });
    }



    return (
        mintComponent === 'second' ?
            <SecondPwd setComponent={setMintComponent} userInfo={userInfo} setSecondPwdCheck={setSecondPwdCheck} />
            :
            mintComponent === 'loading' ?
                <MintLoding />
                :
                mintComponent === 'finish' ?
                    <MintFinish
                        setNowComponent={setNowComponent}
                        amount={amount}
                    />

                    :
                    <>
                        <Icon className="backArrow" onClick={() => setNowComponent('chooseAccount')} color='black' as={BsArrowLeft} />
                        <Box
                            width='100%'
                            height='100%'
                            align='center'
                            position='relative'
                            mx='auto'
                            textAlign='left'
                        >
                            <Flex
                                mt='10vh'
                                direction='column'>
                                <Flex width='90%' mx='auto'>
                                    <Text
                                        className="mintMainText"
                                    >
                                        얼마를 mint(
                                    </Text>
                                    <Text
                                        className="mintMainSmallText"
                                    >
                                        충전
                                    </Text>
                                    <Text
                                        className="mintMainText"
                                    >
                                        ) 할까요?
                                    </Text>
                                </Flex>

                                <Box className="mintInputAmountBox" my='10vh' align='center'>
                                    <Input
                                        className="mintAmountInput"
                                        w='90%'
                                        variant='auth'
                                        placeholder="만원 단위로 입력하세요."
                                        id="amount"
                                        name="amount"
                                        value={amount}
                                        onChange={onChange}>
                                    </Input>
                                    <Flex
                                        direction='column'
                                    >
                                        <Flex justifyContent='space-evenly' my='2vh'>
                                            <Button
                                                className="mintAmountPlusBtn"
                                                onClick={() => plusOne()}
                                            >
                                                1만원
                                            </Button>
                                            <Button
                                                className="mintAmountPlusBtn"
                                                onClick={() => plusThree()}>
                                                3만원
                                            </Button>
                                            <Button
                                                className="mintAmountPlusBtn"
                                                onClick={() => plusFive()}>
                                                5만원
                                            </Button>
                                        </Flex>
                                        <Flex justifyContent='space-evenly'>
                                            <Button
                                                className="mintAmountPlusBtn"
                                                onClick={() => plusSeven()}>
                                                7만원
                                            </Button>
                                            <Button
                                                className="mintAmountPlusBtn"
                                                onClick={() => plusTen()}>
                                                10만원
                                            </Button>
                                            <Button
                                                className="mintAmountPlusBtn">
                                                다른금액
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Box>
                            </Flex>

                            <Box
                                mt='5vh'
                                width='90%'
                                mx='auto'>
                                <Text
                                    width='90%'
                                    className="mintInsertSmallText"
                                >
                                    mint 시 유의사항
                                </Text>
                            </Box>
                            <Box
                                backgroundColor='rgba(217, 217, 217, 0.2)'
                                className="mintNoticeText"
                                align='center'
                                p='3%'
                            >
                                <Text>
                                    mint(충전)은 1회 1만원부터  30만원까지 가능하며,
                                </Text>
                                <Flex align='center' justify='center'>
                                    <Text>
                                        충전 후 총 금액이
                                    </Text>
                                    <Text className="mintNoticeBoldText">
                                        &nbsp;30만원을 초과
                                    </Text>
                                    <Text>
                                        할 수 없습니다.
                                    </Text>
                                </Flex>
                            </Box>
                            {
                                alertText !== '' ?
                                    <Text
                                        className="alertText"
                                        color='red'
                                        fontSize='15px'
                                        fontWeight='700'
                                        mt='3vh'
                                        align='center'>
                                        {alertText}
                                    </Text>
                                    :
                                    null
                            }
                            <Flex
                                direction='column'
                                position='fixed'
                                top='80vh'
                                left='0'
                                width='100%'
                                height='20vh'
                            >

                                <Box
                                    height='5vh'
                                    width='90%'
                                    textAlign='right'
                                    mx='auto'>
                                    <Text
                                        className="mintNextText"
                                        onClick={() => setNowComponent('mint')}
                                    >
                                        다음에 mint 하기
                                    </Text>

                                </Box>

                                <Box
                                    className="underbarBigBtn"
                                    align='center'
                                >

                                    <Flex
                                        justifyContent='space-between'
                                        align='flex-end'
                                        height='4vh'
                                        my='2vh'
                                        width='75%'
                                        className="mintInsertSmallText"
                                    >
                                        <Text>
                                            mint 예정 금액
                                        </Text>
                                        <Flex fontWeight='700'  align='flex-end'>
                                            <Text className="underbarAmountText" >
                                                {(amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                            </Text>
                                            <Text>
                                                원
                                            </Text>
                                        </Flex>
                                    </Flex>

                                    <Button
                                        backgroundColor={amount ? '#16CCEA' : '#C1B9B9'}
                                        height='5vh'
                                        my='1vh'
                                        width='80%'
                                        borderRadius='20px'
                                        onClick={() => setMintComponent('second')}
                                    >
                                        <Text
                                            color='white'
                                            className="buttomBtnText"
                                        >
                                            mint 하기
                                        </Text>
                                    </Button>
                                </Box>
                            </Flex>
                        </Box >
                    </>

    );
}
