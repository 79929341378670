// React
import React from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { Box, Text, useColorModeValue, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
// Assets
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function SeatTableContent({ columns, data, dFlag, setSearchModalOpen, setSearchUserInfo }) {
    // Variables for CSS Styling
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
   
    const {
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        { columns, data },
        useGlobalFilter,
        useSortBy
    );
    var noDataText = (dFlag === 0) ? 'No Data Available' : '';
    var noDataMargin = (dFlag === 0) ? '70px' : '0px';

    return (

        <Box
            borderRadius='0'
            w='100%'
            overflowY="auto"
            className='screen-box transactionTable'>
            <Table whiteSpace='nowrap' position='relative' >
                <Thead position='sticky' top='0' backgroundColor='white' zIndex='1' height='20%' align='center' >
                    {headerGroups.map((headerGroup, index) => (
                        <Tr key={index}>
                            {headerGroup.headers.map((column, index) => (
                                // Exclude the column with the name "cFlag"
                                column.render("Header") === '좌석'
                                    ? <Th
                                        p='2%'
                                        key={index}
                                        borderColor={borderColor}>
                                        <Text
                                            className="tableHead"
                                            fontWeight='400'
                                            align='left'
                                            color={textColor}>
                                            {column.render("Header")}
                                        </Text>
                                    </Th>
                                    :
                                    column.render("Header") === '시작시간'
                                        ? <Th
                                            p='2%'
                                            key={index}
                                            borderColor={borderColor}>
                                            <Text
                                                className="tableHead"
                                                fontWeight='400'
                                                align='center'
                                                color={textColor}>
                                                {column.render("Header")}
                                            </Text>
                                        </Th>
                                        :
                                        <Th
                                            p='2%'
                                            key={index}
                                            borderColor={borderColor}>
                                            <Text
                                                className="tableHead"
                                                fontWeight='400'
                                                align='center'
                                                color={textColor}>
                                                {column.render("Header")}
                                            </Text>
                                        </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()} height='80%'>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr fontSize='11px' fontWeight='400' p='1px' {...row.getRowProps()} key={index} borderBottom='2px solid' borderColor='gray.200'
                                onClick={
                                    () => {
                                        setSearchModalOpen(true);
                                        setSearchUserInfo(row.original);
                                        // setWhereComponent('detail');
                                        // setSeatDetail(row.original);
                                    }
                                }
                            >
                                {row.cells.splice(0, dFlag).map((cell, index) => {

                                    // Variable for each <Td> in ItemTable
                                    let data = "";

                                    if (cell.column.Header === "좌석") {

                                        data = (
                                            <Text align='center'>
                                                {row.original.flore} 층 {row.original.seat_name}
                                            </Text>
                                        );

                                    } else if (cell.column.Header === "시작시간") {
                                        data = (
                                            <Text align='center' >
                                                 {row.original.start_date} {row.original.start_time}
                                            </Text>
                                        );
                                    } else if (cell.column.Header === "종료시간") {
                                        data = (

                                            <Text align='center'>
                                                 {row.original.finish_date} {row.original.finish_time}
                                            </Text>

                                        );
                                    }
                                    return (
                                        <Td
                                            p='2%'
                                            {...cell.getCellProps()}
                                            key={index}
                                            className="tableBody"
                                            maxH='30px !important'
                                            minW={{ sm: "100px", md: "150px", lg: "auto" }}
                                            borderColor='transparent'>
                                            {data}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        )
                    }
                    )}
                </Tbody>
            </Table>
            <Text color={textColor} fontSize='xl' fontWeight='500' align='center' justify='center' my={noDataMargin}>
                {noDataText}
            </Text>
        </Box>
    );
}
