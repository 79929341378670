// React
import React, { useState, useEffect, useRef } from "react";
import { Flex, Box, Text, FormControl, Button, Icon } from "@chakra-ui/react";
// Components
import axios from "axios";
import '../../../../css/main.css';
import { BsArrowLeft } from "react-icons/bs";
import CircleInput from '../../../../components/secondPwd/secondPwd'
import CryptoJS from 'crypto-js';

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function SecondPwdSetting({ setNowComponent, userInfo, setSecondPwdCheck }) {
    function hashVisitorId(visitorId) {
        return CryptoJS.SHA256(visitorId).toString(CryptoJS.enc.Hex);
    }

    const [secondCheck, setSecondCheck] = useState(false);
    const [firstCheck, setFirstCheck] = useState(false);

    const inputRefCheck = useRef([]);
    const [checkValues, setCheckValues] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);
    const inputRefsecond = useRef([]);
    const [inputValueSecond, setInputValueSecond] = useState(['', '', '', '', '', '']);
    const [alertText, setAlertText] = useState('');
    const [alertAni, setAlertAni] = useState('');

    //검증 단계
    useEffect(() => {
        const combinedString = checkValues.join('');
        if (checkValues[5] !== '') {
            for (var i = 0; i < 6; i++) {
                if (checkValues[i] === '' || checkValues[i].length !== 1) {
                    setCheckValues(['', '', '', '', '', '']);
                    inputRefCheck.current[0].focus();
                    setAlertText('비밀번호를 확인하세요.')
                    setAlertAni('alertText');
                    return;
                }
            }
        }

        if (combinedString.length === 6) {
            inputRefCheck.current[0].focus();
            axios.post('/api/user/second', {
                email: userInfo.email,
                inputValues: hashVisitorId(combinedString)
            })
                .then((response) => {
                    if (response.data === 1) {
                        setCheckValues(['', '', '', '', '', '']);
                        setFirstCheck(true);
                        inputRefs.current[0].focus();
                    } else {
                        setCheckValues(['', '', '', '', '', '']);
                        setAlertText('비밀번호를 확인하세요.')
                        setAlertAni('alertText');
                    }
                })
                .catch((error) => {
                    setCheckValues(['', '', '', '', '', '']);
                })
        }
    }, [checkValues])

    // 1차 비밀번호 입력유무 
    useEffect(() => {
        if (inputValues[0] !== '' && inputValues[1] !== '' &&
            inputValues[2] !== '' && inputValues[3] !== '' &&
            inputValues[4] !== '' && inputValues[5] !== '') {
            inputRefs.current[0].focus();
            setSecondCheck(true);
        } else if (inputValues[5] !== '') {
            for (var i = 0; i < 6; i++) {
                if (inputValues[i] === '' || inputValues[i].length !== 1) {
                    setInputValues(['', '', '', '', '', '']);
                    inputRefs.current[0].focus();
                    setAlertAni('alertText');
                }
            }
        }

    }, [inputValues])

    useEffect(() => {
        if (firstCheck && (inputValueSecond[0] !== '' && inputValueSecond[1] !== '' &&
            inputValueSecond[2] !== '' && inputValueSecond[3] !== '' &&
            inputValueSecond[4] !== '' && inputValueSecond[5] !== '')
        ) {
            if (
                inputValues[0] === inputValueSecond[0] &&
                inputValues[1] === inputValueSecond[1] &&
                inputValues[2] === inputValueSecond[2] &&
                inputValues[3] === inputValueSecond[3] &&
                inputValues[4] === inputValueSecond[4] &&
                inputValues[5] === inputValueSecond[5]) {
                updateSecondPwd();
            } else {
                setAlertText('올바른 비밀번호를 입력해주세요.');
                setAlertAni('alertText');
                setInputValueSecond(['', '', '', '', '', '']);
                inputRefsecond.current[0].focus();
            }
        } else if (inputValueSecond[5] !== '') {
            for (var i = 0; i < 6; i++) {
                if (inputValueSecond[i] === '' || inputValueSecond[i].length !== 1) {
                    setInputValueSecond(['', '', '', '', '', '']);
                    inputRefsecond.current[0].focus();
                    setAlertText('다시 시도해주세요.');
                    setAlertAni('alertText');
                }
            }
        }
    }, [inputValueSecond])

    function updateSecondPwd() {
        const combinedString = inputValues.join('');
        if (combinedString.length === 6) {
            axios.post('/api/user/secondChange', {
                inputValues: hashVisitorId(combinedString)
            })
                .then((response) => {
                    if (response.data.status === '1') {
                        alert("2차 비밀번호가 변경되었습니다.")
                        setSecondPwdCheck(true);
                        setNowComponent('');
                    }
                })
                .catch((error) => {
                    // alert(error);
                })
        }
    }

    //2차비밀번호 검증
    const checkInputChange = (index, value) => {
        setAlertText('');
        setAlertAni('');
        const newInputValues = [...checkValues];
        newInputValues[index] = value;

        // 입력이 있으면 다음 input으로 포커스 이동
        if (value && index < inputRefCheck.current.length - 1) {
            if (checkValues[index + 1] !== '') {
                newInputValues[index + 1] = '';
            }
            inputRefCheck.current[index + 1]?.focus();
        }
        setCheckValues(newInputValues);
    };

    //first
    const handleInputChange = (index, value) => {
        setAlertText('');
        setAlertAni('');
        const newInputValues = [...inputValues];
        newInputValues[index] = value;

        // 입력이 있으면 다음 input으로 포커스 이동
        if (value && index < inputRefs.current.length - 1) {
            if (inputValues[index + 1] !== '') {
                newInputValues[index + 1] = '';
            }
            inputRefs.current[index + 1].focus();
        } else if (value && index === inputRefs.current.length - 1) {
            if (secondCheck) {
                setTimeout(() => {
                    inputRefsecond.current[0].focus();
                }, 0);
            }
        }
        setInputValues(newInputValues);
    };

    //second
    const secondInputChange = (index, value) => {
        setAlertText('');
        setAlertAni('');
        var newInputValues = [...inputValueSecond];
        newInputValues[index] = value;

        // 입력이 있으면 다음 input으로 포커스 이동
        if (value && index < inputRefsecond.current.length - 1) {
            if (inputValueSecond[index + 1] !== '') {
                newInputValues[index + 1] = '';
            }
            inputRefsecond.current[index + 1].focus();
        }
        setInputValueSecond(newInputValues);
    };

    function clearFirstDigit(index) {
        let passwordValues = [...inputValues];
        passwordValues[index] = '';
        setInputValues(passwordValues);
        inputRefs.current[index].focus();
    }

    function clearSecondDigit(index) {
        let passwordValues = [...inputValueSecond];
        passwordValues[index] = '';
        setInputValueSecond(passwordValues);
        inputRefsecond.current[index].focus();
    }
    function clearCheckDigit(index) {
        let passwordValues = [...checkValues];
        passwordValues[index] = '';
        setCheckValues(passwordValues);
        inputRefCheck.current[index].focus();
    }

    // 최초 확인 backspace 처리
    // 입력 포커스 이동 핸들링
    const handleKeyUpCheck = (index, event) => {
        if (index !== 0 && event.keyCode === 8) { // Move focus to previous on backspace
            var passwordValues = [...checkValues];
            passwordValues[--index] = '';
            setCheckValues(passwordValues);
            inputRefCheck.current[index].focus();
        }
    };

    const handleKeyUpFirst = (index, event) => {
        if (index !== 0 && event.keyCode === 8) { // Move focus to previous on backspace
            var passwordValues = [...inputValues];
            passwordValues[--index] = '';
            setInputValues(passwordValues);
            inputRefs.current[index].focus();
        }
    };

    const handleKeyUpSecond = (index, event) => {
        if (index !== 0 && event.keyCode === 8) { // Move focus to previous on backspace
            var passwordValues = [...inputValueSecond];
            passwordValues[--index] = '';
            setInputValueSecond(passwordValues);
            inputRefsecond.current[index].focus();
        }
    };
    return (
        <>
            <Box
                width='100%'
                height='100%'
                align='center'
                top='0'
                position='absolute'
                mx='auto'
                textAlign='left'
                backgroundColor='white'
                zIndex='5000'
            >
                <Icon className="backArrow" onClick={() => setNowComponent('')} color='black' as={BsArrowLeft} />
                <Flex
                    direction='column'>
                    {
                        firstCheck && !secondCheck ?
                            <Flex mt='5vh' width='80%' mx='auto'>
                                <Text
                                    className="secondSmallText"
                                >
                                    결제 및 로그인 시 사용하실 비밀번호 6자리를 설정해주세요.
                                </Text>
                            </Flex>
                            :
                            secondCheck ?
                                <Flex mt='5vh' width='80%' mx='auto'>
                                    <Text
                                        className="secondSmallText"
                                    >
                                        다시 한번 입력해주세요.
                                    </Text>
                                </Flex>
                                :
                                <Flex mt='5vh' width='70%' mx='auto'>
                                    <Text
                                        className="secondSmallText"
                                    >
                                        기존 2차 비밀번호를 입력하세요.
                                    </Text>
                                </Flex>
                    }

                </Flex>


                <Box width='80%' align='center'
                    mx='auto'
                    mt='15vh'
                    p='2%'>
                    {
                        alertText ?
                            <Text
                                textColor='red'
                                className={`secondMiddleText ${alertAni}`}
                            >
                                {alertText}
                            </Text>
                            :

                            firstCheck && !secondCheck ?
                                <Text
                                    className="secondMiddleText"
                                >
                                    신규 비밀번호 입력
                                </Text>

                                :
                                secondCheck ?

                                    <Text
                                        className="secondMiddleText"
                                    >
                                        신규 비밀번호 확인
                                    </Text>

                                    :
                                    <Text
                                        className="secondMiddleText"
                                    >
                                        2차 비밀번호 입력
                                    </Text>
                    }

                    <FormControl mt='2vh'>
                        {
                            firstCheck && !secondCheck ?

                                inputValues.map((value, index) => (
                                    <CircleInput
                                        className="secondPwd"
                                        key={index}
                                        type="number"
                                        maxLength="1"
                                        value={value}
                                        hasValue={value !== ''}
                                        name={index}
                                        onKeyUp={(e) => handleKeyUpFirst(index, e)}
                                        inputMode="numeric" // 스마트폰 키보드에서 숫자 키보드를 띄우기 위함
                                        pattern="\d*" // 숫자만 입력받도록 하는 정규 표현식
                                        onClick={() => { clearFirstDigit(index) }}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                    />
                                ))
                                :
                                secondCheck ?
                                    inputValueSecond.map((value, index) => (
                                        <CircleInput
                                            className={`secondPwd ${alertAni}`}
                                            key={index}
                                            type="number"
                                            maxLength="1"
                                            value={value}
                                            hasValue={value !== ''}
                                            name={index}
                                            onKeyUp={(e) => handleKeyUpSecond(index, e)}
                                            inputMode="numeric" // 스마트폰 키보드에서 숫자 키보드를 띄우기 위함
                                            pattern="\d*" // 숫자만 입력받도록 하는 정규 표현식
                                            onClick={() => { clearSecondDigit(index) }}
                                            onChange={(e) => secondInputChange(index, e.target.value)}
                                            ref={(el) => (inputRefsecond.current[index] = el)}
                                        />
                                    ))

                                    :

                                    checkValues.map((value, index) => (
                                        <CircleInput
                                            className={`secondPwd ${alertAni}`}
                                            key={index}
                                            type="number"
                                            maxLength="1"
                                            value={value}
                                            hasValue={value !== ''}
                                            name={index}
                                            onKeyUp={(e) => handleKeyUpCheck(index, e)}
                                            inputMode="numeric" // 스마트폰 키보드에서 숫자 키보드를 띄우기 위함
                                            pattern="\d*" // 숫자만 입력받도록 하는 정규 표현식
                                            onClick={() => { clearCheckDigit(index) }}
                                            onChange={(e) => checkInputChange(index, e.target.value)}
                                            ref={(el) => (inputRefCheck.current[index] = el)}
                                        />
                                    ))

                        }
                    </FormControl>
                </Box>
                {
                    !firstCheck && !secondCheck ?

                        <Flex height='15%' mt='10%' p='2%'>

                            <Button
                                mx='auto'
                                border='1px solid'
                                borderColor='#C1B9B9'
                                borderRadius='0px'
                                width='40%'
                                height='5vh'
                                minH='40px'
                                className="mainText"
                                onClick={() => { setNowComponent('email') }}
                            >
                                이메일 검증
                            </Button>
                        </Flex>
                        :
                        null
                }
            </Box >

        </>

    );
}
