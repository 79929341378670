import React from 'react';
import '../css/App.css';
import { Box, Image, Text, Button } from "@chakra-ui/react";
import veta from "../assets/img/service/veta_banana.png"
import { RiInstallLine } from "react-icons/ri";
export default function Mobile({  deferredPrompt, handleInstallClick }) {
  return (
    <Box
      overflow='hidden'
      backgroundColor='#008BFF'
      // backgroundImage={firstPage}
      // backgroundSize='cover'
      // backgroundPosition='center'
      // backgroundRepeat='no-repeat'
      width='100vw' height='100vh'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      align='center'
      justify='center'
      onClick={() => { handleInstallClick() }}
    >
      <Box align='center'>
        <Image className='loadingKUDETA' src={veta} width='264px' height='78px' />
        <Text color='white'>
          모바일 화면에 적합한 App입니다.
        </Text>
        <Text color='white'>
          다운로드나 홈 화면에 추가 후 사용해주세요.
        </Text>
        {/* {deferredPrompt && (
          <Button width='75px' height='75px' onClick={handleInstallClick}>
            <RiInstallLine size='75px' />
          </Button>
        )} */}
      </Box>
    </Box>
  );
}