// React
import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
// Components
import '../../../../css/main.css';
import Loading_3 from "../../../../assets/img/service/Loading_3.gif";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function ScanLoading() {

    return (
        <>

            <Flex
                width='100%'
                height='100%'
                align='center'
                direction='column'
            >

                <Image className="qrScanLoadingImage" mb='5vh' src={Loading_3}  />

                <Text
                   className="payLoadingMainText"
                >
                    결제 승인 대기 중
                </Text>
            </Flex>
        </>
    );
}
