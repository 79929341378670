// React
import React from "react";
import { Flex, Box, Text, Icon, Input } from "@chakra-ui/react";
import { BsPencilSquare, BsCopy } from "react-icons/bs";
// Components
import '../../../../../css/main.css';
import { Button } from "reactstrap";

export default function Main({ userInfo, onCopy, setAssetComponent, updatePn, onChange, updatePhoneNumber, checkChange, updateAs, updateAsName }) {
    return (
        <>

            <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                <Text>
                    이메일
                </Text>
                <Text>
                    {userInfo.email}
                </Text>
            </Flex>


            <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                <Text>
                    비밀번호
                </Text>
                <Box backgroundColor='#46D1E8' borderRadius='5px' paddingX='5px'>
                    <Button onClick={() => { setAssetComponent('pwd') }}>
                        <Text textColor='white' >
                            변경하기
                        </Text>
                    </Button>
                </Box>
            </Flex>
            <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                <Text>
                    2차 비밀번호
                </Text>
                <Box backgroundColor='#46D1E8' borderRadius='5px' paddingX='5px'>
                    <Button onClick={() => { setAssetComponent('second') }}>
                        <Text textColor='white' >
                            변경하기
                        </Text>
                    </Button>
                </Box>
            </Flex>
            <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                <Text>
                    이름
                </Text>
                <Text>
                    {userInfo.name}
                </Text>
            </Flex>
            <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                <Text>
                    닉네임
                </Text>
                {
                    updateAs ?
                        <>
                            <Input
                                type='text'
                                autoComplete='off'
                                id="account_name"
                                maxLength='6'
                                placeholder="6글자 이내"
                                name="account_name"
                                onChange={onChange}
                                width='50%'>
                            </Input>
                            <Box backgroundColor='#46D1E8' borderRadius='5px' paddingX='5px' textColor='white'>
                                <Button
                                    onClick={(e) => { updateAsName(e) }}>
                                    OK
                                </Button>
                            </Box>
                        </>
                        :
                        <>
                            <Text ml='auto'>
                                {userInfo.account_name}
                            </Text>
                            <Icon as={BsPencilSquare} color='gray' size='17px' ml='10px' onClick={() => checkChange('As')} />
                        </>
                }
            </Flex>
            <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                <Text>
                    연락처
                </Text>
                {
                    updatePn ?
                        <>
                            <Input
                                type='text'
                                autoComplete='off'
                                id="phone_number"
                                maxLength='11'
                                placeholder="- 제외"
                                name="phone_number"
                                onChange={onChange}
                                width='50%'>
                            </Input>
                            <Box backgroundColor='#46D1E8' borderRadius='5px' paddingX='5px' textColor='white'>
                                <Button
                                    onClick={(e) => { updatePhoneNumber(e) }}>
                                    OK
                                </Button>
                            </Box>
                        </>
                        :
                        <>
                            <Text ml='auto'>
                                {userInfo.phone ? userInfo.phone : "미등록"}
                            </Text>
                            <Icon as={BsPencilSquare} color='gray' size='17px' ml='10px' onClick={() => checkChange('Pn')} />
                        </>
                }
            </Flex>
            <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                <Text>
                    계좌번호
                </Text>
                <Text ml='auto'>
                    {userInfo.address && (userInfo.address).substring(0, 10) + '...'}
                </Text>
                <Icon as={BsCopy} color='gray' size='17px' ml='10px'
                    onClick={onCopy} />
            </Flex>
            <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                <Text>
                    &nbsp;
                </Text>
            </Flex>
        </>

    );
}