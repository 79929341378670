// React
import React, { useEffect, useState } from "react";
import axios from 'axios';
// External Libraries
import { Flex, Text, Button, Input, Icon, useColorModeValue, Box } from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
// Components
import Card from "../../../../components/card/Card.js";
import TransferLoding from "./TransferLoding.js";
import TransferFinish from "./TransferFinish.js";
import SecondPwd from "../confirm/SecondPwd.js";
/* global BigInt */
// Assets

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function PaymentCard({ transferReceiver, userInfo, setNowComponent }) {
    const [payComponent, setPayComponent] = useState('');
    const [alertText, setAlertText] = useState('');
    const [secondPwdCheck, setSecondPwdCheck] = useState(false);
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    // input data
    const [inputs, setInputs] = useState({
        amount: ""
    });
    // input variable
    const { amount } = inputs;
    function onChange(e) {
        if (e.target.value !== '<') {
            const pureKrc = e.target.value.replace(/[^0-9]/g, "").replaceAll(',', '');
            setInputs({
                ...inputs,
                amount: pureKrc.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            });
            if (!userInfo.amount) {
                setAlertText('잔액이 부족합니다.');
            } else if (BigInt(pureKrc) > BigInt(userInfo.amount)) {
                setAlertText('잔액이 부족합니다.');
            } else {
                setAlertText('');
            }
        } // if else end

    } // fn onChange end

    function transfer() {
        if (!transferReceiver) {
            setNowComponent('friend')
        }
        if (amount.startsWith('0')) {
            return;
        }
        if (userInfo.amount && BigInt(amount.replace(/[^0-9]/g, "").replaceAll(',', '')) > BigInt(userInfo.amount)) {
            setAlertText('잔액이 부족합니다.');
            return;
        }
        if (payComponent === 'confirm') {
            setPayComponent('second');
        } else {
            setPayComponent('confirm');
        }
    }

    useEffect(() => {
        if (secondPwdCheck) {
            axios.post('/api/account/transfer', {
                amount: amount.replace(/[^0-9]/g, "").replaceAll(',', ''),
                target: transferReceiver.address
            }, {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))}`,
            }).then((response) => {         // api return
                setPayComponent('finish');
                setSecondPwdCheck(false);
            }).catch(error => {
                setAlertText('잠시 후 다시 시도해주세요.');
                setPayComponent('');
                setSecondPwdCheck(false);
            })
        }
    }, [secondPwdCheck])


    // input value to max 
    function autoMax() {
        setInputs({
            amount: (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
        });
        setAlertText('');
    }// fn autoMax end
    return (
        payComponent === 'second' ?
            <SecondPwd setComponent={setPayComponent} userInfo={userInfo} setSecondPwdCheck={setSecondPwdCheck} />
            :
            payComponent === 'loading' ?
                <TransferLoding />
                :
                payComponent === 'finish' ?
                    <TransferFinish amount={amount} setNowComponent={setNowComponent} />
                    :
                    <>
                        {
                            payComponent === 'confirm' ?
                                <Box
                                    width='100%'
                                    height='100%'
                                    position='relative'
                                    align='center'>
                                    <Flex position='relative' top='3vh' w='90%' direction='column'>
                                        <Icon className="insideBackArrow" onClick={() => setPayComponent('')} color='black' as={BsArrowLeft} />
                                        <Card
                                            textAlign='left'
                                            height='80%'
                                            overflow='hidden'
                                            alignSelf='center'
                                            bgPosition='10%'
                                            mx='auto'
                                            width='100%'
                                            py='10px'
                                            px='0px'>
                                            <Flex direction='column' h='100%' w='100%' overflow='auto'>
                                                <Text isTruncated className="paymentSendText">
                                                    Send
                                                </Text>
                                                <Text className="paymentBigText" >
                                                    {transferReceiver ?
                                                        transferReceiver.name
                                                        :
                                                        null
                                                    }
                                                </Text>
                                                <Text isTruncated className="paymentSmallText" >
                                                    {
                                                        transferReceiver ?
                                                            (transferReceiver.phone)
                                                            :
                                                            null
                                                    }
                                                </Text>
                                                <Flex align='center' mt='5%' >
                                                    <Text isTruncated mr='20px' className="paymentBigText" >
                                                        {amount}
                                                    </Text>
                                                    <Text mt='8px' className="paymentKRDCText" >
                                                        VETA
                                                    </Text>
                                                </Flex>
                                            </Flex>
                                        </Card >
                                        <Button _hover={{ backgroundColor: '#46D1E8' }} className='underbarButton' position='fixed' zIndex='5' bottom='0' height='15vh' align='center' alignSelf='center' backgroundColor='#46D1E8' borderRadius='0' onClick={() => transfer()}>
                                            <Text color='white' className="paymentKRDCText" >
                                                보내기
                                            </Text>
                                        </Button>
                                    </Flex>
                                </Box>
                                :
                                <Box
                                    width='100%'
                                    height='100%'
                                    position='relative'
                                    align='center'
                                >
                                    <Flex position='relative' top='3vh' w='90%' direction='column'>
                                        <Icon className="insideBackArrow" onClick={() => setNowComponent('friend')} color='black' as={BsArrowLeft} />
                                        <Card
                                            textAlign='left'
                                            height='80%'
                                            overflow='hidden'
                                            alignSelf='center'
                                            bgPosition='10%'
                                            mx='auto'
                                            width='100%'
                                            py='10px'
                                            px='0px'>
                                            <Flex direction='column' h='100%' w='100%' overflow='auto'>
                                                <Text isTruncated className="paymentSendText" >
                                                    Send
                                                </Text>
                                                <Text className="paymentBigText" >
                                                    {transferReceiver ?
                                                        transferReceiver.name
                                                        :
                                                        null}
                                                </Text>
                                                <Text isTruncated className="paymentSmallText">
                                                    {transferReceiver ?
                                                        (transferReceiver.phone)
                                                        :
                                                        null}
                                                </Text>
                                                <Input
                                                    my='5%'
                                                    pl='0px'
                                                    variant='auth'
                                                    className="paymentInput"
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='얼마나 송금할까요?'
                                                    id="amount"
                                                    name="amount"
                                                    value={amount}
                                                    onChange={onChange}>
                                                </Input>
                                            </Flex>
                                        </Card >
                                        <Button align='left' backgroundColor='#D9D9D9' boxShadow={shadow} className="payCardButtomBtn" onClick={() => autoMax()}>
                                            {
                                                alertText !== '' ?
                                                    <Text className="alertText" color='red' align='left'>
                                                        {alertText}
                                                    </Text>
                                                    :
                                                    <Text color='#6F6F6F' align='left'>
                                                        송금가능 : {userInfo.amount ? (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0 : 0} VETA 입력
                                                    </Text>
                                            }
                                        </Button>
                                        {
                                            amount !== '' && alertText === '' && !amount.startsWith('0') ?
                                                <Button position='fixed' className='underbarButton' zIndex='5' bottom='0' height='10vh' align='center' alignSelf='center' backgroundColor='#46D1E8' borderRadius='0' onClick={() => transfer()}>
                                                    <Text color='white' className="paymentKRDCText" >
                                                        다음
                                                    </Text>
                                                </Button>
                                                : null
                                        }
                                    </Flex>
                                </Box>
                        }
                    </>
    );
}
