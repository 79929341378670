// React
import React, { useState } from "react";
import { Box, Text, Icon, Flex, useColorModeValue, Input } from "@chakra-ui/react";
// Components
import '../../../../css/main.css';
import Card from "../../../../components/card/Card.js";
import { BsArrowLeft } from "react-icons/bs";
import { useEffect } from "react";
import { useSwipeable } from 'react-swipeable';
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function SeatCard({ myModalOpen, BookModalOpen, floreList, seatList, setWhereComponent, setSeatModalOpen, floreInfo }) {
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    const [changeOn, setChangeOn] = useState(false);
    const [flore] = useState('');
    const [nowF, setNowF] = useState(floreInfo.flore);
    function onChange(e) {
        setNowF(String(e.target.value));
        setChangeOn(false);
    }

    const [currentComponentIndex, setCurrentComponentIndex] = useState(0);
    // 화면 슬라이드 코드
    let [slide, setSlide] = useState('');
    let [start, setStart] = useState('');
    useEffect(() => {
        // tab의 상태가 변할때 (클릭 후 다른탭 열리면) 0.1초 뒤 'end' className 바인딩
        const fadeTimer = setTimeout(() => { setSlide('end'); }, 100)
        return () => {
            // 기존 fadeTimer 제거 후 class 빈 값으로 변경
            clearTimeout(fadeTimer);
            setSlide('');
        }
    }, [floreInfo])

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('left'),
        onSwipedRight: () => handleSwipe('right'),
    }, []);

    const handleSwipe = (direction) => {
        setCurrentComponentIndex((prevIndex) => {
            let newIndex;
            if (direction === 'left') {
                if (nowF !== '6') {
                    newIndex = Number(nowF) + 1;
                } else {
                    newIndex = 2;
                }
                // newIndex = Math.min(prevIndex + 1, componentList.length - 1);
                setStart('startLeft');
            } else if (direction === 'right') {
                if (nowF !== '2') {
                    newIndex = Number(nowF) - 1;
                } else {
                    newIndex = 6;
                }
                setStart('startRight');
            }

            if (newIndex !== prevIndex) {
                setNowF(String(newIndex));
            }

            return newIndex;
        });
    };
    return (
        <>
            <Box
                align='center'
                position='relative'
                {...handlers}
            >
                <Flex
                    width='90%'
                    p='10px'
                    mx='auto'
                    height='10vh'
                    mb='2vh'
                    direction='column'
                >
                    <Icon className="backArrowOrder"
                        onClick={() => setWhereComponent('')}
                        as={BsArrowLeft}
                    />
                </Flex>


                <Flex align='center' justify='center'>
                    <Text>
                        현재 층 :
                    </Text>
                    {
                        changeOn ?
                            <>
                                <Box
                                    width='100px'
                                >

                                    <Input
                                        type='text'
                                        textAlign='center'
                                        placeholder={nowF}
                                        autoComplete="off"
                                        backgroundColor='rgba(255,255,255,0.9)'
                                        borderRadius='0px'
                                        list={'floreList'}
                                        id="flore"
                                        name="flore"
                                        value={flore}
                                        onChange={(e) => onChange(e)} />
                                </Box>
                                <datalist id="floreList">
                                    {floreList.map((option) => (
                                        <option
                                            value={option.flore}
                                            key={option.flore}
                                            name={option.flore}>
                                        </option>
                                    ))}

                                </datalist>
                            </>
                            :
                            <Box
                                border='1px solid'
                                borderColor='gray.300'
                                width='100px'
                                backgroundColor='rgba(255,255,255,0.9)'
                                borderRadius='0px'
                            >
                                <Text
                                    justifySelf='center'
                                    onClick={() => setChangeOn(true)}
                                >
                                    {nowF}
                                </Text>
                            </Box>
                    }
                </Flex>

                <div className={start + slide}>
                    {
                        myModalOpen || BookModalOpen ?
                            null
                            :
                            <>
                                <Icon as={BsChevronCompactLeft} position='fixed' left='0' top='50%' color='rgba(0,0,0,0.5)' />
                                <Icon as={BsChevronCompactRight} position='fixed' left='95vw' top='50%' color='rgba(0,0,0,0.5)' />
                            </>
                    }
                    <Flex wrap='wrap' gap='5vw' width='90vw' mt='3vh'>
                        {seatList &&
                            seatList.filter((i) => i.flore === nowF)
                                .map((seat, index) => (
                                    <Card
                                        w='25vw' alignSelf='center' mx='auto' align='center'
                                        boxShadow={shadow}
                                        key={index}
                                        backgroundColor={seat.seat_active === '0' ? 'rgba(70, 209, 232, 1)' : "red"}
                                        onClick={() => {
                                            // setSeatInfo(seat);
                                            setSeatModalOpen(seat);
                                        }}
                                        isTruncated
                                    >
                                        <Text color='white'>
                                            {seat.seat_name}
                                        </Text>
                                    </Card>
                                ))
                        }
                    </Flex>
                </div>
            </Box >
        </>

    );
}
