// React
import React from "react";
import { Text, Icon, Flex, Button, Modal, useColorModeValue, ModalContent } from "@chakra-ui/react";
import axios from 'axios';
// Components
import '../../../../css/Page.css';
import { BsXLg, BsCircleFill } from "react-icons/bs";
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
*/
export default function SelectModal({ onClose, seatInfo, setUserUpdate, userSeatInfo, userInfo, mySeatInfo, setWhereUpdate, finishSeat }) {
    registerLocale('ko', ko); // 한국어 로케일 등록
    // Variables for Card CSS Styling
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
    );
    const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.200" }
    );

    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function reservation(e) {
        if (window.confirm("이 좌석으로 사용하시겠습니까?")) {

            // if (!book) {
            //     setBook(true);
            // } else if (startDate && finishDate) {
            e.preventDefault();

            axios.post('/api/seat/reservation', {
                seat_number: seatInfo.seat_number,
                start_time: "",
                finish_time: ""
            })
                .then((response) => {
                    alert("예약 완료 되었습니다.");
                    setUserUpdate(true);
                    setWhereUpdate(true);
                    onClose();
                })
                .catch((error) => {
                    // alert(error);
                })
        }
        // }
    }



    return (
        <div className='modal'>
            <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
                <ModalContent
                    my='auto'
                    className='sirenOrderModal'
                    border='1px solid'
                    borderColor='white'
                    backgroundColor='rgba(229, 244, 255, 1)'
                    backgroundRepeat='no-repeat'
                    bgSize='cover'
                    bgPosition='10%'
                    textColor='black'
                >
                    <Flex>
                        <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
                    </Flex>

                    <Flex
                        className='modalMainCloseText'
                        width='100%'
                        mb='3%'
                        align='center'
                        direction='column'
                    >
                        <Text
                        >
                            [ 좌석 정보 ]
                        </Text>
                    </Flex>

                    <Flex
                        mt='5%'
                        className='modalMainInsideDefaultText'
                        justifyContent='space-evenly'
                    >
                        <Flex
                            direction='column'
                            width='80%'
                        >
                            <Flex justifyContent='space-between'>
                                <Flex align='center'>
                                    <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                                    <Text>
                                        좌석명
                                    </Text>
                                </Flex>
                                <Text
                                    fontWeight='700'
                                    textColor='rgba(2, 58, 142, 1)'
                                >
                                    {seatInfo.seat_name}
                                </Text>
                            </Flex>
                            <Flex justifyContent='space-between'>
                                <Flex align='center'>
                                    <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                                    <Text
                                    >
                                        형태:
                                    </Text>
                                </Flex>
                                <Text
                                    fontWeight='700'
                                    textColor='rgba(2, 58, 142, 1)'
                                >
                                    {seatInfo.seat_number}
                                </Text>
                            </Flex>
                            <Flex justifyContent='space-between'>
                                <Flex align='center'>
                                    <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                                    <Text
                                    >
                                        사용 유무
                                    </Text>
                                </Flex>
                                <Text
                                    fontWeight='700'
                                    textColor='rgba(2, 58, 142, 1)'
                                >
                                    {seatInfo.seat_active === '1' ? "사용중" : "공석"}
                                </Text>
                            </Flex>

                            {/* {
                                book ?
                                    <>
                                        <Flex
                                            justify='space-between'
                                        >
                                            <Text>
                                                시작시간
                                            </Text>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={handleStartChange}
                                                showTimeSelect
                                                dateFormat="Pp"
                                                timeFormat="p"
                                                locale="ko"
                                            />
                                        </Flex>
                                        <Flex
                                            justify='space-between'
                                        >
                                            <Text>
                                                종료시간
                                            </Text>
                                            <DatePicker
                                                selected={finishDate}
                                                onChange={handleFinishChange}
                                                showTimeSelect
                                                dateFormat="Pp"
                                                timeFormat="p"
                                                locale="ko"
                                            />
                                        </Flex>
                                    </>
                                    :
                                    null
                            } */}
                            {
                                userSeatInfo && seatInfo.seat_active === '1' ?
                                    <>
                                        <Flex
                                            justify='space-between' align='center'
                                        >
                                            <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                                            <Text mr='auto'>
                                                사용자
                                            </Text>
                                            <Text>
                                                {userSeatInfo.name}
                                            </Text>
                                        </Flex>
                                        <Flex
                                            justify='space-between' align='center'
                                        >
                                            <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                                            <Text mr='auto'>
                                                시작시간
                                            </Text>
                                            <Text>
                                                {userSeatInfo.start_date} {userSeatInfo.start_time}
                                            </Text>
                                        </Flex>
                                        {/* <Flex
                                            justify='space-between'
                                        >
                                            <Icon as={BsCircleFill} className='modalMainInsideDefaultDot' />
                                            <Text mr='auto'>
                                                종료시간
                                            </Text>
                                            <Text>
                                                {(userSeatInfo.finish_time).replace('_', ' ')}
                                            </Text>
                                        </Flex> */}
                                    </>
                                    :
                                    null
                            }
                        </Flex>

                    </Flex>

                    <Flex
                        mt='auto'
                        mb='3vh'
                        justify='space-around'
                    >
                        {
                            seatInfo.seat_active === '0' && mySeatInfo.using === '0' ?
                                <Button
                                    className='modalMainSmallBtn'
                                    _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                    _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                    _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                    shadow={shadow}
                                    backgroundColor='rgba(22, 204, 234, 1)'
                                    border='1px solid rgba(217, 217, 217, 1)'
                                    textColor='white'
                                    align='center'
                                    onClick={(e) => { reservation(e) }}
                                >
                                    < Text
                                        className='modalMainSmallBtnText'
                                        mx='auto'
                                    >
                                        예약
                                    </Text>
                                </Button>
                                :
                                seatInfo.seat_active === '0' && mySeatInfo.using === '1' ?
                                    <Button
                                        className='modalMainSmallBtn'
                                        _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                        _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                        _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                        shadow={shadow}
                                        backgroundColor='rgba(22, 204, 234, 1)'
                                        border='1px solid rgba(217, 217, 217, 1)'
                                        textColor='white'
                                        align='center'
                                        disabled
                                        onClick={(e) => { reservation(e) }}
                                    >
                                        < Text
                                            className='modalMainSmallBtnText'
                                            mx='auto'
                                        >
                                            사용중
                                        </Text>
                                    </Button>
                                    :
                                    userSeatInfo && userSeatInfo.email === userInfo.email ?
                                        <Button
                                            className='modalMainSmallBtn'
                                            _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                            _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                            _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                            shadow={shadow}
                                            backgroundColor='rgba(22, 204, 234, 1)'
                                            border='1px solid rgba(217, 217, 217, 1)'
                                            textColor='white'
                                            align='center'
                                            onClick={(e) => { finishSeat(e) }}
                                        >
                                            < Text
                                                className='modalMainSmallBtnText'
                                                mx='auto'
                                            >
                                                종료
                                            </Text>
                                        </Button>

                                        :
                                        <Button
                                            className='modalMainSmallBtn'
                                            _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                            _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                            _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                                            shadow={shadow}
                                            backgroundColor='rgba(22, 204, 234, 1)'
                                            border='1px solid rgba(217, 217, 217, 1)'
                                            textColor='white'
                                            align='center'
                                            disabled
                                            onClick={(e) => { reservation(e) }}
                                        >
                                            < Text
                                                className='modalMainSmallBtnText'
                                                mx='auto'
                                            >
                                                사용중
                                            </Text>
                                        </Button>
                        }
                        <Button
                            className='modalMainSmallBtn'
                            _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                            _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                            _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
                            shadow={shadow}
                            backgroundColor='rgba(22, 204, 234, 1)'
                            border='1px solid rgba(217, 217, 217, 1)'
                            textColor='white'
                            align='center'
                            onClick={() => {
                                onClose();
                            }}
                        >
                            < Text
                                className='modalMainSmallBtnText'
                                mx='auto'
                            >
                                취소
                            </Text>
                        </Button>

                    </Flex>

                </ModalContent>
            </Modal>
        </div>
    );
}