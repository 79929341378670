// React
import React, { useState } from "react";
import { Flex, Box, Text, Icon, Button } from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
import axios from 'axios';
// Components
import '../../../../css/main.css';
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function TransactionDetailCard({ userInfo, transactionInfo, setTxComponent,setTxUpdate }) {
    // 닫기 애니메이션 닫을 컴포넌트에 ${effect} 
    const [effect, setEffect] = useState('');
    const onClickBtn = () => {
        setEffect('unmount');
        setTimeout(() => {
            setTxComponent('');
        }, 400)
    };
    const titleStyle = {
        fontWeight: '700',
        fontSize: { base: '14px' },
        color: '#008BFF'
    };
    const textStyle = {
        fontWeight: '400',
        fontSize: { base: '14px' }
    };
    function deleteTx() {
        if (window.confirm("삭제하시겠습니까?")) {
            axios.post('/api/user/txDelete', {
                transactionHash: transactionInfo.transaction_hash
            })
                .then((response) => {
                    setTxUpdate(true);
                    onClickBtn();
                })
                .catch((error) => {
                    // alert(error)
                })
        }
    }
    return (
        <div className={`slide-up-container  ${effect}`}>
            <div className="slide-up-content">
                <Box
                    borderTop='1px solid gray'
                    width='100%'
                    height='100%'
                    position='relative'
                    align='center'
                >
                    <Icon className="backArrow" onClick={() => onClickBtn()} color='black' as={BsArrowLeft} />
                    <Flex position='relative' top='7vh' w='90%' direction='column'>

                        <Text
                            mt='3vh'
                            className="assetMainText"
                            mr='auto'>
                            거래 세부 정보
                        </Text>
                    </Flex>
                    <Box position='relative' top='10vh' w='90%'>
                        <Box border='1px solid' borderColor='gray.400' p='3%' align='left'>
                            <Box >
                                <Box m='5%'>
                                    <Text className={titleStyle} color='#008BFF'>
                                        Date
                                    </Text>
                                    <Text className={textStyle}>
                                        {transactionInfo.date}
                                    </Text>
                                </Box>

                                <Box m='5%'>
                                    <Text className={titleStyle} color='#008BFF'>
                                        From
                                    </Text>
                                    <Text className={textStyle}>
                                        Name&nbsp;:&nbsp;{transactionInfo.source_name}
                                    </Text>
                                    <Text isTruncated className={textStyle}>
                                        Address&nbsp;:&nbsp;{transactionInfo.source_account_address}
                                    </Text>
                                    <Flex>
                                        <Text className={textStyle}>
                                            VETA&nbsp;:&nbsp;
                                        </Text>
                                        <Text className={textStyle} color={"red.500"}>
                                            -
                                        </Text>
                                        <Text className={textStyle} color={"red.500"}>
                                            {transactionInfo.amount}
                                        </Text>
                                    </Flex>
                                </Box>

                                <Box m='5%'>
                                    <Text className={titleStyle} color='#008BFF'>
                                        to
                                    </Text>
                                    <Text className={textStyle}>
                                        Name&nbsp;:&nbsp;{transactionInfo.target_name}
                                    </Text>
                                    <Text isTruncated className={textStyle}>
                                        Address&nbsp;:&nbsp;{transactionInfo.target_account_address}
                                    </Text>
                                    <Text className={textStyle}>
                                        VETA&nbsp;:&nbsp;+{transactionInfo.amount}
                                    </Text>
                                </Box>
                            </Box>
                        </Box>

                        <Flex
                            justifyContent='space-between'
                            width='100%'    
                            pb='50px'
                            borderBottom='1px solid gray'
                        >
                            <Button
                                border='1px solid'
                                borderColor='#C1B9B9'
                                borderRadius='0px'
                                width='50%'
                                height='5vh'
                                className="mainText"
                                onClick={() => { onClickBtn() }}
                            >
                                닫기
                            </Button>
                            <Button
                                border='1px solid'
                                borderColor='#C1B9B9'
                                borderRadius='0px'
                                width='50%'
                                height='5vh'
                                className="mainText"
                                onClick={() => { deleteTx() }}
                            >
                                삭제
                            </Button>
                        </Flex>
                    </Box>
                </Box>
            </div>
        </div>
    );
}
