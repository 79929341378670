// React
import React from "react";
import CircleInput from '../secondPwd/secondPwd'
// External Libraries
import { Box, FormControl, Text } from "@chakra-ui/react";

export default function StepTwoUser({ inputValueSecond, secondCheck, secondInputChange, inputRefsecond, alertAni, alertText, inputValues, handleInputChange, inputRefs, clearFirstDigit, clearSecondDigit,handleKeyUpSecond,handleKeyUpFirst }) {
    return (
        <>
            <Box
                align='center'
                mx='auto'
                textAlign='left'
                mb='80%'
            >
                <Box width='100%' align='center'
                    mx='auto'
                    mt='15%'
                >
                    {
                        alertText ?
                            <Text
                                textColor='red'
                                className={`secondMiddleText ${alertAni}`}
                            >
                                {alertText}
                            </Text>
                            :

                            secondCheck ?

                                <Text
                                    color='white'
                                    className="secondSmallText"
                                >
                                    한번 더 입력해 주세요.
                                </Text>

                                :
                                <Text
                                    color='white'
                                    className="secondSmallText"
                                >
                                    결제시 사용할 비밀번호 6자리를 입력해 주세요.
                                </Text>
                    }
                    {
                        secondCheck ?
                            <FormControl mt='2vh'>
                                {inputValueSecond.map((value, index) => (
                                    <CircleInput
                                        className={`secondPwd ${alertAni}`}
                                        key={index}
                                        type="number"
                                        maxLength="1"
                                        value={value}
                                        hasValue={value !== ''}
                                        onKeyUp={(e) => handleKeyUpSecond(index, e)}
                                        inputMode="numeric" // 스마트폰 키보드에서 숫자 키보드를 띄우기 위함
                                        pattern="\d*" // 숫자만 입력받도록 하는 정규 표현식
                                        onClick={() => { clearSecondDigit(index) }}
                                        onChange={(e) => secondInputChange(index, e.target.value)}
                                        ref={(el) => (inputRefsecond.current[index] = el)}
                                    />
                                ))}
                            </FormControl>
                            :
                            <FormControl mt='2vh'>
                                {inputValues.map((value, index) => (
                                    <CircleInput
                                        className="secondPwd"
                                        key={index}
                                        type="number"
                                        maxLength="1"
                                        value={value}
                                        hasValue={value !== ''}
                                        onKeyUp={(e) => handleKeyUpFirst(index, e)}
                                        inputMode="numeric" // 스마트폰 키보드에서 숫자 키보드를 띄우기 위함
                                        pattern="\d*" // 숫자만 입력받도록 하는 정규 표현식
                                        onClick={() => { clearFirstDigit(index) }}
                                        onChange={(e) => handleInputChange(index, e.target.value)}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                    />
                                ))}
                            </FormControl>
                    }
                </Box>

            </Box >
        </>

    )

}