// React
import React, { useEffect, useState } from "react";
import { Flex, Box, Text, Button, useColorModeValue } from "@chakra-ui/react";
// Components
import Card from "../../../../components/card/Card.js";
// Assets
import axios from "axios";
import CryptoJS from 'crypto-js';
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function PaymentCard({ creditStatus, setNowComponent, buyList, setBuyList, secondInput, setSecondInput, userInfo, takeoutStatus, setOrderOk, storeInfo, totalDiscountAmount, totalDiscountPercent, totalPrice, discountMessage }) {
    const [resultText, setResultText] = useState('');
    function hashVisitorId(visitorId) {
        return CryptoJS.SHA256(visitorId).toString(CryptoJS.enc.Hex);
    }
    useEffect(() => {
        const combinedString = secondInput.join('');
        if (storeInfo.open_status === '1') {
            axios.post("/api/wallet/v1/order", {
                account_name: userInfo.account_name,
                buyList: buyList,
                takeoutStatus: takeoutStatus,
                totalPrice: String(totalPrice),
                secondInput: hashVisitorId(combinedString),
                creditStatus: creditStatus,
                discountMessage: discountMessage
            })
                .then((response) => {
                    if (response.data.error) {
                        alert(response.data.error);
                        setResultText("error");
                    } else if (response.data.status === '0') {
                        alert("다시 시도해주세요.");
                        setResultText("error");
                    } else if (response.data.status === '2') {
                        alert("2차 비밀번호를 확인하세요");
                        setResultText("second");
                    } else if (response.data.status === '1') {
                        alert(userInfo.account_name + "님,\n주문번호 : " + response.data.order_number + "\n주문이 완료 되었습니다.");
                        setOrderOk(true);
                        setResultText("true");
                        setBuyList([]);
                        setNowComponent('');
                    } else {
                        alert("주문이 완료 되었습니다.");
                        setOrderOk(true);
                        setResultText("true");
                        setBuyList([]);
                    }
                })
                .catch((error) => {
                    alert("잠시 후 다시 이용해주세요.");
                    setResultText("error");
                })
            setSecondInput(['', '', '', '', '', '']);
        } else {
            alert("영업 전 입니다.");
            setResultText("error");
        }
    }, [])

    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    return (
        <>
            <Flex
                width='90%'
                p='10px'
                mx='auto'
                height='7vh'
            >
            </Flex>
            <Flex width='90%' height='3vh'>
                <Text fontWeight='700'>
                    {userInfo.account_name}
                </Text>
                <Text>
                    님 주문 내역입니다.
                </Text>
            </Flex>
            <Card
                height='65vh'
                overflow='auto'
                backgroundColor='rgba(70, 209, 232, 0.5)'
                alignSelf='center'
                boxShadow={shadow}
                bgPosition='10%'
                mx='auto'
                width='90%'
                p='10px'>
                <Flex direction='column' h='100%' w='100%' justifyContent='space-between' overflow='auto'>
                    <Box >
                        <Flex direction='column'>
                            {
                                buyList.map((item, index) => (
                                    <Card
                                        borderRadius='5'
                                        boxShadow={shadow}
                                        alignSelf='center'
                                        mx='auto'
                                        mb='1%'
                                        p='2%'
                                        width='100%'
                                        key={index}
                                        isTruncated>
                                        <Flex direction='column'>
                                            <Flex justifyContent='space-between'>
                                                <Text>
                                                    {item.item.name}
                                                </Text>
                                                <Text>
                                                    {item.item.category_big_name}
                                                </Text>
                                                <Text>
                                                    {item.item.category_name}
                                                </Text>
                                            </Flex>
                                            {item.selectedOptions.length > 0 ?
                                                <Flex direction='column' justify='space-evenly' border='1px solid gray'>
                                                    <Text>
                                                        선택 옵션
                                                    </Text>

                                                    {
                                                        item.selectedOptions &&
                                                        item.selectedOptions.map((option, index) => (
                                                            <Flex
                                                                width='100%'
                                                                justify='space-evenly'
                                                                key={index}
                                                            >
                                                                <Text>
                                                                    {option.option_name}
                                                                </Text>

                                                                <Text>
                                                                    +{(option.additional_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                                                                </Text>
                                                            </Flex>
                                                        ))
                                                    }
                                                </Flex>
                                                :
                                                null}
                                            <Flex justifyContent='space-between'>
                                                <Text mr='12px'>
                                                    가격 :
                                                </Text>
                                                {
                                                    totalDiscountAmount || totalDiscountPercent ?
                                                        <Flex mr='auto '>
                                                            <Text
                                                                color='red'
                                                                textDecoration='line-through'
                                                            >
                                                                {(item.item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                                            </Text>
                                                            <Text>
                                                                &nbsp; -&gt; &nbsp;
                                                            </Text>
                                                            {
                                                                totalDiscountAmount ?
                                                                    <Text
                                                                        whiteSpace='nowrap'
                                                                        overflow='hidden'
                                                                        textOverflow='ellipsis'
                                                                    >
                                                                        {(Number(item.item.price) - Number(totalDiscountAmount)) > 0 ? (Number(item.item.price) - Number(totalDiscountAmount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                                                    </Text>

                                                                    :
                                                                    totalDiscountPercent ?
                                                                        <Text
                                                                            whiteSpace='nowrap'
                                                                            overflow='hidden'
                                                                            textOverflow='ellipsis'
                                                                        >
                                                                            {(Number(item.item.price) * (100 - Number(totalDiscountPercent)) / 100) > 0 ? (parseInt((Number(item.item.price) * (100 - Number(totalDiscountPercent)) / 100))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                                                        </Text>
                                                                        :
                                                                        <Text
                                                                            whiteSpace='nowrap'
                                                                            overflow='hidden'
                                                                            textOverflow='ellipsis'
                                                                        >
                                                                            {(item.item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                                                        </Text>
                                                            }
                                                        </Flex>
                                                        :
                                                        <Text isTruncated>
                                                            {item.item.price}
                                                        </Text>
                                                }
                                                <Text>
                                                    수량 : {item.quantity}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Card>

                                ))
                            }
                        </Flex>
                    </Box>
                </Flex>
            </Card>
            <Box align='center'>
                {
                    resultText && resultText !== 'error' ?
                        <Button mt='3vh' h='7vh' w='90%'
                            alignSelf='center'
                            variant='outline'
                            colorScheme='blue'
                            fontWeight='500'
                            onClick={() => setNowComponent('order')}>
                            <Text>
                                주문 내역 확인하기
                            </Text>

                        </Button>
                        :
                        resultText && resultText === 'error' ?
                            <Button mt='3vh' h='7vh' w='90%'
                                alignSelf='center'
                                variant='outline'
                                colorScheme='red'
                                fontWeight='500' onClick={() => setNowComponent('detail')}>
                                <Text>
                                    잠시 후 다시 이용해주세요
                                </Text>
                            </Button>

                            :
                            <Button mt='3vh' h='7vh' w='90%'
                                alignSelf='center'
                                variant='outline'
                                colorScheme='blue'
                                fontWeight='500'
                                isLoading
                                loadingText='진행중..' />
                }
            </Box>
        </>
    );
}
