// React
import React from "react";
import { Flex, Box, Image, Text,  Button, Icon } from "@chakra-ui/react";
// Components
import { BsArrowLeft } from "react-icons/bs";
import '../../../../css/main.css';
import developing from "../../../../assets/img/service/developing.png";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function Developing({ setNowComponent }) {



    return (

        <>

            <Icon className="backArrow" onClick={() => setNowComponent('mint')} color='black' as={BsArrowLeft} />
            <Box
                width='100%'
                height='100%'
                align='center'
                position='relative'
            >
                <Flex
                    direction='column'
                    className="developingText"
                >
                    <Text>
                        veta 개발팀에서
                    </Text>
                    <Text>
                        열심히 서비스 개발 중입니다
                    </Text>
                </Flex>

                <Flex
                    mt='5vh'
                    justify='center'
                >

                    <Image src={developing} className="developingImage" />
                </Flex>


                <Button
                    className="buttomBtnText underbarButton"
                    position='fixed'
                    top='95vh'
                    left='0'
                    backgroundColor='#46D1E8'
                    height='5vh'
                    borderRadius='0px'
                    onClick={() => setNowComponent('mint')}
                >
                    <Text
                        color='white'
                    >
                        mint 하기
                    </Text>
                </Button>
            </Box >
        </>

    );
}
