import React from 'react';
import { Flex, Box, Text, Button, Image } from "@chakra-ui/react";
//component
import paymentOk from "../../../../assets/img/service/paymentOk.gif";
export default function QRPayFinish({ amount, setNowComponent }) {

  return (
    <>


      <Box align='center' className='qrPayFinishImageBox'>
        <Image className='mintLoadingImage' src={paymentOk} />
      </Box>
      <Flex align='center' direction='column' fontWeight='700' textColor='#004A56'>
        <Flex>
          <Text className='qrPayFinishBigText'>
            {amount}
          </Text>
          <Text  ml ='10px'mt='auto' className='qrPayFinishSmallText' >
            VETA
          </Text>
        </Flex>
        <Text className='qrPayFinishMiddleText'>
          전송 완료 되었습니다.
        </Text>
      </Flex>
      <Button className='buttomBtnText' position='fixed' zIndex='5' bottom='0' height='15vh' width='100%' align='center' alignSelf='center' backgroundColor='#46D1E8' borderRadius='0' onClick={() => setNowComponent('')}>
        <Text color='white'>
          완료
        </Text>
      </Button>
    </>
  );
}