// React
import React, { useState, useEffect } from "react";
import { Flex, Box, Image, Text, Button, Icon } from "@chakra-ui/react";
// Components
import qrImage from '../../../assets/img/service/qrImage.png';
import KRDC from '../../../assets/img/service/KRDC.png';
import clickImage from '../../../assets/img/service/click3.gif';
import '../../../css/main.css';
import { useSwipeable } from 'react-swipeable';
import QRCode from "react-qr-code";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function MainCard({ setNowComponent, userInfo, bnpl }) {

    const [balanceComponent, setBalanceComponent] = useState('');
    const [mainComponent, setMainComponent] = useState('');
    // 슬라이드

    // 송금 화면 슬라이드 코드
    let [slide, setSlide] = useState('');
    let [start, setStart] = useState('');
    useEffect(() => {
        // tab의 상태가 변할때 (클릭 후 다른탭 열리면) 0.1초 뒤 'end' className 바인딩
        const fadeTimer = setTimeout(() => { setSlide('end'); }, 100)
        return () => {
            // 기존 fadeTimer 제거 후 class 빈 값으로 변경
            clearTimeout(fadeTimer);
            setSlide('');
        }
    }, [mainComponent])

    /// 송금기능 화면 드래그 ///

    const [currentComponentIndex, setCurrentComponentIndex] = useState(0);

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('left'),
        onSwipedRight: () => handleSwipe('right'),
    }, []);

    const handleSwipe = (direction) => {
        setCurrentComponentIndex((prevIndex) => {
            let newIndex;
            if (direction === 'left') {
                if (mainComponent === '') {
                    newIndex = 1;
                } else if (mainComponent === 'transfer') {
                    newIndex = 2;
                } else {
                    newIndex = 0;
                }
                // newIndex = Math.min(prevIndex + 1, componentList.length - 1);
                setStart('startLeft');
            } else if (direction === 'right') {
                if (mainComponent === 'transfer') {
                    newIndex = 0;
                } else if (mainComponent === 'qrcode') {
                    newIndex = 1;
                } else {
                    newIndex = 2;
                }
                setStart('startRight');
            }

            if (newIndex !== prevIndex) {
                if (newIndex === 0) {
                    setMainComponent('');
                } else if (newIndex === 1) {
                    setMainComponent('transfer');
                } else if (newIndex === 2) {
                    setMainComponent('qrcode');
                }
            }

            return newIndex;
        });
    };
    /// 송금기능 화면 드래그 종료///

    //잔액 조회 슬라이드 코드
    let [bSlide, setBSlide] = useState('');
    let [bStart, setBStart] = useState('');
    useEffect(() => {
        // tab의 상태가 변할때 (클릭 후 다른탭 열리면) 0.1초 뒤 'end' className 바인딩
        const fadeTimer = setTimeout(() => { setBSlide('end'); }, 100)
        return () => {
            // 기존 fadeTimer 제거 후 class 빈 값으로 변경
            clearTimeout(fadeTimer);
            setBSlide('');
        }
    }, [balanceComponent])

    /// 잔액조회 화면 드래그 ///

    const [balanceComponentIndex, setBalanceComponentIndex] = useState(0);

    const balanceHandlers = useSwipeable({
        onSwipedLeft: () => balanceHandleSwipe('left'),
        onSwipedRight: () => balanceHandleSwipe('right'),
    }, []);

    const balanceHandleSwipe = (direction) => {
        setBalanceComponentIndex((prevIndex) => {
            let newIndex;
            if (direction === 'left') {
                if (balanceComponent === '') {
                    newIndex = 1;
                } else {
                    newIndex = 0;
                }
                setBStart('startLeft');
            } else if (direction === 'right') {
                if (balanceComponent === 'bnpl') {
                    newIndex = 0;
                } else {
                    newIndex = 1;
                }
                setBStart('startRight');
            }

            if (newIndex !== prevIndex) {
                if (newIndex === 0) {
                    setBalanceComponent('');
                } else if (newIndex === 1) {
                    setBalanceComponent('bnpl');
                }
            }

            return newIndex;
        });
    };
    /// 잔액조회 화면 드래그 종료 ///

    return (
        <>
            <Box
                width='100%'
                height='100vh'
                overflowY='hidden'
                align='center'
                position='sticky'
            >
                <Flex
                    width='90%'
                    position='relative'
                    top='8vh'
                >
                    <Text color='black' fontWeight='700'>
                        {userInfo.account_name}
                    </Text>
                    <Text color='black'>
                        님
                    </Text>
                </Flex>
                <Flex
                    position='relative'
                    top='13vh'
                    align='center'
                >
                    {
                        bnpl ?
                            <>
                                <Icon as={BsChevronCompactLeft} color='rgba(0,0,0,0.5)' />
                                <Box
                                    mx='auto'
                                    {...balanceHandlers}
                                    backgroundColor='rgba(70, 209, 232, 0.2)'
                                    width='90%'
                                    minH='5vh'
                                    borderRadius='10px'
                                >
                                    {
                                        balanceComponent === '' ?
                                            <>
                                                <Flex justify='space-evenly'>
                                                    <Text
                                                        color='#008399'
                                                        className="mainBalanceText"
                                                    >
                                                        계좌 잔액
                                                    </Text>
                                                </Flex>
                                                <div className={bStart + bSlide}>
                                                    <Flex className="mainTextBox" my='auto' justify='space-around'>
                                                        <Text
                                                            className="mainAmountText"
                                                        >
                                                            {userInfo.amount && userInfo.amount !== 'error' ?
                                                                (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                                                :
                                                                0
                                                            }
                                                        </Text>
                                                        <Text
                                                            className="mainKRDCText"
                                                        >
                                                            VETA
                                                        </Text>
                                                    </Flex>
                                                </div>
                                            </>
                                            :

                                            <>
                                                <Flex justify='space-evenly'>
                                                    <Text
                                                        color='#008399'
                                                        className="mainBalanceText"
                                                    >
                                                        BNPL 잔액
                                                    </Text>
                                                </Flex>
                                                <div className={bStart + bSlide}>
                                                    <Flex className="mainTextBox" my='auto' justify='space-around'>
                                                        <Text
                                                            className="mainAmountText"
                                                        >
                                                            {userInfo.bnpl_amount && userInfo.bnpl_amount !== 'error' ?
                                                                (userInfo.bnpl_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                                                :
                                                                0
                                                            }
                                                        </Text>
                                                        <Text
                                                            className="mainKRDCText"
                                                        >
                                                            VETA
                                                        </Text>
                                                    </Flex>
                                                </div>
                                            </>
                                    }

                                    <Box style={{ class: "circle-container" }}>
                                        <Button class={balanceComponent === '' ? 'circleColor' : 'circle'} onClick={() => { setBalanceComponent('') }}></Button>
                                        <Button class={balanceComponent === 'bnpl' ? 'circleColor' : 'circle'} onClick={() => { setBalanceComponent('bnpl') }}></Button>
                                    </Box>
                                </Box>
                                <Icon as={BsChevronCompactRight} color='rgba(0,0,0,0.5)' />
                            </>
                            :
                            <Box
                                backgroundColor='rgba(70, 209, 232, 0.2)'
                                width='90%'
                                mx='auto'
                                minH='10vh'
                                borderRadius='10px'
                            >
                                <>
                                    <Flex justify='space-evenly'>
                                        <Text
                                            color='#008399'
                                            className="mainBalanceText"
                                        >
                                            계좌 잔액
                                        </Text>
                                    </Flex>
                                    <Flex className="mainTextBox" my='auto' justify='space-around'>
                                        <Text
                                            className="mainAmountText"
                                        >
                                            {userInfo.amount && userInfo.amount !== 'error' ?
                                                (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                                :
                                                0
                                            }
                                        </Text>
                                        <Text
                                            className="mainKRDCText"
                                        >
                                            VETA
                                        </Text>
                                    </Flex>
                                </>

                            </Box>
                    }
                </Flex>
                <Flex justify='space-between' position='relative' top='20vh'  {...handlers} >
                    <Icon as={BsChevronCompactLeft} my='auto' color='rgba(0,0,0,0.5)' />
                    <Box >
                        {
                            mainComponent === '' ?
                                <div className={start + slide}>
                                    <Box
                                        onClick={() => { setNowComponent('qrScan') }}
                                    >
                                        <Image src={clickImage} className="mainSmallImage" position='relative' />
                                        <Image src={qrImage} className="mainBigImage" position='relative' />
                                        <Text
                                            className="mainText"
                                            my='3vh'
                                        >
                                            QR 스캔
                                        </Text>
                                    </Box>
                                </div>
                                :
                                mainComponent === 'transfer' ?
                                    <div className={start + slide}>
                                        <Box
                                            onClick={() => {
                                                setNowComponent('friend');
                                            }}
                                        >
                                            <Image src={clickImage} className="mainSmallImage"
                                                position='relative' />
                                            <Image src={KRDC} className="mainBigImage" position='relative' />
                                            <Text
                                                className="mainText"
                                                my='3vh'
                                            >
                                                송금
                                            </Text>
                                        </Box>
                                    </div>
                                    :
                                    <div className={start + slide}>
                                        <Box
                                            onClick={() => { setNowComponent('qrCode') }}
                                        >
                                            <Image src={clickImage} className="mainSmallImage" position='relative' />
                                            <QRCode position='relative' value={userInfo.address ? userInfo.address : "loading"} className="mainBigImage" />
                                            <Text
                                                className="mainText"
                                                my='3vh'
                                            >
                                                QR Code
                                            </Text>
                                        </Box>

                                    </div>
                        }
                        <Box style={{ class: "circle-container" }}>
                            <Button class={mainComponent === '' ? 'circleColor' : 'circle'} onClick={() => { setMainComponent('') }}></Button>
                            <Button class={mainComponent === 'transfer' ? 'circleColor' : 'circle'} onClick={() => { setMainComponent('transfer') }}></Button>
                            <Button class={mainComponent === 'qrcode' ? 'circleColor' : 'circle'} onClick={() => { setMainComponent('qrcode') }}></Button>
                        </Box>
                    </Box>
                    <Icon as={BsChevronCompactRight} my='auto' color='rgba(0,0,0,0.5)' />
                </Flex>

                <Flex
                    justifyContent='space-evenly'
                    position='sticky'
                    top='80vh'
                    width='100%'
                >
                    <Button
                        border='1px solid'
                        borderColor='#C1B9B9'
                        borderRadius='0px'
                        width='40%'
                        height='5vh'
                        className="mainText"
                        onClick={() => { setNowComponent('asset') }}
                    >
                        내 정보
                    </Button>
                    <Button
                        border='1px solid'
                        borderColor='#C1B9B9'
                        borderRadius='0px'
                        width='40%'
                        height='5vh'
                        className="mainText"
                        onClick={() => { setNowComponent('transaction') }}
                    >
                        거래 정보
                    </Button>
                </Flex>

            </Box >
        </>

    );
}
