import React from 'react';
import { Flex, Image, Text } from "@chakra-ui/react";
import send from "../../../../assets/img/service/Send_ani.gif"
export default function TransferLoding() {
  return (
    <>
      <Flex justifyContent='center' direction='column' align='center'>

        <Image src={send} mt='20vh' maxH='30vh'/>
        <Text
          className='transferLoadingText' 
          fontWeight='400'
        >
          진행중입니다
        </Text>

      </Flex>
    </>
  );
}