// React
import React, { useState, useEffect } from "react";
// External Libraries
import axios from 'axios';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { Box, Button, Flex, FormControl, Heading, Image, Icon, Input, InputGroup, InputRightElement, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import '../../css/App.css';
import '../../css/phone/Iphone.css';
import '../../css/phone/Android.css';
import '../../css/Switch.css';
import '../../css/slide.css';

import Loding from "../LodingFirst";
import veta from "../../assets/img/service/veta_banana.png";
import Underbar from "../../components/underbar/Underbar";

// 암호화 js 
import CryptoJS from 'crypto-js';
import AutoLogin from "./component/AutoLogin";
/**
 * 
 * Props list - none
 */
export default function SignIn({ setViewComponent, setToken, setSseConnect, viewComponent }) {
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const textColorSecondary = "gray.400";
  // style variables
  const [loding, setLoding] = useState(false);

  const [alertText, setAlertText] = useState("");
  // input data passsword view variables
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [alreadyToken, setAlreadyToken] = useState(false);
  const [alreadyInfo, setAlreadyInfo] = useState();
  const [autoLogin, setAutoLogin] = useState(false);
  const toggleSwitch = () => {
    setAutoLogin((prevIsOn) => !prevIsOn);
  };
  // input data variables
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const { email, password } = inputs;

  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  }
  function hashVisitorId(visitorId) {
    return CryptoJS.SHA256(visitorId).toString(CryptoJS.enc.Hex);
  }
  useEffect(() => {
    axios.post('/api/auth/isToken')
      .then(response => {
        // 만료안됀 토큰
        if (response.data.auto === 'true') {
          setAlreadyToken(true);
          setAlreadyInfo({
            "name": response.data.name,
            "email": response.data.email,
            "auto": response.data.auto
          })
          //만료됐지만 자동로그인 토큰
        }
        else if (response.data.auto === 'create') {
          localStorage.setItem('jwtToken', JSON.stringify(response.data.token));
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
          setToken(response.data.token);
          setAlreadyToken(true);
          setAlreadyInfo({
            "name": response.data.name,
            "email": response.data.email,
            "auto": "true"
          })
        } else {
          delete axios.defaults.headers.common['Authorization'];
        }
      })
      .catch((error) => {
        delete axios.defaults.headers.common['Authorization'];
      })
    setLoding(true);
  }, [viewComponent]);

  function logout() {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      setToken(null);
      axios.post('/api/sign/logout');
      localStorage.removeItem('jwtToken');
      setViewComponent('');
    }
  }
  // async api call
  async function handleSubmit(e) {

    e.preventDefault();

    if (password) {

      // server api call
      axios.post('/api/sign/login', {
        email: email,
        pwd: hashVisitorId(password),
        autoLogin: autoLogin
      },
      ).then((response) => {                       // api return
        setToken(response.data.token);
        // JWT를 로컬 스토리지에 저장합니다.
        localStorage.setItem('jwtToken', JSON.stringify(response.data.token));
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        setViewComponent("Service");
        setSseConnect(true);
      })
        .catch(error => {                            // api call failed
          alert('이메일과 비밀번호를 확인해주세요.');
          const { status } = error.response.data;
          if (status === 21101 || status === 21002 || status === 21001) {
            setAlertText("이메일과 비밀번호를 다시 확인해주세요.");
          }
          if (status === 21102) {
            setAlertText("이메일 인증을 진행해주세요.");
          }
          if (status === 21103) {
            setAlertText("잠시 후 다시 시도해주세요.");
          }
        })
    }
  }// fn handleSubmit end
  return (
    loding ?
      <Flex
        position='relative'
        h="100vh"
        overflowY='auto'
        w='100vw'
        backgroundColor='#008BFF'
      >

        <Flex
          className="login"
          justifyContent='start'
          direction='column'
          overflowY='auto'
          height='100vh'
        >

          <Flex justifyContent='center' height='18vh' mt='10vh' >
            <Image src={veta} width='80%' height='60%' />
          </Flex>
          {
            alreadyToken ?
              <>
                <AutoLogin logout={logout} userInfo={alreadyInfo} setToken={setToken} setComponent={setViewComponent} setSecondPwdCheck={setLoding} setSseConnect={setSseConnect} />
                <Button
                  position='sticky'
                  left='50%'
                  transform='translateX(-50%)'
                  top='85vh'
                  color='#008BFF'
                  mx='auto'
                  fontWeight='700'
                  onClick={() => logout()}
                >
                  로그아웃
                </Button>
              </>
              :
              <>
                {/* <Button
                  onClick={(e) => { testLogin(e) }}>
                  테스트 로그인
                </Button> */}
                <Flex
                  position='relative'
                  className="LoginImageTextMargin"
                  zIndex='1'
                  direction='column'
                  w={{ base: "100%" }}
                  background='transparent'
                  borderRadius='15px'
                  mx={{ base: "auto", lg: "unset" }}>
                  <form onSubmit={handleSubmit}>
                    <Box me='auto'>
                      <Heading color='white' mb='10px' className="loginHeadText" >
                        로그인
                      </Heading>
                      <Text
                        mb='7%'
                        ms='4px'
                        color='white'
                        className="loginHeadSubText" >
                        이메일과 비밀번호를 입력하세요.
                      </Text>
                    </Box>
                    <FormControl>
                      <Input
                        className="loginEmailInput"
                        isRequired={true}
                        borderRadius='0px'
                        variant='auth'
                        type='text'
                        width='100%'
                        placeholder='Email'
                        id="email"
                        name="email"
                        value={email}
                        onChange={onChange} />
                      <InputGroup >
                        <Input
                          className="loginPwdInput"
                          isRequired={true}
                          placeholder='Password'
                          borderRadius='0px'
                          width='100%'
                          type={show ? "text" : "password"}
                          variant='auth'
                          id="password"
                          name="password"
                          value={password}
                          onChange={onChange} />
                        <InputRightElement display='flex' className="loginPwdInputEye" alignItems='center'>
                          <Icon
                            color={textColorSecondary}
                            _hover={{ cursor: "pointer" }}
                            as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                            onClick={handleClick} />
                        </InputRightElement>
                      </InputGroup>
                      <Flex textColor='white' align='center' mb='30px'>
                        <Text mr='10px' fontWeight='700'>
                          자동 로그인
                        </Text>
                        <Button backgroundColor='#ccc' className={`switch ${autoLogin ? 'on' : 'off'}`} onClick={toggleSwitch}>
                          <div className="slider"></div>
                        </Button>
                      </Flex>
                      <Text
                        ms='4px'
                        className="loginAlertText"
                        color='red'>
                        {alertText}
                      </Text>
                      <Button
                        className="loginSignInBtn"
                        backgroundColor='rgba(70, 209, 232, 1)'
                        borderRadius='0'
                        boxShadow={shadow}
                        width='100%'
                        type="submit">
                        <Text className="loginHeadText" color='white'>
                          로그인
                        </Text>
                      </Button>
                    </FormControl>
                  </form>
                  <Flex
                    flexDirection='column'
                    justifyContent='center'>
                    <Text
                      className="loginForgetPwdText"
                      align='right'
                      color='white'
                      textDecoration='underline'
                      as='span'
                      onClick={() => setViewComponent('FindPwd')}>
                      비밀번호 찾기
                    </Text>
                  </Flex>
                </Flex>
                <Button className="loginText" position='fixed' top='90%' width='60%' left='20%' onClick={() => { setViewComponent('SignUp') }} borderRadius='5px' backgroundColor='rgba(0,0,0,0.5)' textColor='white' height='5vh' fontWeight='700'>
                  처음 사용하신다면
                </Button>
              </>
          }
        </Flex>
        <Underbar color='rgba(0,0,0,0.5)' />
      </Flex >
      :
      <Loding />
  );
}


