// React
import React from "react";
import { Flex, Box, Text, useClipboard, Button, Icon, IconButton, useColorModeValue } from "@chakra-ui/react";
// Components
import '../../../../css/main.css';
// external
import { BsArrowLeft, BsDot } from "react-icons/bs";
import { BiSolidWallet } from "react-icons/bi";
import { CopyIcon } from '@chakra-ui/icons'

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function InsertCash({ setNowComponent, userInfo }) {
    // A variable to use function variables from store.js
    const { onCopy } = useClipboard(userInfo.account_name);
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    return (
        <>
            <Icon className="backArrow" onClick={() => setNowComponent('mint')} color='black' as={BsArrowLeft} />
            <Box
                width='100%'
                height='100%'
                align='center'
                position='relative'
            >
                <Flex
                    className="mintTitleBox"
                    direction='column' align='center'>
                    <Flex >
                        <Text
                            className="mintMainText"
                        >
                            KRCD를 mint(
                        </Text>
                        <Text
                            className="mintMainSmallText"
                        >
                            충전
                        </Text>
                        <Text
                            className="mintMainText"
                        >
                            ) 해볼까요?
                        </Text>
                    </Flex>


                    <Box
                        className="mintInsertAccountBox"
                        backgroundColor='rgba(70, 209, 232, 0.2)'
                    >
                        <Flex direction='column' className="mintInsertBox">
                            <Flex justifyContent='flex-start' align='center'>
                                <Icon as={BsDot} />
                                <Text >
                                    계좌번호 :  227-910120-74607 (하나은행)
                                </Text>
                            </Flex>
                            <Flex justifyContent='flex-start' align='center'>
                                <Icon as={BsDot} />
                                <Text>
                                    예금주 : CHO BRIAN (MVP용)
                                </Text>
                            </Flex>
                        </Flex>
                    </Box>

                    <Flex direction='column' className="mintInsertBigText mintInsertTextBox">

                        <Text>
                            원화를 위 계좌로 송금하면
                        </Text>
                        <Text>
                            VETA가 MINT 됩니다.
                        </Text>
                    </Flex>
                    <Flex
                        w='80%'
                        mb='5vh'
                        className="mintInsertSmallText">
                        <Text >
                            mint 시 유의사항
                        </Text>
                    </Flex>
                    <Box
                        w='100%'
                        backgroundColor='rgba(217, 217, 217, 0.2)'
                    >

                        <Text m='2%' whiteSpace="pre-line" textColor='#008BFF' align='center' w='70%' className="mintInsertBox">
                            입금 확인을 위해 입금자명을 아래 이름으로 입력해주세요.
                        </Text>
                        <Flex>
                            <Box
                                margin='auto'
                                align='center'
                                width='60%'
                                maxW='70vw'
                                height='80%'
                                borderRadius='3'
                                mb='5%'
                                backgroundColor='#EDF2FA'>
                                <Flex color='#245EC6' justifyContent='space-between' align='center'>
                                    <Icon
                                        m='3%'
                                        w='12%'
                                        h='12%'
                                        as={BiSolidWallet} />
                                    <Flex align='center' w='60%'>
                                        {
                                            userInfo.account_name ?
                                                <Text w='80%' className="mintInsertBigText" my='auto' border='2px solid' borderColor='red.400'>
                                                    {userInfo.account_name}
                                                </Text>
                                                :
                                                null
                                        }
                                    </Flex>
                                    <IconButton aria-label='Copy' boxShadow={shadow} color='#AAB4C8' icon={<CopyIcon />} size='xs' colorScheme='whiteAlpha' m='5%' borderRadius='5' onClick={onCopy} />
                                </Flex>
                            </Box>
                        </Flex>
                    </Box>
                </Flex>
                <Button
                    className="buttomBtnText underbarButton"
                    position='fixed'
                    top='95vh'
                    left='0'
                    backgroundColor='#46D1E8'
                    height='5vh'
                    borderRadius='0px'
                    onClick={() => { setNowComponent('') }}
                >
                    <Text
                        color='white'
                    >
                        완료
                    </Text>
                </Button>
            </Box >
        </>

    );
}
