// React
import React from "react";
import { Flex, Box, Text, Button } from "@chakra-ui/react";
// Components
import '../../../../css/main.css';

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function AccountFinish({ setAccountComponent, userInfo }) {


    return (
        <>
            <Box
                width='100vw'
                height='100vh'
                align='center'
                top='0'
                position='absolute'
                mx='auto'
                textAlign='left'
                backgroundColor='white'
                zIndex='5000'
            >



                <Box width='80%' align='center'
                    mx='auto'
                    mt='26vh'>
                    <Text
                       className="accountUpdateFinishBigText"
                    >
                        계좌 등록 완료
                    </Text>

                </Box>

                <Flex direction='column' align='center'>

                    <Box
                        className="assetBankCard"
                        mt='5vh'
                        textColor='white'
                    >
                        <Text>
                            어디은행
                        </Text>
                        <Text>
                            010-1414-1414
                        </Text>
                    </Box>

                    <Text
                        mt='30px'
                       className="accountUpdateFinishSmallText"
                    >
                        정상적으로 등록이 완료 되었습니다.
                    </Text>
                </Flex>



                <Button _hover={{ backgroundColor: '#46D1E8' }} className='buttomBtnText underbarButton' position='fixed' zIndex='5' bottom='0' height='5vh' align='center' alignSelf='center' backgroundColor='#46D1E8' borderRadius='0' onClick={() => setAccountComponent('')}>
                    <Text color='white'>
                        확인
                    </Text>
                </Button>
            </Box >
        </>

    );
}
