// React
import React, { useState, useEffect, useRef } from "react";
// External Libraries
import axios from 'axios';
import { Box, Button, Flex, Heading, Text, Image } from "@chakra-ui/react";
// Components
import StepOneUser from "../../components/signUp/StepOneUser";
import veta from "../../assets/img/service/veta_banana.png";
import Underbar from "../../components/underbar/Underbar";
import StepTwoUser from "../../components/signUp/StepTwoUser";
import CryptoJS from 'crypto-js';
/**
 * 
 * Props list - none
 */
export default function SignUp({ setViewComponent }) {
  function hashVisitorId(visitorId) {
    return CryptoJS.SHA256(visitorId).toString(CryptoJS.enc.Hex);
  }
  const [signComponent, setSignComponent] = useState('');
  const regexName = /^[A-Za-z가-힣]*$/;

  // 결제 비밀번호 관련
  const [secondCheck, setSecondCheck] = useState(false);
  const inputRefs = useRef([]);
  const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);
  const inputRefsecond = useRef([]);
  const [inputValueSecond, setInputValueSecond] = useState(['', '', '', '', '', '']);


  // input data variables
  const [inputs, setInputs] = useState({
    email: "",
    name: "",
    password: "",
    passwordConfirm: "",
    emailVerify: ""
  });
  const { email, name, password, passwordConfirm, emailVerify } = inputs;

  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    if (e.target.name === 'email') {
      setEmailButtonText("중복\n확인");
      setEmailCheck(false);
      setInputs({
        emailVerify: "",
      });
    }
    const { value, name } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  }

  // Check input data 
  const isValidPassword = password.length >= 8
  const isValidEmail = email && email.includes('@') && email.includes('.');
  const isValidName = regexName.test(name);
  // view chage variables for input component
  const [submitLoading, setSubmitLoading] = useState(true);
  const [emailCheck, setEmailCheck] = useState(false);
  const [emailButtonText, setEmailButtonText] = useState("중복\n확인");
  const [emailCode, setEmailCode] = useState("");
  const [alertText, setAlertText] = useState("");
  const [alertAni, setAlertAni] = useState('');
  // 1차 비밀번호 입력유무 
  useEffect(() => {
    if (inputValues[0] !== '' && inputValues[1] !== '' &&
      inputValues[2] !== '' && inputValues[3] !== '' &&
      inputValues[4] !== '' && inputValues[5] !== '') {
      inputRefs.current[0].focus();
      setSecondCheck(true);
    } else if (inputValues[5] !== '') {
      for (var i = 0; i < 6; i++) {
        if (inputValues[i] === '' || inputValues[i].length !== 1) {
          setInputValues(['', '', '', '', '', '']);
          inputRefs.current[0].focus();
          setAlertAni('alertText');
        }
      }
    }

  }, [inputValues])

  useEffect(() => {
    if ((inputValueSecond[0] !== '' && inputValueSecond[1] !== '' &&
      inputValueSecond[2] !== '' && inputValueSecond[3] !== '' &&
      inputValueSecond[4] !== '' && inputValueSecond[5] !== '')
    ) {
      if (
        inputValues[0] === inputValueSecond[0] &&
        inputValues[1] === inputValueSecond[1] &&
        inputValues[2] === inputValueSecond[2] &&
        inputValues[3] === inputValueSecond[3] &&
        inputValues[4] === inputValueSecond[4] &&
        inputValues[5] === inputValueSecond[5]) {
        reg();
      } else {
        setAlertText('올바른 비밀번호를 입력해주세요.');
        setAlertAni('alertText');
        setInputValueSecond(['', '', '', '', '', '']);
        inputRefsecond.current[0].focus();
      }
    } else if (inputValueSecond[5] !== '') {
      for (var i = 0; i < 6; i++) {
        if (inputValueSecond[i] === '' || inputValueSecond[i].length !== 1) {
          setInputValueSecond(['', '', '', '', '', '']);
          inputRefsecond.current[0].focus();
          setAlertText('다시 시도해주세요.');
          setAlertAni('alertText');
        }
      }
    }
  }, [inputValueSecond])
  function emailInputCheck() {
    const alertTextId = document.getElementById('alertId');
    if (email.includes("@uws.co.kr")) {
      alertTextId.classList.toggle('alertText');
      setAlertText("경영지원팀으로 요청 바랍니다.");
      return;
    }
    if (isValidEmail) {
      delete axios.defaults.headers.common['Authorization'];
      axios.post('/api/sign/duplication/email', {
        email: email
      },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }).then((response) => {
          if (response.data !== null) {
            setEmailCode(response.data);
            setEmailButtonText('완료');
          }
        }).catch(error => {
          setEmailButtonText("이메일\n오류");
        })
    } else {
      setEmailButtonText("이메일\n오류");
    }
  }

  // async api call
  async function handleSubmit(e) {
    e.preventDefault();
    const alertTextId = document.getElementById('alertId');
    if (email.includes("@uws.co.kr")) {
      alertTextId.classList.toggle('alertText');
      setAlertText("경영지원팀으로 요청 바랍니다.");
      return;
    }
    if (emailCode === "" || String(emailCode) !== emailVerify) {
      alertTextId.classList.toggle('alertText');
      setAlertText("인증번호를 확인하세요.");
      return;
    }
    if ((password !== '' && isValidPassword && passwordConfirm !== '' && password === passwordConfirm)) {
    } else if (!email) {
      alertTextId.classList.toggle('alertText');
      setAlertText("이메일을 확인해주세요");
      return;
    } else if (!emailVerify) {
      alertTextId.classList.toggle('alertText');
      setAlertText("본인확인 확인해주세요");
      return;
    }
    else if (!isValidName) {
      alertTextId.classList.toggle('alertText');
      setAlertText("올바른 이름을 입력해주세요");
      return;
    }
    else if (!password || !passwordConfirm || password !== passwordConfirm) {
      alertTextId.classList.toggle('alertText');
      setAlertText("비밀번호를 확인해주세요");
      return;
    }
    if (signComponent !== 'finish') {
      setAlertText();
      setSignComponent('second');
    }
  }
  function reg() {
    const combinedString = inputValues.join('');
    //server api call
    axios.post('/api/sign/reg', {
      email: email,
      name: name,
      pwd: hashVisitorId(password),
      second_pwd: hashVisitorId(combinedString),
    }).then((response) => {                       // api return
      setSignComponent('finish');
      setSubmitLoading(true);
    })
      .catch(error => {                           // api call failed
        const { status } = error.response.data;
        if (status === 21001) {
          setAlertText("입력한 정보를 다시 확인하세요.");
        } else if (status === 21203 || status === 21204 || status === 21202 || status === 21002) {
          setAlertText("다시 시도해주세요.");
        }
        setSubmitLoading(true);
      })
  }


  //first
  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = value;

    // 입력이 있으면 다음 input으로 포커스 이동
    if (value && index < inputRefs.current.length - 1) {
      setTimeout(() => {
        inputRefs.current[index + 1].focus();
      }, 0);
    } else if (value && index === inputRefs.current.length - 1) {
      if (secondCheck) {
        setTimeout(() => {
          inputRefsecond.current[0].focus();
        }, 0);
      } else {
        setTimeout(() => {
          inputRefs.current[0].focus();
        }, 0);
      }
    }
    setInputValues(newInputValues);
  };

  //second
  const secondInputChange = (index, value) => {
    setAlertText('');
    setAlertAni('');
    const newInputValues = [...inputValueSecond];
    newInputValues[index] = value;

    // 입력이 있으면 다음 input으로 포커스 이동
    if (value && index < inputRefsecond.current.length - 1) {
      setTimeout(() => {
        inputRefsecond.current[index + 1].focus();
      }, 0);
    } else if (value && index === inputRefsecond.current.length - 1) {
      setTimeout(() => {
        inputRefsecond.current[0].focus();
      }, 0);
    }

    setInputValueSecond(newInputValues);
  };

  function clearFirstDigit(index) {
    let passwordValues = [...inputValues];
    passwordValues[index] = '';
    setInputValues(passwordValues);
    inputRefs.current[index].focus();
  }

  function clearSecondDigit(index) {
    let passwordValues = [...inputValueSecond];
    passwordValues[index] = '';
    setInputValueSecond(passwordValues);
    inputRefsecond.current[index].focus();
  }
  const handleKeyUpFirst = (index, event) => {
    if (index !== 0 && event.keyCode === 8) { // Move focus to previous on backspace
      var passwordValues = [...inputValues];
      passwordValues[--index] = '';
      setInputValues(passwordValues);
      inputRefs.current[index].focus();
    }
  };

  const handleKeyUpSecond = (index, event) => {
    if (index !== 0 && event.keyCode === 8) { // Move focus to previous on backspace
      var passwordValues = [...inputValueSecond];
      passwordValues[--index] = '';
      setInputValueSecond(passwordValues);
      inputRefsecond.current[index].focus();
    }
  };



  return (
    <Flex
      position='relative'
      h="100vh"
      w='100vw'
      backgroundColor='#008BFF'>
      <Flex
        className="login"
        justifyContent='start'
        direction='column'
      >
        <Flex justifyContent='center' height='18vh' mt='12vh' >
          <Image src={veta} width='80%' height='60%' />
        </Flex>
        <Flex
          width='100%'
          height='55%'
          justifyContent='center'
          flexDirection='column'>
          <Box my='auto'>
            {
              signComponent === 'second' ?
                <Heading color='white' className="signUpHeadBigText" mb='10px'>
                  2차 비밀번호 입력
                </Heading>
                :
                signComponent === 'finish' ?

                  <Text align='center' color='white' className="signUpHeadBigText" mb='10px'>
                    가입이 완료 되었습니다.
                  </Text>

                  :
                  <>
                    <Heading color='white' className="signUpHeadBigText" mb='10px'>
                      회원가입
                    </Heading>
                    <Text
                      mb='10px'
                      ms='4px'
                      color='white'
                      className="signUpHeadSmallText">
                      이메일과 이름, 비밀번호를 입력하세요.
                    </Text>
                  </>
            }
            <div style={{ overflow: 'auto', width: '100%' }}>
              {
                signComponent === 'second' ?
                  <StepTwoUser setComponent={signComponent}
                    inputValueSecond={inputValueSecond} secondCheck={secondCheck}
                    secondInputChange={secondInputChange} inputRefsecond={inputRefsecond}
                    alertAni={alertAni} alertText={alertText} inputValues={inputValues}
                    handleInputChange={handleInputChange} inputRefs={inputRefs}
                    clearFirstDigit={clearFirstDigit} clearSecondDigit={clearSecondDigit}
                    handleKeyUpFirst={handleKeyUpFirst} handleKeyUpSecond={handleKeyUpSecond}
                  />
                  :
                  signComponent === 'finish' ?
                    <Button mt='70%' _hover={{ backgroundColor: 'rgba(70, 209, 232, 1)' }} className='signUpBtn' backgroundColor='rgba(70, 209, 232, 1)' borderRadius='5px' width='100%' onClick={() => { setViewComponent('Login') }}>
                      <Text color='white' ml='4px'> 완료 </Text>
                    </Button>
                    :
                    <>
                      <StepOneUser onChange={onChange} email={email} password={password} passwordConfirm={passwordConfirm} emailCheck={emailCheck} emailButtonText={emailButtonText} name={name} emailInputCheck={emailInputCheck} emailVerify={emailVerify} emailCode={emailCode} />
                      <Text mt='46px' id='alertId' mb='0px' color='red' className="loginAlertText">
                        {alertText}
                      </Text>
                      <Flex mb='5%' align='center'>
                        {
                          password !== '' && isValidPassword && passwordConfirm !== '' && password === passwordConfirm
                            ?
                            submitLoading
                              ?
                              <Button className='signUpBtn' borderRadius='5px' backgroundColor='rgba(70, 209, 232, 1)' width='100%' onClick={handleSubmit}>
                                <Text ml='4px' color='white'>회원가입</Text>
                              </Button>
                              :
                              <Button className='signUpBtn' backgroundColor='rgba(70, 209, 232, 1)' borderRadius='5px' width='100%' variant='outline' isLoading loadingText='진행중..' />
                            :
                            <Button _hover={{ backgroundColor: 'rgba(70, 209, 232, 1)' }} className='signUpBtn' backgroundColor='rgba(70, 209, 232, 1)' borderRadius='5px' width='100%' onClick={handleSubmit}>
                              <Text color='white' ml='4px'> 다음 &gt; </Text>
                            </Button>
                        }
                      </Flex>
                    </>
              }
              {
                signComponent === 'finish' ?
                  null
                  :
                  < Flex
                    position='fixed'
                    top='90%'
                    left='10%'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='start'
                  >
                    <Text className="signUpBottomText" color='white' >
                      계정이 있으신가요?
                      <Text
                        color='white'
                        textDecoration='underline'
                        as='span'
                        ms='5px'
                        fontWeight='400'
                        onClick={() => setViewComponent('Login')}>
                        로그인
                      </Text>
                    </Text>
                  </Flex>

              }
            </div>
          </Box>
        </Flex>
        <Underbar color='rgba(0, 0, 0, 0.5)' />
      </Flex>
    </Flex >
  );
}