// React
import React, { useEffect, useState } from "react";
import { Flex, Box, Text, Image, Icon } from "@chakra-ui/react";
// Components
import Card from "../../../../components/card/Card.js";
// Assets
import gradient3 from "../../../../assets/img/service/gradient3.jpeg";
import eternalsCatBlack from "../../../../assets/img/service/eternalsCatBlack.png";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { useSwipeable } from 'react-swipeable';
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function ItemCard({ setProductModalOpen, setProductInfo, storeInfo, setStoreComponent, setTotalDiscountAmount, setTotalDiscountPercent, totalDiscountAmount, totalDiscountPercent, setDiscountMessage, categoryList, setCategoryList, bigCategoryList, setBigCategoryList, handleAddToCart, itemList, setItemList }) {




    const [selectedBigCategory, setSelectedBigCategory] = useState();
    // const [columnsByBigCategory, setColumnsByBigCategory] = useState([]);

    useEffect(() => {
        if (bigCategoryList.length > 0) {
            handleBigCategoryClick(bigCategoryList[0].category_big_id);
        }
    }, [bigCategoryList])

    // 화면 슬라이드

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('left'),
        onSwipedRight: () => handleSwipe('right'),
    }, []);

    const handleSwipe = (direction) => {
        if (bigCategoryList.length > 1) {
            let newIndex;
            var count = 0;
            if (direction === 'left') {
                bigCategoryList.forEach((e) => {
                    count += 1;
                    if (e.category_big_id === selectedBigCategory) {
                        newIndex = count;
                    }
                })
            } else if (direction === 'right') {
                bigCategoryList.forEach((e) => {
                    count += 1;
                    if (e.category_big_id === selectedBigCategory) {
                        newIndex = count;
                    }
                })
            }
            if (direction === 'left' && newIndex === bigCategoryList.length) {
                setSelectedBigCategory(bigCategoryList[0].category_big_id);               
            } else if (direction === 'left' && newIndex !== bigCategoryList.length) {
                setSelectedBigCategory(bigCategoryList[newIndex].category_big_id);               
            } else if (direction === 'right' && newIndex === 1) {
                setSelectedBigCategory(bigCategoryList[bigCategoryList.length - 1].category_big_id);               
            } else if (direction === 'right' && newIndex !== 1) {
                setSelectedBigCategory(bigCategoryList[newIndex - 2].category_big_id);               
            }

        }
    };

    const handleBigCategoryClick = (bigCategoryId) => {
        setSelectedBigCategory(bigCategoryId);
    };


    return (
        <>
            <Flex
                width='98%'
                {...handlers}
                p='10px'
                mx='auto'
                direction='column'
            >
                <Icon as={BsChevronCompactLeft} position='fixed' left='0' top='50%' color='rgba(0,0,0,0.5)' />
                <Icon as={BsChevronCompactRight} position='fixed' left='96vw' top='50%' color='rgba(0,0,0,0.5)' />
                <Icon className="backArrowOrder"
                    onClick={() => setStoreComponent('')}
                    as={BsArrowLeft}
                />
                <Flex width='100%' wrap='wrap' mb='1vh' gap='10px' justify='space-evenly'>
                    {itemList && bigCategoryList && bigCategoryList.map((category, index) => (
                        <>
                            {
                                category.category_big_id === selectedBigCategory ?
                                    <Box
                                        align='center'
                                        backgroundColor='rgba(70, 209, 232, 1)'
                                        className="bigCategory" key={index} onClick={() => handleBigCategoryClick(category.category_big_id)} >
                                        <Box
                                            width='25vw'
                                            textColor='white'
                                            direction='column'
                                            whiteSpace='nowrap'
                                            overflow='hidden'
                                            textOverflow='ellipsis'
                                        >
                                            {category.category_big_name}
                                        </Box>
                                    </Box>
                                    :
                                    <Box
                                        align='center'
                                        width='25vw'
                                        backgroundImage={gradient3}
                                        backgroundRepeat='no-repeat'
                                        bgSize='cover'
                                        className="bigCategory" key={index} onClick={() => handleBigCategoryClick(category.category_big_id)} >
                                        <Box
                                            textColor='black'
                                            direction='column'
                                            width='25vw'
                                            whiteSpace='nowrap'
                                            overflow='hidden'
                                            textOverflow='ellipsis'
                                        >
                                            {category.category_big_name}
                                        </Box>
                                    </Box>
                            }
                        </>
                    ))}
                </Flex>
                <Card
                    height='62.6vh'
                    maxW='95vw'
                    overflowY='auto'
                    overflowX='hidden'
                    backgroundColor='rgba(70, 209, 232, 0.5)'
                    mx='auto'
                    width='100%'
                    p='10px'
                >

                    <Box className='table-box' direction='row' h='100%' w='100%' justifyContent='space-around' overflowX='hidden' overflowY='auto'>
                        <Flex wrap='wrap' gap='20px' justify='space-evenly'>

                            {itemList && // 해당 카테고리의 열이 있는지 확인
                                itemList
                                    .filter(e => selectedBigCategory === e.category_big_id)
                                    .map((item, itemIndex) => (
                                        <Flex key={itemIndex}>{
                                            <>
                                                <Card
                                                    className='itemText'
                                                    _active={{
                                                        p: '2% 0% 2% 0%'
                                                    }}
                                                    _hover={{
                                                        p: '2% 0% 2% 0%'
                                                    }}
                                                    w='26vw' maxW='30vw'
                                                    borderRadius='5'
                                                    m='2%'
                                                    p='2%'
                                                    key={itemIndex}
                                                    onClick={() => {
                                                        if (storeInfo.open_status === '1' && item.stock !== '0') {
                                                            setProductModalOpen(true);
                                                            setProductInfo(item);
                                                        }
                                                        if (item.stock === '0') {
                                                            alert("해당 상품은 품절입니다.");
                                                        }
                                                    }}
                                                    zIndex='10'
                                                >

                                                    <Flex direction='column' alignItems='center' height='100%'>
                                                        <Flex >
                                                            {item.image ?
                                                                <Image style={{ height: '100%' }} src={`data:image/*;base64,${item.image}`} className="storeItemImage" />
                                                                :
                                                                <Image src={eternalsCatBlack} alt='' style={{ height: '100%' }} className="storeItemImage" />
                                                            }
                                                        </Flex>
                                                        <Flex width="100%" justifyContent="center" >
                                                            <Text
                                                                fontSize='15px'
                                                                // whiteSpace='nowrap'
                                                                overflow='hidden'
                                                            // textOverflow='ellipsis'
                                                            // isTruncated
                                                            >
                                                                {item.name}
                                                            </Text>
                                                        </Flex>
                                                        <Flex width="100%" justify='space-around' fontSize='13px' mt='auto'>
                                                            {
                                                                totalDiscountAmount || totalDiscountPercent ?
                                                                    <>
                                                                        <Text
                                                                            whiteSpace='nowrap'
                                                                            overflow='hidden'
                                                                            textOverflow='ellipsis'
                                                                            color='red'
                                                                            textDecoration='line-through'
                                                                        >
                                                                            {(item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                                                        </Text>
                                                                        {
                                                                            totalDiscountAmount ?
                                                                                <Text
                                                                                    whiteSpace='nowrap'
                                                                                    overflow='hidden'
                                                                                    textOverflow='ellipsis'
                                                                                >
                                                                                    {(Number(item.price) - Number(totalDiscountAmount)) > 0 ? (Number(item.price) - Number(totalDiscountAmount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                                                                </Text>

                                                                                :
                                                                                totalDiscountPercent ?
                                                                                    <Text
                                                                                        whiteSpace='nowrap'
                                                                                        overflow='hidden'
                                                                                        textOverflow='ellipsis'
                                                                                    >
                                                                                        {(Number(item.price) * (100 - Number(totalDiscountPercent)) / 100) > 0 ? (parseInt((Number(item.price) * (100 - Number(totalDiscountPercent)) / 100))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                                                                    </Text>
                                                                                    :
                                                                                    <Text
                                                                                        whiteSpace='nowrap'
                                                                                        overflow='hidden'
                                                                                        textOverflow='ellipsis'
                                                                                    >
                                                                                        {(item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                                                                    </Text>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <Text
                                                                        whiteSpace='nowrap'
                                                                        overflow='hidden'
                                                                        textOverflow='ellipsis'
                                                                    >
                                                                        {(item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}

                                                                    </Text>
                                                            }
                                                        </Flex>
                                                    </Flex>
                                                </Card>
                                            </>
                                        }</Flex>
                                    ))}
                        </Flex>
                    </Box>
                </Card >
            </Flex >
        </>

    );
}
