// React
import React, { useState, useEffect } from 'react';
// External Libraries
import { Flex, Icon, Text, useColorModeValue, Modal, Button, ModalContent } from "@chakra-ui/react";
import '../../../../../css/Page.css';
import { BsXLg } from "react-icons/bs";
import Card from '../../../../../components/card/Card';
// Components
// Assets
/**
 * 
 * Props list
 *  - titleText           : 모달버튼 상단 텍스트
 */
export default function OrderModal({ onClose, orderInfo, orderDetailList, deleteOrder,  discountList, orderProductOptionList }) {
  // Variables for Card CSS Styling
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );


  return (
    <div className='modal'>
      <Modal isOpen={true} onClose={onClose} isCloseOnOverlayClick={true}>
        <ModalContent
          my='auto'
          className='orderModal'
          border='1px solid'
          borderColor='white'
          backgroundColor='rgba(229, 244, 255, 1)'
          textColor='black'
        >
          <Flex position='absolute' top='1vh' left='90%'>
            <Icon _hover={bgHover} _focus={bgFocus} _active={bgFocus} className='modalExitBtn' onClick={onClose} color='rgba(130, 126, 126, 1)' as={BsXLg} />
          </Flex>

          <Flex
            className='modalMainCloseText'
            my='10%'
            align='center'
            direction='column'
          >
            <Text
            >
              [ 주문 상세 정보 ]
            </Text>
          </Flex>

          <Flex
            className='modalMainInsideDefaultText'
            justifyContent='space-between'
          >
            <Flex
              direction='column'
              width='100%'
              align='center'
            >
              <Flex width='80%' justify='space-evenly'>
                <Text mr='auto'>
                  주문 번호
                </Text>
                <Text>
                  {orderInfo.order_number}
                </Text>
              </Flex>
              <Flex width='80%' justify='space-evenly'>
                <Text mr='auto'>
                  총 금액
                </Text>
                <Text>
                  {(orderInfo.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원
                </Text>
              </Flex>
              <Flex width='80%' justify='space-evenly'>
                <Text mr='auto'>
                  주문시간
                </Text>
                <Text>
                  {(orderInfo.order_reg).substring(0, 16)}
                </Text>
              </Flex>
              <Flex width='80%' justify='space-evenly'>
                <Text mr='auto'>
                  주문결과
                </Text>
                <Text>
                  {orderInfo.order_status === 'order' ?
                    "주문 접수"
                    :
                    orderInfo.order_status === 'ongoing' ?
                      "상품 제작중"
                      :
                      orderInfo.order_status === 'complete' ?
                        "픽업 완료"
                        :
                        orderInfo.order_status === 'reject' ?
                          "주문 취소"
                          :
                          "완료"
                  }
                </Text>
              </Flex>
              <Flex width='80%' justify='space-evenly'>
                <Text>
                  상품항목
                </Text>
              </Flex>
              <Flex width='80%' justify='space-between' borderBottom='1px solid gray'>
                <Text>
                  상품명
                </Text>
                <Text>
                  수량
                </Text>
                <Text>
                  금액
                </Text>
              </Flex>


              <>
                {
                  orderDetailList.map((order, index) => (
                    <Card width='90%' key={index}>
                      <Flex direction='column'>
                        <Flex justify='space-between'>
                          <Text>
                            {order.product_name}
                          </Text>
                          <Text>
                            {order.product_quantity}
                          </Text>
                          <Text >
                            {(order.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원
                          </Text>
                        </Flex>
                        {
                          orderProductOptionList && orderProductOptionList.length > 0 ?
                            orderProductOptionList
                              .filter((e) => e.product_number === order.product_number)
                              .map((option, index) => (
                                < Flex justify='space-between' key={index}>
                                  <Text>

                                    {option.option_name}

                                  </Text>
                                  <Text>
                                    + {(option.additional_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 원
                                  </Text>
                                </Flex>
                              ))
                            :
                            null
                        }
                        {
                          orderInfo.order_comment ?
                            discountList.filter((e) => e.discount_number === orderInfo.order_comment)
                              .map((discount, index) => (
                                < Flex justify='space-between' key={index}>
                                  <Text>

                                    {discount.comment}

                                  </Text>
                                  <Text>
                                    {
                                      discount.discount_amount > 0 ?
                                        "- " + (discount.discount_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원"
                                        :
                                        discount.discount_percent + "%"
                                    }
                                  </Text>
                                </Flex>
                              ))
                            :
                            null
                        }



                      </Flex>
                    </Card>
                  ))
                }
              </>
            </Flex>


          </Flex>

          <Flex
            mt='auto'
            justify='space-around'
          >
            <Button
              className='modalMainSmallBtn'
              _focus={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _active={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              _hover={{ backgroundColor: 'rgba(22, 204, 234, 1)' }}
              shadow={shadow}
              backgroundColor='rgba(22, 204, 234, 1)'
              border='1px solid rgba(217, 217, 217, 1)'
              textColor='white'
              align='center'
              onClick={() => { onClose() }}
            >
              < Text
                className='modalMainSmallBtnText'
                mx='auto'
              >
                확인
              </Text>
            </Button>
            <Button
              className='modalMainSmallBtn'
              _focus={{ backgroundColor: 'rgba(233, 51, 21, 1)' }}
              _active={{ backgroundColor: 'rgba(233, 51, 21, 1)' }}
              _hover={{ backgroundColor: 'rgba(233, 51, 21, 1)' }}
              shadow={shadow}
              backgroundColor='rgba(233, 51, 21, 1)'
              border='1px solid rgba(233, 51, 21, 1)'
              textColor='white'
              align='center'
              onClick={() => { deleteOrder() }}
            >
              < Text
                className='modalMainSmallBtnText'
                mx='auto'
              >
                삭제
              </Text>
            </Button>
          </Flex>

        </ModalContent>
      </Modal >
    </div >
  );
}