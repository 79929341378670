export const columnsDataTransaction = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Particulars",
    accessor: "name",
  },
  {
    Header: "Send/Receive",
    accessor: "amount",
  }
];
