// React
import React from "react";
// Components
import SecondPwdSetting from "../../confirm/SecondPwdSetting";

export default function SecPassword({ userInfo, setAssetComponent, setUserUpdate }) {
    return (
        <>

            <SecondPwdSetting userInfo={userInfo} setSecondPwdCheck={setUserUpdate} setNowComponent={setAssetComponent} />

            
        </>
    );
}