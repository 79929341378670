// React
import React, { useMemo } from "react";
// Components
// Assets
import SeatTableContent from "./SeatTableContent.js";
import { columnsDataSeat } from "../../../../../variables/seat/columnsDataSeat";
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function SeatTable({ SeatList, setSearchModalOpen, setSearchUserInfo }) {
    const columns = useMemo(() => columnsDataSeat, [columnsDataSeat]);
    const tableData = useMemo(() => SeatList, [SeatList]);
    return (
        <>
            <SeatTableContent columns={columns} dFlag={3} data={tableData}            
                setSearchModalOpen={setSearchModalOpen} setSearchUserInfo={setSearchUserInfo}
            />
        </>
    );
}
