import React from 'react';
import { Flex, Box, Text, Button, Image } from "@chakra-ui/react";
//component
import paymentOk from "../../../../assets/img/service/paymentOk.gif";
export default function TransferFinish({ amount, setNowComponent }) {

  return (
    <>


      <Box align='center' className='transferFinishBox'>
        <Image w='310px' h='250px' src={paymentOk} />
      </Box>
      <Flex align='center' direction='column' fontWeight='700' textColor='#004A56'>
        <Flex>
          <Text className='transferFinishBigText'>
            {amount}
          </Text>
          <Text ml ='10px'mt='auto' className='transferFinishSmallText' >
            VETA
          </Text>
        </Flex>
        <Text className='transferFinishMiddleText'>
          송금 완료 되었습니다.
        </Text>
      </Flex>
      <Button position='fixed' zIndex='5' bottom='0' height='15vh' width='100%' align='center' alignSelf='center' backgroundColor='#46D1E8' borderRadius='0' onClick={() => setNowComponent('')}>
        <Text color='white'>
          완료
        </Text>
      </Button>
    </>
  );
}