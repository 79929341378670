// React
import React from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import { Flex, Box, Text, useColorModeValue, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
// Assets
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function TransactionTableContent({ columns, data, cFlag, dFlag, userInfo, setTxComponent, setTxDetail }) {
    // Variables for CSS Styling
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

    const {
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        { columns, data },
        useGlobalFilter,
        useSortBy
    );
    var noDataText = (dFlag === 0) ? 'No Data Available' : '';
    var noDataMargin = (dFlag === 0) ? '70px' : '0px';

    return (

        <Box
            borderRadius='0'
            w='100%'
            overflowY="auto"
            className='screen-box transactionTable'>
            <Table whiteSpace='nowrap' position='relative' >
                <Thead position='sticky' top='0' backgroundColor='white' zIndex='1' height='20%' align='center' >
                    {headerGroups.map((headerGroup, index) => (
                        <Tr key={index}>
                            {headerGroup.headers.map((column, index) => (
                                // Exclude the column with the name "cFlag"
                                column.render("Header") === 'Date'
                                    ? <Th
                                        p='2%'
                                        key={index}
                                        borderColor={borderColor}>
                                        <Text
                                            className="tableHead"
                                            fontWeight='400'
                                            align='left'
                                            color={textColor}>
                                            {column.render("Header")}
                                        </Text>
                                    </Th>
                                    :
                                    column.render("Header") === 'Particulars'
                                        ? <Th
                                            p='2%'
                                            key={index}
                                            borderColor={borderColor}>
                                            <Text
                                                className="tableHead"
                                                fontWeight='400'
                                                align='left'
                                                color={textColor}>
                                                {column.render("Header")}
                                            </Text>
                                        </Th>
                                        :
                                        <Th
                                            p='2%'
                                            key={index}
                                            borderColor={borderColor}>
                                            <Text
                                                className="tableHead"
                                                fontWeight='400'
                                                align='right'
                                                color={textColor}>
                                                {column.render("Header")}
                                            </Text>
                                        </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()} height='80%'>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Tr fontSize='14px' fontWeight='400' p='1px' {...row.getRowProps()} key={index} borderBottom='2px solid' borderColor='gray.200'
                                onClick={
                                    () => {
                                        setTxComponent('detail');
                                        setTxDetail(row.original);
                                    }
                                }
                            >
                                {row.cells.splice(0, dFlag).map((cell, index) => {

                                    // Variable for each <Td> in ItemTable
                                    let data = "";

                                    if (cell.column.Header === "Date") {

                                        data = (
                                            <Text color={textColor} mb='10px' pl='2%' align='left'>
                                                {(cell.value).substring(0, 10)}
                                            </Text>
                                        );

                                    } else if (cell.column.Header === "Particulars") {
                                        data = (
                                            <Text color={textColor} align='left' mb='10px'>
                                                {row.original.source_account_address === userInfo.address ?
                                                    row.original.target_name
                                                    :
                                                    row.original.source_name
                                                }
                                            </Text>
                                        );
                                    } else if (cell.column.Header === "Send/Receive") {
                                        data = (
                                            <Flex direction='column' pr='2%' >
                                                <Text color={
                                                    row.original.source_account_address === userInfo.address ?
                                                        "red.500"
                                                        :
                                                        "black"
                                                } align='right'>
                                                    {row.original.source_account_address === userInfo.address ?
                                                        '-' + cell.value
                                                        :
                                                        cell.value}
                                                </Text>

                                                <Text color={textColor} fontSize='10px' align='right'>
                                                    {
                                                        userInfo.address === row.original.source_account_address && row.original.source_result_amount?
                                                            (row.original.source_result_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                                            :
                                                            row.original.target_result_amount ?
                                                                (row.original.target_result_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                                                :
                                                                0
                                                    }
                                                </Text>
                                            </Flex>
                                        );
                                    }
                                    return (
                                        <Td
                                            p='2%'
                                            {...cell.getCellProps()}
                                            key={index}
                                            className="tableBody"
                                            maxH='30px !important'
                                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                            borderColor='transparent'>
                                            {data}
                                        </Td>
                                    );
                                })}
                            </Tr>
                        )
                    }
                    )}
                </Tbody>
            </Table>
            <Text color={textColor} fontSize='xl' fontWeight='500' align='center' justify='center' my={noDataMargin}>
                {noDataText}
            </Text>
        </Box>
    );
}
