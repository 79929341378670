// React
import React, { useState } from "react";
// External Libraries
import axios from 'axios';
import { Box, Button, Flex, FormControl, FormLabel, Heading, Icon, Input, InputGroup, InputRightElement, Text, useColorModeValue, } from "@chakra-ui/react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
// Assets
import Underbar from "../../components/underbar/Underbar"
// 암호화 js 
import CryptoJS from 'crypto-js';
/**
 * 
 * Props list - none
 */
export default function FindPwd({ setViewComponent }) {
  function hashVisitorId(visitorId) {
    return CryptoJS.SHA256(visitorId).toString(CryptoJS.enc.Hex);
}

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  // style variables
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  // input data variables
  const [inputs, setInputs] = useState({
    email: "",
    inputVerityCode: "",
    newPassword: "",
  });
  const { email, inputVerityCode, newPassword } = inputs;

  // When the user enters data, the value is changed to the entered value.      
  function onChange(e) {
    const { value, name } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  }

  // Check input data 
  const isValidEmail = email.includes('@') && email.includes('.');


  // view chage variables for input component
  const [submitLoading, setSubmitLoading] = useState(true);

  const [verityCode, setVerityCode] = useState("");

  // async api call
  async function handleSubmit(e) {

    e.preventDefault();
    // input data Check 
    if (!isValidEmail) {
      alert("Check Your Email");
    } else {

      // input style change
      setSubmitLoading(false);

      //server api call
      axios.post('/api/sign/find/pwd', {
        email: email
      }).then((response) => {
        if (response.data != null) {
          setVerityCode(response.data.toString());
          setSubmitLoading(true);
        }
      })
        .catch(error => {                           // api call failed
          alert("입력한 정보를 다시 확인하세요.");
          setSubmitLoading(true);
        })
    }// variable Check if else end
  } // fn handleSubmit end
  async function modifyHandleSubmit(e) {

    e.preventDefault();
    // input data Check 
    if (!isValidEmail) {
      alert("Check Your Email");
    } else {

      // input style change
      setSubmitLoading(false);

      //server api call
      axios.post('/api/sign/update/pwd', {
        email: email,
        pwd: hashVisitorId(newPassword)
      }).then((response) => {
        setSubmitLoading(true);

        alert("입력하신 패스워드로 변경이 완료되었습니다.\n" +
          "\n" +
          "새로운 비밀번호로 로그인을 시도해주세요."
        );
        setSubmitLoading(true);

        window.location.reload();// Change route( user view)
      })
        .catch(error => {                           // api call failed
          alert("입력한 정보를 다시 확인하세요.");
          setSubmitLoading(true);
        })
    }// variable Check if else end
  } // fn handleSubmit end

  return (
    <Flex position='relative' h='max-content' backgroundColor='#008BFF' >
      <Flex
        h="100vh"
        // w='100%'
        // maxW={{ md: "66%", lg: "1313px" }}
        mx='auto'
        pt={{ sm: "50px", md: "0px" }}
        px={{ lg: "30px", xl: "0px" }}
        ps={{ xl: "70px" }}
        justifyContent='start'
        direction='column'
        alignItems='center'>
        <Flex
          className='findPwd'
          maxW={{ base: "100%", md: "max-content" }}
          // w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100vh'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          // mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'>
          <Box me='auto' >
            <Flex justifyContent='center' mb='20%' mt='10%' >
            </Flex>
            <Heading color='white' fontSize='130%' mb='10px'>
             비밀번호 찾기
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color='white'
              fontWeight='400'
              fontSize='md'>
              이메일을 입력하세요. 인증번호를 보내드립니다.
            </Text>
          </Box>
          <Flex
            zIndex='1'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            // mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            {
              verityCode !== ''
                ?
                <form onSubmit={modifyHandleSubmit}>
                  <FormControl>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color='white'
                      mb='8px'>
                      인증코드 <Text color='white'>*</Text>
                    </FormLabel>
                    <Input
                      isRequired={true}
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      type='text'
                      placeholder='verify code'
                      mb='20px'
                      fontWeight='500'
                      size='lg'
                      autoComplete="off"
                      id="inputVerityCode"
                      name="inputVerityCode"
                      value={inputVerityCode}
                      onChange={onChange} />
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color='white'
                      mb='8px'>
                      신규 비밀번호 <Text color='white'>&nbsp;&nbsp;* 8글자 이상 입력하세요.</Text>
                    </FormLabel>

                    <InputGroup size='md'>
                      <Input
                        isRequired={true}
                        fontSize='sm'
                        placeholder='new password'
                        mb={{ base: '40px', md: '60px', xl: '45px' }}
                        size='lg'
                        type={show ? "text" : "password"}
                        variant='auth'
                        autoComplete="off"
                        id="newPassword"
                        name="newPassword"
                        value={newPassword}
                        onChange={onChange} />
                      <InputRightElement display='flex' alignItems='center' mt='4px'>
                        <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: "pointer" }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick} />
                      </InputRightElement>
                    </InputGroup>

                    {
                      verityCode === inputVerityCode && newPassword.length > 7
                        ?
                        <Button backgroundColor='#245EC6' borderRadius='5' height='50' width='100%' type="submit" >
                          <Text ml='4px' color='white'>비밀번호 변경</Text>
                        </Button>
                        :
                        <Button backgroundColor='#245EC6' borderRadius='5' height='50' width='100%' disabled='disabled'>
                          <Text ml='4px' color='white'>  </Text>
                        </Button>
                    }
                  </FormControl>
                </form>
                :

                <form onSubmit={handleSubmit}>
                  <FormControl>
                    <FormLabel
                      display='flex'
                      ms='4px'
                      fontSize='sm'
                      fontWeight='500'
                      color='white'
                      mb='8px'>
                      이메일 <Text color='white'>*</Text>
                    </FormLabel>
                    <Input
                      isRequired={true}
                      autoComplete="off"
                      variant='auth'
                      fontSize='sm'
                      ms={{ base: "0px", md: "0px" }}
                      type='text'
                      placeholder='test@veta.io'
                      mb='40px'
                      fontWeight='500'
                      size='lg'
                      id="email"
                      name="email"
                      value={email}
                      onChange={onChange} />


                    {
                      email !== ''
                        ?
                        submitLoading
                          ?
                          <Button backgroundColor='#245EC6' borderRadius='5' height='50' width='100%' type="submit" >
                            <Text ml='4px' color='white'>메일 전송</Text>
                          </Button>
                          :
                          <Button backgroundColor='#245EC6' borderRadius='5' height='50' width='100%' variant='outline' isLoading loadingText='진행중..' />
                        :
                        <Button backgroundColor='#245EC6' borderRadius='5' height='50' width='100%' disabled='disabled'>
                          <Text ml='4px' color='white'> </Text>
                        </Button>
                    }
                  </FormControl>
                </form>
            }



            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              mt='5px'>
              <Text color={textColorDetails} fontWeight='400' fontSize='14px'>

                <Text
                  as='span'
                  textDecoration='underline'
                  ms='5px'
                  fontWeight='500'
                  color='white'
                  onClick={() => setViewComponent('Login')}>
                  로그인
                </Text>
                <Text
                  as='span'
                  textDecoration='underline'
                  ms='5px'
                  ml='20px'
                  fontWeight='500'
                  color='white'
                  onClick={() => setViewComponent('SignUp')}>
                  회원가입
                </Text>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Underbar color='white' />
      </Flex>
    </Flex>
  );
}