import React from 'react';
import '../css/App.css';
import { Box, Image } from "@chakra-ui/react";
import veta from "../assets/img/service/veta_banana.png"
export default function LodingFirst() {
  return (
    <Box
      overflow='hidden'
      backgroundColor='#008BFF'
      // backgroundImage={firstPage}
      // backgroundSize='cover'
      // backgroundPosition='center'
      // backgroundRepeat='no-repeat'
      width='100vw' height='100vh'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      align='center'
      justify='center'
      // onClick={() => { setViewComponent('Login') }}
    >
      <Box align='center'>
        <Image className='loadingKUDETA' src={veta} width='264px' height='78px' />
      </Box>
    </Box>
  );
}