// React
import React from "react";
import { Flex, Box, Icon, Text, useColorModeValue } from "@chakra-ui/react";
// Components
import Card from "../../../../components/card/Card.js";
// Assets
import { BsCaretUpFill, BsCaretDownFill } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { MdClose } from "react-icons/md";
/* global BigInt */
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function BuyListCard({ userInfo, buyList, totalPrice, handleRemoveItem, handleIncreaseQuantity, handleDecreaseQuantity, setStoreComponent, takeoutChange, takeoutStatus, setTakeoutStatus, creditStatus, creditChange, setCreditStatus, bnpl, totalDiscountAmount, totalDiscountPercent }) {
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    function check() {
        if (totalPrice <= userInfo.amount) {
            setStoreComponent('second');
        } else {
            alert("잔액이 부족합니다.");
        }
    }

    return (
        <>
            <Flex
                width='90%'
                p='10px'
                mx='auto'
                height='7vh'
            >
                <Icon className="backArrowOrder"
                    onClick={() => setStoreComponent('')}
                    as={BsArrowLeft}
                />

            </Flex>
            <Flex width='90%' height='3vh'>
                <Text fontWeight='700'>
                    {userInfo.account_name}
                </Text>
                <Text>
                    님 주문 내역입니다.
                </Text>
            </Flex>
            <Card
                className='table-box'
                height='45vh'
                overflow='auto'
                backgroundColor='rgba(70, 209, 232, 0.5)'
                alignSelf='center'
                boxShadow={shadow}
                bgPosition='10%'
                mx='auto'
                width='90%'
                p='10px'>
                <Flex direction='column' h='100%' w='100%' justifyContent='space-between' overflow='auto' className="table-box">
                    <Box >
                        <Flex direction='column'>
                            {
                                buyList.map((item, index) => (
                                    <Card
                                        // h={{ base: '50px', md: '50px', xl: '40px' }}
                                        borderRadius='5'
                                        boxShadow={shadow}
                                        alignSelf='center'
                                        mx='auto'
                                        mb='1%'
                                        p='2%'
                                        width='100%'
                                        key={index}
                                        isTruncated>
                                        <Flex direction='column'>
                                            <Flex justify='space-evenly'>
                                                <Text>
                                                    {item.item.name}
                                                </Text>
                                                <Text>
                                                    {item.item.category_big_name}
                                                </Text>
                                                <Text>
                                                    {item.item.category_name}
                                                </Text>
                                            </Flex>
                                            {item.selectedOptions.length > 0 ?
                                                <Flex direction='column' justify='space-evenly' border='1px solid gray'>
                                                    {
                                                        item.selectedOptions &&
                                                        item.selectedOptions.map((option, index) => (
                                                            <Flex
                                                                width='100%'
                                                                justify='space-around'
                                                                key={index}
                                                            >
                                                                <Text>
                                                                    {option.option_name}
                                                                </Text>

                                                                <Text>
                                                                    +{(option.additional_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원
                                                                </Text>
                                                            </Flex>
                                                        ))
                                                    }
                                                </Flex>
                                                :
                                                null}
                                            <Flex justify='space-evenly'>
                                                <Text>
                                                    가격 : &nbsp;
                                                </Text>
                                                {
                                                    totalDiscountAmount || totalDiscountPercent ?
                                                        <Flex>
                                                            <Text
                                                                color='red'
                                                                textDecoration='line-through'
                                                            >
                                                                {(item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                                            </Text>
                                                            <Text>
                                                                &nbsp; -&gt; &nbsp;
                                                            </Text>
                                                            {
                                                                totalDiscountAmount ?
                                                                    <Text
                                                                        whiteSpace='nowrap'
                                                                        overflow='hidden'
                                                                        textOverflow='ellipsis'
                                                                    >
                                                                        {(Number(item.price) - Number(totalDiscountAmount)) > 0 ? (Number(item.price) - Number(totalDiscountAmount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                                                    </Text>

                                                                    :
                                                                    totalDiscountPercent ?
                                                                        <Text
                                                                            whiteSpace='nowrap'
                                                                            overflow='hidden'
                                                                            textOverflow='ellipsis'
                                                                        >
                                                                            {(Number(item.price) * (100 - Number(totalDiscountPercent)) / 100) > 0 ? (parseInt((Number(item.price) * (100 - Number(totalDiscountPercent)) / 100))).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0}
                                                                        </Text>
                                                                        :
                                                                        <Text
                                                                            whiteSpace='nowrap'
                                                                            overflow='hidden'
                                                                            textOverflow='ellipsis'
                                                                        >
                                                                            {(item.price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                                                        </Text>
                                                            }
                                                        </Flex>
                                                        :
                                                        <Text isTruncated>
                                                            {item.price}
                                                        </Text>
                                                }


                                                <Text>
                                                    수량 : {item.quantity}
                                                </Text>


                                            </Flex>
                                            <Flex justify='space-evenly'>
                                                <Icon
                                                    onClick={() => {
                                                        handleIncreaseQuantity(index)
                                                    }}
                                                    as={BsCaretUpFill} color='blue' w='25px' h='25px' />
                                                <Icon
                                                    onClick={() => {
                                                        handleDecreaseQuantity(index, item)
                                                    }}
                                                    as={BsCaretDownFill} color='blue' w='25px' h='25px' />
                                                <Icon
                                                    onClick={() => {
                                                        handleRemoveItem(index, item.item)
                                                    }}
                                                    as={MdClose} color='red' w='25px' h='25px' />
                                            </Flex>
                                        </Flex>

                                    </Card>



                                ))
                            }
                        </Flex>
                    </Box>
                </Flex >
            </Card >
            <Flex>
                {
                    bnpl ?
                        <>
                            <Card
                                mt='3vh' h='7vh' w='40%' alignSelf='center' mx='auto'
                                backgroundColor='rgba(70, 209, 232, 0.5)'
                                onClick={() => setCreditStatus("0")} py='10px'>
                                <Flex direction='column' textAlign='start'>
                                    <label>
                                        <input
                                            type="radio"
                                            value="0"
                                            checked={creditStatus === "0"}
                                            onChange={creditChange}
                                        />
                                        포인트
                                    </label>
                                    <Text fontSize='12px'>
                                        잔액 : &nbsp;
                                        {userInfo.amount && userInfo.amount !== 'error' ?
                                            (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                            :
                                            0
                                        }
                                    </Text>
                                </Flex>
                            </Card >
                            <Card
                                mt='3vh' h='7vh' w='40%' alignSelf='center' mx='auto'
                                backgroundColor='rgba(70, 209, 232, 0.5)'
                                onClick={() => setCreditStatus("1")} py='10px'>
                                <Flex direction='column' textAlign='start'>
                                    <label>
                                        <input
                                            type="radio"
                                            value="1"
                                            checked={creditStatus === "1"}
                                            onChange={creditChange}
                                        />
                                        후불
                                    </label>
                                    <Text fontSize='12px'>
                                        잔액 : &nbsp;
                                        {userInfo.bnpl_amount && userInfo.bnpl_amount !== 'error' ?
                                            (userInfo.bnpl_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                            :
                                            0
                                        }
                                    </Text>
                                </Flex>
                            </Card >
                        </>
                        :
                        <Card
                            mt='3vh' h='7vh' w='90%' alignSelf='center' mx='auto'
                            backgroundColor='rgba(70, 209, 232, 0.5)'
                            onClick={() => setCreditStatus("0")}>
                            <Flex direction='column' textAlign='start'>

                                <Text>
                                    포인트 : &nbsp;
                                    {userInfo.amount && userInfo.amount !== 'error' ?
                                        (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                        :
                                        0
                                    }
                                </Text>
                            </Flex>
                        </Card >
                }
            </Flex>
            <Flex>
                <Card
                    mt='3vh' h='7vh' w='40%' alignSelf='center' mx='auto'
                    backgroundColor='rgba(70, 209, 232, 0.5)'
                    onClick={() => setTakeoutStatus("0")}>
                    <Flex>
                        <label>
                            <input
                                type="radio"
                                value="0"
                                checked={takeoutStatus === "0"}
                                onChange={takeoutChange}
                            />
                            매장
                        </label>
                    </Flex>
                </Card >
                <Card
                    mt='3vh' h='7vh' w='40%' alignSelf='center' mx='auto'
                    backgroundColor='rgba(70, 209, 232, 0.5)'
                    onClick={() => setTakeoutStatus("1")}>
                    <Flex>
                        <label>
                            <input
                                type="radio"
                                value="1"
                                checked={takeoutStatus === "1"}
                                onChange={takeoutChange}
                            />
                            포장
                        </label>
                    </Flex>
                </Card >
            </Flex>
            {
                buyList.length === 0 ?

                    <Card
                        mt='3vh' h='7vh' w='90%' alignSelf='center' mx='auto'
                        backgroundColor='rgba(70, 209, 232, 1)' color='white'
                        onClick={() => setStoreComponent('')}>
                        <Flex>
                            <Text mx='auto'>
                                상품 둘러보기
                            </Text>
                        </Flex>
                    </Card >
                    :
                    <Card
                        mt='3vh' h='7vh' w='90%' alignSelf='center' mx='auto'
                        backgroundColor='rgba(70, 209, 232, 1)' color='white'
                        onClick={() => { check() }}
                    >
                        <Flex justifyContent='space-between'>

                            <Text>
                                구매 금액 :
                            </Text>
                            <Text>
                                {BigInt(totalPrice).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0} VETA
                            </Text>
                        </Flex>
                    </Card >
            }
        </>
    );
}
