// React
import React, { useState, useEffect, useRef } from "react";
// External Libraries
import axios from 'axios';
import { Box, Flex, Image, Button } from "@chakra-ui/react";
// Components
import MainCard from "./components/MainCard";
import MintCard from "./components/MintCard";
import InsertCash from "./components/mint/InsertCash";
import ChooseAccount from "./components/mint/ChooseAccount";
import exit from "../../assets/img/service/exit.png"
import AssetCard from "./components/slide/AssetCard";
import TransactionCard from "./components/slide/TransactionCard";
import "../../css/main.css"
import "../../css/Page.css"
import FriendCard from "./components/FriendCard";
import PaymentCard from "./components/transfer/PaymentCard";
import MintStart from "./components/mint/MintStart";
import ScanCard from "./components/qrcode/ScanCard";
import CodeCard from "./components/qrcode/CodeCard";
import LogoutCard from "./components/logout/LogoutCard";
import Developing from "./components/mint/Developing";
import CafeCard from "./components/CafeCard";
import SirenOrderModal from "./components/store/modal/SirenOrderModal";
import ko from 'date-fns/locale/ko';
import { format } from 'date-fns';
import WhereCard from "./components/WhereCard";
import LodingFirst from "../LodingFirst";
//fcm
// import { messaging } from '../../fcm/firebase';
// import { getToken } from 'firebase/messaging';

export default function MainDashboard({ setViewComponent, token, setToken, sirenOrder, sirenPopUp, setSirenPopUp }) {
  // Variables to check the current window size (mobile/pc)
  const [nowComponent, setNowComponent] = useState('');

  const scrollRef = useRef(null);
  const scrollToRef = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
  };

  const [userUpdate, setUserUpdate] = useState(false);
  const [userInfo, setUserInfo] = useState([]);
  const [userList, setUserList] = useState([]);
  const [recentFriend, setRecentFriend] = useState([]);
  const [transactionList, setTransactionList] = useState([]);
  const [transferReceiver, setTransferReceiver] = useState();
  const [secondPwdCheck, setSecondPwdCheck] = useState(false);
  const nowDetailDate = format(new Date(), 'yyyy-MM-dd (eee) HH:mm', { locale: ko })
  const [secondInput, setSecondInput] = useState(['', '', '', '', '', '']);
  const [txUpdate, setTxUpdate] = useState(false);
  const [pointInfo, setPointInfo] = useState();
  const [bnpl, setBnpl] = useState(false);
  useEffect(() => {
    if (token) {
      axios.post('/api/account/main')
        .then((response) => {
          if (response.data.status === 'expired') {
            setViewComponent("Login");
          } else {
            setUserInfo({
              "name": response.data.name,
              "amount": response.data.amount,
              "authority": response.data.authority,
              "phone": response.data.phone,
              "address": response.data.address,
              "email": response.data.email,
              "account_name": response.data.account_name,
              "bnpl_amount": response.data.bnpl_amount,
              "max_limit_amount": response.data.max_limit_amount
            })
          }
          if (response.data.bnpl_status === '1') {
            setBnpl(true);
          }
        })
        .catch((error) => {
          logout();
        })
      if (userUpdate) {
        setUserUpdate(false);
      }
    }
  }, [nowComponent, userUpdate]
  );

  // 뒤로가기 stack code

  // 안드로이드 취소버튼 감지 및 처리
  const [pageStack, setPageStack] = useState([]);
  // 새로운 페이지를 스택에 추가하고, 스택 업데이트

  function nowComponentStack(page) {
    setPageStack([...pageStack, page]);
    setNowComponent(page);
  };

  // 취소 버튼을 누르면 스택에서 이전 페이지를 팝하여 현재 페이지 변경
  const backComponent = () => {
    setPageStack((prevStack) => prevStack.slice(0, -1));
    setNowComponent(pageStack.length > 0 ? pageStack[pageStack.length - 1] : '');
  };
  window.onpopstate = () => {
    backComponent()
  }
  window.history.pushState(null, null, null);
  ////



  useEffect(() => {
    if (token) {
      var friend = [];
      var lastList = [];
      axios.get('/api/user/accountList', null)
        .then((response) => {
          for (var i = 0; i < response.data.userList.length; i++) {
            friend.push({
              "email": response.data.userList[i].email,
              "name": response.data.userList[i].name,
              "address": response.data.userList[i].address,
              "phone": response.data.userList[i].phone_number
            })
          }
          for (var l = 0; l < response.data.latestUserList.length; l++) {
            lastList.push({
              "email": response.data.latestUserList[l].email,
              "name": response.data.latestUserList[l].name,
              "address": response.data.latestUserList[l].address,
              "phone": response.data.userList[l].phone_number
            })
          }
          setUserList(friend);
          setRecentFriend(lastList);
        })
        .catch((error) => {
          // logout();
        })
    }
  }, [nowComponent, token]
  );


  useEffect(() => {
    if (token) {

      var transaction = [];
      axios.post('/api/account/tx', null)
        .then((response) => {
          for (var i = 0; i < response.data.txList.length; i++) {
            // Variables for specific data for each transaction
            var price;
            price = (response.data.txList[i].amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;
            const parsedDate = response.data.txList[i].date.replaceAll('-', '.').replaceAll('_', ' ').substring(0, 16);

            transaction.push({
              "date": parsedDate,
              "name": response.data.txList[i].target_name,
              "source_result_amount": response.data.txList[i].source_result_amount,
              "target_result_amount": response.data.txList[i].target_result_amount,
              "amount": price,
              "transaction_hash": response.data.txList[i].transaction_hash,
              "source_account_address": response.data.txList[i].source_account_address,
              "target_account_address": response.data.txList[i].target_account_address,
              "target_name": response.data.txList[i].target_name,
              "transaction_type": response.data.txList[i].transaction_type,
              "transaction_code": response.data.txList[i].transaction_code,
              "status": response.data.txList[i].status,
              "source_name": response.data.txList[i].source_name
            })
          }
          setTransactionList(transaction);
        })
        .catch((error) => {
          // logout();
        })
    }
    if (txUpdate) {
      setTxUpdate(false);
    }
  }, [nowComponent, token, txUpdate]
  );

  // // A function variable for the logout functionality
  function logout() {
    setToken(null);
    axios.post('/api/sign/logout');
    localStorage.removeItem('jwtToken');
    setViewComponent('');
  }



  // init Value
  const [storeList, setStoreList] = useState([]);
  const [storeInfo, setStoreInfo] = useState();
  const [storeRoll, setStoreRoll] = useState(false);

  useEffect(() => {
    if (token) {
      var store = [];
      axios.post('/api/product/getStoreInfo')
        .then((response) => {
          for (var i = 0; i < response.data.storeOpenInfo.length; i++) {
            store.push({
              "store_number": response.data.storeOpenInfo[i].store_number,
              "open_status": response.data.storeOpenInfo[i].open_status,
              "store_name": response.data.storeOpenInfo[i].store_name
            })
          }
          setStoreList(store);
        })
    } else {
      // logout();
    }
    if (storeRoll) {
      setStoreRoll(false);
    }
  }, [storeRoll])

  useEffect(() => {
    if (token) {
      axios.post('/api/wallet/point/info')
        .then((response) => {
          setPointInfo({
            "input_amount": response.data.input_amount,
            "create_date": response.data.create_date,
            "expired_date": response.data.expired_date
          })
        })
        .catch((error) => {
          // alert(error);
        })
    }
  }, [])



  // 상품정보 
  const [discountMessage, setDiscountMessage] = useState();
  const [totalDiscountAmount, setTotalDiscountAmount] = useState(0);
  const [totalDiscountPercent, setTotalDiscountPercent] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [bigCategoryList, setBigCategoryList] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [discountList,setDiscountList] = useState([])

  const [optionDetail, setOptionDetail] = useState([]);
  const [optionGroup, setOptionGroup] = useState([]);
  const [optionMapping, setOptionMapping] = useState([]);
  useEffect(() => {
    var items = [];
    var big = [];
    var totalAmount = 0;
    var totalPercent = 0;
    var store = [];
    var discount = [];
    var optionGroup = [];
    var optionDetails = [];
    var optionMapping = [];

    axios.post('/api/product/getTotal')
      .then((response) => {
        const test1 = response.data.bigCategory;
        const jsonBigCategory = JSON.parse(test1);
        const test3 = response.data.totalProductInfo;
        const jsonItem = JSON.parse(test3);
        const test4 = response.data.discount;
        const jsonDiscount = JSON.parse(test4);
        const test5 = response.data.optionGroup;
        const jsonOptionGroup = JSON.parse(test5);
        const test6 = response.data.option;
        const jsonOptions = JSON.parse(test6);
        const test7 = response.data.productOption;
        const jsonMapping = JSON.parse(test7);
        if (jsonBigCategory) {
          for (var i = 0; i < jsonBigCategory.length; i++) {
            big.push({
              "category_big_id": jsonBigCategory[i].category_big_id,
              "category_big_name": jsonBigCategory[i].category_big_name,
              "category_primary": jsonBigCategory[i].category_primary,
              "image": jsonBigCategory[i].image
            })
          }
          setBigCategoryList(big);
        }
        if (jsonItem) {
          for (var l = 0; l < jsonItem.length; l++) {
            items.push({
              "category_big_id": jsonItem[l].category_big_id,
              "category_id": jsonItem[l].category_id,
              "product_number": jsonItem[l].product_number,
              "date": jsonItem[l].reg_date,
              "name": jsonItem[l].product_name,
              "price": jsonItem[l].price1,
              "image": jsonItem[l].image,
              "owner": jsonItem[l].store_number,
              "comment": jsonItem[l].content,
              "stock": jsonItem[l].product_stock
            })
          }
          setItemList(items);
        }
        if (jsonDiscount) {
          for (var k = 0; k < jsonDiscount.length; k++) {
            discount.push({
              "discount_number": jsonDiscount[k].discount_number,
              "discount_amount": jsonDiscount[k].discount_amount,
              "discount_percent": jsonDiscount[k].discount_percent,
              "comment": jsonDiscount[k].comment
            })
            setDiscountMessage(jsonDiscount[k].discount_number);
            if (jsonDiscount[k].discount_amount) {
              totalAmount += Number(jsonDiscount[k].discount_amount);
            } else if (jsonDiscount[k].discount_percent) {
              totalPercent += Number(jsonDiscount[k].discount_percent);
            }
          }
          setDiscountList(discount);
          setTotalDiscountAmount(totalAmount);
          setTotalDiscountPercent(totalPercent);
        }
        if (jsonOptionGroup) {
          for (var i = 0; i < jsonOptionGroup.length; i++) {
            optionGroup.push({
              "option_group_id": jsonOptionGroup[i].option_group_id,
              "option_group_name": jsonOptionGroup[i].option_group_name,
              "option_group_type": jsonOptionGroup[i].option_group_type
            });
          }
          setOptionGroup(optionGroup);
        }
        if (jsonOptions) {
          for (var i = 0; i < jsonOptions.length; i++) {
            optionDetails.push({
              "option_id": jsonOptions[i].option_id,
              "option_group_id": jsonOptions[i].option_group_id,
              "option_name": jsonOptions[i].option_name,
              "additional_price": jsonOptions[i].additional_price
            });
          }
          setOptionDetail(optionDetails);
        }

        if (jsonMapping) {
          for (var i = 0; i < jsonMapping.length; i++) {
            optionMapping.push({
              "product_number": jsonMapping[i].product_number,
              "option_group_id": jsonMapping[i].option_group_id
            });
          }
          setOptionMapping(optionMapping);
        }

        if (loading) {
          setLoading(false);
        }
      })
    axios.post('/api/product/getStoreInfo')
      .then((response) => {
        if (response.data.storeOpenInfo) {
          for (var i = 0; i < response.data.storeOpenInfo.length; i++) {
            store.push({
              "store_number": response.data.storeOpenInfo[i].store_number,
              "open_status": response.data.storeOpenInfo[i].open_status,
              "store_name": response.data.storeOpenInfo[i].store_name
            })
            setStoreInfo(store[0]);
          }
        }
      })
      .catch((error) => {
      })
  }, [])

  // 좌석선택 init
  const [whereUpdate, setWhereUpdate] = useState(false);
  const [floreList, setFloreList] = useState();

  const [seatList, setSeatList] = useState();
  const [seatInfo, setSeatInfo] = useState();
  useEffect(() => {
    var flore = [];
    var seat = [];
    if (token) {
      axios.post('/api/seat/info')
        .then((response) => {
          for (var i = 0; i < response.data.flore.length; i++) {
            flore.push({
              "flore": response.data.flore[i].flore,
              "total": response.data.flore[i].total,
              "active": response.data.flore[i].active,
              "floreInfomation": response.data.flore[i].floreInfomation
            })
            for (var l = 0; l < response.data.flore[i].floreInfomation.length; l++) {
              seat.push({
                "flore": response.data.flore[i].flore,
                "seat_number": response.data.flore[i].floreInfomation[l].seat_number,
                "seat_name": response.data.flore[i].floreInfomation[l].seat_name,
                "seat_active": response.data.flore[i].floreInfomation[l].seat_active
              })
            }
            setSeatList(seat);
          }
          setFloreList(flore);
        })
        .catch((error) => {
        })

      if (whereUpdate) {
        setWhereUpdate(false);
      }
    }
  }, [whereUpdate, token])

  return (
    <>
      {
        loading ?
          <LodingFirst />
          :
          nowComponent === 'mint' ?
            <MintCard
              userInfo={userInfo}
              setNowComponent={nowComponentStack} />
            :
            nowComponent === 'developing' ?
              <Developing setNowComponent={setNowComponent} />
              :
              nowComponent === 'mintInsert' ?
                <InsertCash
                  setNowComponent={nowComponentStack}
                  userInfo={userInfo}
                />
                :
                nowComponent === 'chooseAccount' ?
                  <ChooseAccount
                    setNowComponent={nowComponentStack}
                    userInfo={userInfo}
                  />
                  :
                  nowComponent === 'mintStart' ?
                    <MintStart
                      setNowComponent={nowComponentStack}
                      userInfo={userInfo} />

                    :

                    <Flex
                      overflowY='hidden'
                      backgroundColor='#46D1E8'
                      direction='column'
                      width='100%'
                      height='100%'
                      zIndex='1'>
                      {sirenPopUp && <SirenOrderModal setNowComponent={setNowComponent} userInfo={userInfo} nowDetailDate={nowDetailDate} sirenOrder={sirenOrder} onClose={() => setSirenPopUp(false)} />}
                      <Flex
                        mt='2.5vh'
                      >

                        {
                          nowComponent === '' || nowComponent === 'asset' || nowComponent === 'transaction' || nowComponent === 'qrCode' || nowComponent === 'payment' || nowComponent === 'qrScan' || nowComponent === 'friend' && (nowComponent !== 'logout' && nowComponent !== 'cafe') ?
                            <Button
                              className="navbarText"
                              borderRadius='0'
                              backgroundColor='#46D1E8'
                              textColor='black'
                              borderBottom='4px solid'
                              borderColor='#008399'
                              px='4px'
                              ml='12px'
                              _hover={{ textColor: 'black', borderBottom: '4px solid', borderColor: '#008399', backgroundColor: '#46D1E8' }}
                              _active={{ textColor: 'black', borderBottom: '4px solid', borderColor: '#008399', backgroundColor: '#46D1E8' }}
                            >
                              PAY
                            </Button>
                            :
                            nowComponent === 'logout' ?
                              <Button
                                className="navbarText"
                                borderRadius='0'
                                backgroundColor='#46D1E8'
                                textColor='black'
                                px='4px'
                                ml='12px'
                              >
                                &nbsp;
                              </Button>
                              :
                              <Button
                                className="navbarText"
                                borderRadius='0'
                                backgroundColor='#46D1E8'
                                textColor='white'
                                px='4px'
                                ml='12px'
                                _focus={{ textColor: 'black', borderBottom: '4px solid', borderColor: '#008399', backgroundColor: '#46D1E8' }}
                                _hover={{ textColor: 'black', borderBottom: '4px solid', borderColor: '#008399', backgroundColor: '#46D1E8' }}
                                _active={{ textColor: 'black', borderBottom: '4px solid', borderColor: '#008399', backgroundColor: '#46D1E8' }}
                                onClick={() => { nowComponentStack('') }}
                              >
                                PAY
                              </Button>

                        }


                        {
                          nowComponent === 'cafe' ?
                            <Button
                              className="navbarText"
                              borderRadius='0'
                              backgroundColor='#46D1E8'
                              textColor='black'
                              borderBottom='4px solid'
                              borderColor='#008399'
                              px='4px'
                              ml='12px'
                              _hover={{ textColor: 'black', borderBottom: '4px solid', borderColor: '#008399', backgroundColor: '#46D1E8' }}
                              _active={{ textColor: 'black', borderBottom: '4px solid', borderColor: '#008399', backgroundColor: '#46D1E8' }}
                            >
                              ORDER
                            </Button>
                            :
                            nowComponent === 'logout' ?
                              <Button
                                className="navbarText"
                                borderRadius='0'
                                backgroundColor='#46D1E8'
                                textColor='black'
                                px='4px'
                                ml='12px'
                              >
                                &nbsp;
                              </Button>
                              :
                              <Button
                                className="navbarText"
                                borderRadius='0'
                                backgroundColor='#46D1E8'
                                textColor='white'
                                px='4px'
                                ml='12px'
                                _hover={{ textColor: 'white', backgroundColor: '#46D1E8' }}
                                onClick={() => { nowComponentStack('cafe') }}
                              >
                                ORDER
                              </Button>
                        }
                        {
                          nowComponent === 'where' ?
                            <Button
                              className="navbarText"
                              borderRadius='0'
                              backgroundColor='#46D1E8'
                              textColor='black'
                              borderBottom='4px solid'
                              borderColor='#008399'
                              px='4px'
                              ml='12px'
                              _hover={{ textColor: 'black', borderBottom: '4px solid', borderColor: '#008399', backgroundColor: '#46D1E8' }}
                              _active={{ textColor: 'black', borderBottom: '4px solid', borderColor: '#008399', backgroundColor: '#46D1E8' }}
                            >
                              OFFICE
                            </Button>
                            :
                            nowComponent !== 'where' && nowComponent !== 'logout' ?
                              <Button
                                className="navbarText"
                                borderRadius='0'
                                backgroundColor='#46D1E8'
                                textColor='white'
                                px='4px'
                                ml='12px'
                                _hover={{ textColor: 'white', backgroundColor: '#46D1E8' }}
                                onClick={() => { nowComponentStack('where') }}
                              >
                                OFFICE
                              </Button>
                              :
                              nowComponent === 'logout' ?
                                <Button
                                  className="navbarText"
                                  borderRadius='0'
                                  backgroundColor='#46D1E8'
                                  textColor='black'
                                  px='4px'
                                  ml='12px'
                                >
                                  &nbsp;
                                </Button>
                                :
                                <Button
                                  className="navbarText"
                                  borderRadius='0'
                                  backgroundColor='#46D1E8'
                                  textColor='white'
                                  px='4px'
                                  ml='12px'
                                  _hover={{ textColor: 'white', backgroundColor: '#46D1E8' }}
                                  onClick={() => { nowComponentStack('where') }}
                                >
                                  OFFICE
                                </Button>
                        }
                        <Image src={exit} className="navbarImage" ml='auto' mr='25px' align='bottom' onClick={() => { setNowComponent('logout') }} />
                      </Flex>
                      <Box
                        borderRadius='20px 20px 0px 0px'
                        zIndex='2'
                        height='100vh'
                        overflowY='auto'
                        width='100vw'
                        backgroundColor='white'
                      >

                        {
                          nowComponent === ''
                            ? // AssetTable (Mobile)
                            <Box >
                              <>
                                <MainCard
                                  userInfo={userInfo}
                                  pointInfo={pointInfo}
                                  scrollToRef={scrollToRef}
                                  setTransferReceiver={setTransferReceiver}
                                  setNowComponent={nowComponentStack}
                                  bnpl={bnpl} />
                              </>
                            </Box>
                            :
                            nowComponent === 'cafe' ?
                              <>
                                <CafeCard
                                  userInfo={userInfo} setNowComponent={nowComponentStack}
                                  secondPwdCheck={secondPwdCheck} setSecondPwdCheck={setSecondPwdCheck}
                                  setSecondInput={setSecondInput} secondInput={secondInput}
                                  storeList={storeList}
                                  setStoreInfo={setStoreInfo} storeInfo={storeInfo}
                                  setStoreRoll={setStoreRoll} bnpl={bnpl}

                                  discountMessage={discountMessage} setDiscountMessage={setDiscountMessage}
                                  totalDiscountAmount={totalDiscountAmount} setTotalDiscountAmount={setTotalDiscountAmount}
                                  totalDiscountPercent={totalDiscountPercent} setTotalDiscountPercent={setTotalDiscountPercent}
                                  categoryList={categoryList} setCategoryList={setCategoryList}
                                  bigCategoryList={bigCategoryList} setBigCategoryList={setBigCategoryList}
                                  itemList={itemList} setItemList={setItemList}
                                  optionDetail={optionDetail}
                                  optionGroup={optionGroup}
                                  optionMapping={optionMapping}
                                  discountList={discountList}
                                />

                              </>
                              :
                              nowComponent === 'asset'
                                ?
                                <Box >
                                  <>
                                    <AssetCard
                                      setUserUpdate={setUserUpdate}
                                      userInfo={userInfo}
                                      scrollToRef={scrollToRef}
                                      nowComponent={nowComponent}
                                      setNowComponent={nowComponentStack}
                                      bnpl={bnpl} />
                                  </>
                                </Box>
                                :
                                nowComponent === 'transaction'
                                  ?
                                  <Box >
                                    <TransactionCard
                                      userInfo={userInfo}
                                      scrollToRef={scrollToRef}
                                      transactionList={transactionList}
                                      setTxUpdate={setTxUpdate}
                                      setNowComponent={nowComponentStack} />
                                  </Box>
                                  :
                                  nowComponent === 'logout' ?
                                    <Box>
                                      <LogoutCard nowComponent={nowComponent} setNowComponent={setNowComponent} logout={logout} />
                                    </Box>

                                    :
                                    nowComponent === 'friend' ?
                                      <Box>
                                        <FriendCard
                                          userInfo={userInfo}
                                          userList={userList}
                                          setNowComponent={nowComponentStack}
                                          setTransferReceiver={setTransferReceiver}
                                          recentFriend={recentFriend}
                                        />

                                      </Box>
                                      :
                                      nowComponent === 'payment' ?
                                        <Box>
                                          <PaymentCard
                                            userInfo={userInfo}
                                            setNowComponent={nowComponentStack}
                                            transferReceiver={transferReceiver} />
                                        </Box>
                                        :
                                        nowComponent === 'qrScan' ?
                                          <ScanCard
                                            userInfo={userInfo}
                                            nowComponent={nowComponent}
                                            setNowComponent={nowComponentStack}
                                            setTransferReceiver={setTransferReceiver}
                                            transferReceiver={transferReceiver}
                                          />
                                          :
                                          nowComponent === 'qrCode' ?
                                            <CodeCard
                                              userInfo={userInfo}
                                              setNowComponent={nowComponentStack}
                                            />
                                            :
                                            nowComponent === 'where' ?
                                              <WhereCard
                                                userInfo={userInfo}
                                                floreList={floreList}
                                                seatList={seatList}
                                                setSeatInfo={setSeatInfo}
                                                seatInfo={seatInfo}
                                                setSeatList={setSeatList}
                                                setUserUpdate={setUserUpdate}
                                                setWhereUpdate={setWhereUpdate}
                                                whereUpdate={whereUpdate}
                                              />
                                              :
                                              null
                        }
                      </Box>
                    </Flex>

      }
    </>
  );
}
