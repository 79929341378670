// React
import React, { useState } from "react";
// External Libraries
import axios from 'axios';
import { Flex, Box, Text } from "@chakra-ui/react";
// Components
import '../../../../../css/main.css';
import { Button } from "reactstrap";

export default function SecEmail({ userInfo, onCopy, setAssetComponent, updatePn, onChange, updatePhoneNumber, checkChange, updateAs, updateAsName }) {
    const [sendOk, setSendOk] = useState(false);

    // view chage variables for input component
    const [submitLoading, setSubmitLoading] = useState(true);
    // async api call
    async function handleSubmit(e) {

        e.preventDefault();
        // input style change
        setSubmitLoading(false);

        //server api call
        axios.post('/api/user/resetSecond')
            .then((response) => {
                if (response.data.status === '1') {
                    setSendOk(true);
                    setSubmitLoading(true);
                } else {
                    alert("잠시 후 다시 시도해주세요.");
                    setSubmitLoading(true);
                }
            })
            .catch(error => {                           // api call failed
                alert("입력한 정보를 다시 확인하세요.");
                setSubmitLoading(true);
            })
    } // fn handleSubmit end
    return (
        sendOk ?
            <>
                <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center' direction='column'>
                    <Text>
                        발송된 비밀번호로
                    </Text>
                    <Text my='3%'>
                        새롭게 변경해주세요.
                    </Text>

                </Flex>

                <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                    <Box mx='auto' backgroundColor='#46D1E8' borderRadius='10px' padding='5px'>
                        <Button onClick={() => { setAssetComponent('second') }}>
                            <Text textColor='white' fontWeight='700'>
                                확인
                            </Text>
                        </Button>
                    </Box>
                </Flex>

            </>
            :
            <>
                <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center' direction='column'>
                    <Text>
                        {userInfo.email}로
                    </Text>
                    <Text my='3%'>
                        초기화한 비밀번호를 전송해드립니다.
                    </Text>
                    <Text>
                        전송하시겠습니까?
                    </Text>

                </Flex>

                <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>

                    <Box mx='auto' backgroundColor='#46D1E8' borderRadius='10px' padding='5px' textColor='white' fontWeight='700'>
                        {
                            submitLoading ?

                                <Button variant='outline' onClick={(e) => { handleSubmit(e) }}>
                                    전송하기
                                </Button>
                                :
                                <Button variant='outline' isLoading loadingText='진행중..' />
                        }

                    </Box>

                </Flex>

            </>
    );
}