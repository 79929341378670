// React
import React from "react";
import { Flex, Box, Text, Button, useColorModeValue, IconButton } from "@chakra-ui/react";
// Components
import Card from "../../../../components/card/Card.js";
// Assets
import { BsArrowCounterclockwise } from "react-icons/bs";
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function SelectCard({ storeList, setStoreComponent, setStoreInfo, setStoreRoll }) {


    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    return (
        <>
            <Flex
                width='90%'
                p='10px'
                mx='auto'
                // height='10vh'
                mb='2vh'
            >
                <IconButton
                    className="storeRollBtn"
                    shadow={shadow}
                    backgroundColor='rgba(0,0,0,0)'
                    textColor='rgba(0, 100, 120, 1)'
                    // mr='5%'
                    ml='auto'
                    as={BsArrowCounterclockwise}
                    onClick={() => {
                        setStoreRoll(true);
                    }}
                >
                </IconButton>
            </Flex>
            {
                storeList.map((store, index) => (
                    <Card
                        key={index}
                        overflow='auto'
                        backgroundColor='#46D1E8'
                        alignSelf='center'
                        boxShadow={shadow}
                        mx='auto'
                        width='90%'
                        p='10px'
                        mb='5px'>
                        <Flex direction='column' h='100%' w='100%' justifyContent='space-between' overflow='auto'>
                            <Box overflowY='auto' className='screen-box'>
                                <Card
                                    borderRadius='5'
                                    alignSelf='center'
                                    mx='auto'
                                    width='100%'
                                    isTruncated
                                    onClick={() => {
                                        if (store.open_status === '1') {
                                            setStoreComponent('store');
                                            setStoreInfo(store);
                                        }
                                    }}
                                >
                                    <Flex align='center'>
                                        {
                                            store.open_status === '1' ?
                                                <Text>
                                                    {store.store_name}
                                                </Text>
                                                :
                                                <>
                                                    <Text align='left'>
                                                        {store.store_name}
                                                    </Text>
                                                    <Text align='right'>
                                                        &nbsp;영업 준비중
                                                    </Text>
                                                </>
                                        }
                                    </Flex>
                                </Card>
                            </Box>
                        </Flex>
                    </Card>
                ))
            }
            < Flex
                position='sticky'
                top='80vh'
                ml='5%'
            >
                <Button
                    borderRadius='5'
                    height='5vh'
                    width='90vw'
                    backgroundColor='#46D1E8'
                    onClick={() => { setStoreComponent('order') }}
                >
                    <Text color='white'>
                        주문내역 확인
                    </Text>
                </Button>
            </Flex >
        </>
    );
}
