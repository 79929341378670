// React
import React from "react";
import { Flex, Box, Image, Text, Button } from "@chakra-ui/react";
// Components
import '../../../../css/main.css';
import mint_krdc from "../../../../assets/img/service/mint_krdc.gif"

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function MintFinish({ setNowComponent, amount }) {
    // A variable to use function variables from store.js


    return (
        <>

            <Box width='100%'
                height='100%'
                align='center'
                position='relative'
            >

                <Flex fontWeight='700'
                    align='center'
                    justify='center'
                    mt='20vh'
                    mb='10vh'
                    textColor='#004A56'
                    direction='column'
                    h='5vh'
                >
                    <Flex>
                        <Text className="transferFinishBigText">
                            {amount}
                        </Text>
                        <Text fontWeight='400'className="transferFinishSmallText" mt='16px'>
                            VETA
                        </Text>
                    </Flex>
                    <Flex>
                        <Text className="transferFinishMiddleText">
                           mint(충전)이 완료 되었습니다.
                        </Text>
                    </Flex>
                </Flex>
                <Image src={mint_krdc} className="mintLoadingImage" />
            </Box>


            <Button position='fixed' zIndex='5' bottom='0' height='5vh' width='100%' align='center' alignSelf='center' backgroundColor='#46D1E8' borderRadius='0' onClick={() => setNowComponent('')}>
                <Text color='white' fontSize={{ base: 'sm', md: 'lg' }} >
                    완료
                </Text>
            </Button>
        </>
    );
}
