// React
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Flex, Box, Text, Icon, useClipboard } from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
// Components
import '../../../../css/main.css';
import Main from "./assetComponent/Main";
import Password from "./assetComponent/Password";
import SecPassword from "./assetComponent/SecPassword";
import SecEmail from "./assetComponent/SecEmail";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function AssetCard({ nowComponent, setNowComponent, userInfo, setUserUpdate, bnpl }) {
    const { onCopy } = useClipboard(userInfo.address);
    const [assetComponent, setAssetComponent] = useState('');
    const [updateAs, setUpdateAs] = useState(false);
    const [updatePn, setUpdatePn] = useState(false);
    const [inputs, setInputs] = useState({
        account_name: "",
        phone_number: ""
    });
    const { account_name, phone_number } = inputs;
    useEffect(() => {
        setInputs({
            account_name: "",
            phone_number: "",
        });
    }, [])
    function checkChange(value) {
        if (value === 'As') {
            if (updatePn) {
                setUpdatePn(false);
            }
            setUpdateAs(true);
        }
        if (value === 'Pn') {
            if (updateAs) {
                setUpdateAs(false);
            }
            setUpdatePn(true);
        }
    }
    function validateAccountName(account_name) {
        // 특수문자 검증 (알파벳과 숫자만 허용)
        const specialCharPattern = /^[a-zA-Z0-9가-힣]+$/;
        if (!specialCharPattern.test(account_name)) {
            return false;
        }

        // '관리자' 포함 여부 검증
        if (account_name.includes('관리자')) {
            return false;
        }

        // 'i'와 'l'로만 구성된 이름 검증
        const ilPattern = /^[il]+$/i;
        if (ilPattern.test(account_name)) {
            return false;
        }

        return true;
    }
    function onChange(e) {
        // focus input value
        const { value, name } = e.target;
        // inputs value change
        setInputs({
            ...inputs,
            [name]: value,
        });
    } // fn onChange end
    function updateAsName(e) {
        e.preventDefault();
        if (account_name.length > 0 && account_name.length <= 6 && validateAccountName(account_name)) {
            axios.post('/api/user/accountName', {
                account_name: account_name,
            })
                .then((response) => {
                    if (response.data.status === '1') {
                        alert("변경이 완료되었습니다.");
                        setUserUpdate(true);
                        setUpdateAs(false);
                    } else {
                        alert(response.data.error);
                    }
                })
                .catch((error) => {
                    alert("다시 시도해주세요.")
                })
        } else if (account_name.length === 0) {
            if (window.confirm('닉네임을 초기화 하시겠습니까?')) {
                axios.post('/api/user/accountNameReset')
                    .then((response) => {
                        if (response.data.status === '1') {
                            alert("변경이 완료되었습니다.");
                            setUserUpdate(true);
                            setUpdateAs(false);
                        }
                    })
                    .catch((error) => {
                        alert("다시 시도해주세요.")
                    })
            }
        }
    }
    function updatePhoneNumber(e) {
        e.preventDefault();
        const pattern = /^[0-9]+$/;
        if (phone_number.startsWith('010') && phone_number.length === 11 && pattern.test(phone_number)) {
            axios.post('/api/user/phoneNumber', {
                phone_number: phone_number,
            })
                .then((response) => {
                    if (response.data.status === '1') {
                        alert("변경이 완료되었습니다.");
                        setUserUpdate(true);
                        setUpdatePn(false);
                    } else {
                        alert(response.data.error);
                    }
                })
                .catch((error) => {
                    alert("다시 시도해주세요.")
                })
        } else {
            alert("올바른 전화번호를 입력하세요.")
        }
    }
    // 닫기 애니메이션 닫을 컴포넌트에 ${effect} 
    const [effect, setEffect] = useState('mount1');
    const onClickBtn = () => {
        setEffect('unmount');
        setTimeout(() => {
            setNowComponent('');
        }, 400)
    };
    //슬라이드 내려서 닫기
    let startX = 0;
    let startY = 0;

    const handleTouchStart = (e) => {
        startX = e.touches[0].clientX;
        startY = e.touches[0].clientY;
    };

    const handleTouchEnd = (e) => {
        const endX = e.changedTouches[0].clientX;
        const endY = e.changedTouches[0].clientY;

        const deltaX = Math.abs(endX - startX);
        const deltaY = endY - startY;

        if (deltaX < 100 && deltaY > 50) {
            setEffect('unmount');
            setTimeout(() => {
                setNowComponent('');
            }, 400)
        }
    };

    return (
        <div className={`slide-up-container ${effect} ${nowComponent === '' ? 'slide-up-content-closed' : ''}`}>
            <div className="slide-up-content" >
                <Box
                    onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}
                    width='100%'
                    height='100vh'
                    position='relative'
                    align='center'
                >
                    <Icon className="backArrow" onClick={() => onClickBtn('')} color='black' as={BsArrowLeft} />
                    <Flex position='relative' top='7vh' w='90%' direction='column'>
                        <Text
                            className="assetMainText"
                            mr='auto'
                            mt='3vh'
                        >
                            내 정보
                        </Text>
                    </Flex>
                    <Box position='relative' top='15vh' w='90%' >
                        {
                            assetComponent === 'second' ?
                                <SecPassword
                                    userInfo={userInfo}
                                    setAssetComponent={setAssetComponent}
                                    setUserUpdate={setUserUpdate}
                                />
                                :
                                assetComponent === 'pwd' ?
                                    <Password
                                        userInfo={userInfo}
                                        setAssetComponent={setAssetComponent}
                                        setUserUpdate={setUserUpdate} />
                                    :
                                    assetComponent === 'email' ?
                                        <SecEmail
                                            userInfo={userInfo}
                                            setAssetComponent={setAssetComponent}
                                            setUserUpdate={setUserUpdate} />
                                        :
                                        <Main
                                            userInfo={userInfo}
                                            setAssetComponent={setAssetComponent}
                                            onCopy={onCopy}
                                            updatePn={updatePn}
                                            onChange={onChange}
                                            updatePhoneNumber={updatePhoneNumber}
                                            checkChange={checkChange}
                                            updateAs={updateAs}
                                            updateAsName={updateAsName}
                                        />
                        }
                    </Box>

                    {
                        assetComponent === '' ?
                            <Box position='relative' top='15vh' w='90%' >
                                <Flex height='15%' p='2%' fontSize='17px' justifyContent='space-between' align='center'>
                                    <Text >
                                        자산
                                    </Text>
                                    <Text mr='30px'>
                                        금액
                                    </Text>
                                </Flex>

                                <Flex height='20%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                                    <Text color='#008BFF' fontSize='17px' mb='10px' fontWeight='700'>
                                        VETA
                                    </Text>
                                    <Flex direction='column' mr='30px'>
                                        <Text align='right' >
                                            {userInfo.amount ?
                                                (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                                :
                                                0
                                            }
                                        </Text>
                                        <Text fontSize='10px' align='right'>
                                            ￦ {userInfo.amount ?
                                                (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                                :
                                                0
                                            }
                                        </Text>
                                    </Flex>
                                </Flex>
                                {
                                    bnpl ?
                                        <Flex height='20%' p='2%' fontSize='17px' justifyContent='space-between' borderBottom='2px solid' borderColor='gray.200' align='center'>
                                            <Text color='#008BFF' fontSize='17px' mb='10px' fontWeight='700'>
                                                BNPL
                                            </Text>
                                            <Flex direction='column' mr='30px'>
                                                <Text align='right' >
                                                    {userInfo.bnpl_amount ?
                                                        (userInfo.bnpl_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                                        :
                                                        0
                                                    }
                                                </Text>
                                                <Text fontSize='10px' align='right'>
                                                    ￦ {userInfo.max_limit_amount ?
                                                        (userInfo.max_limit_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
                                                        :
                                                        0
                                                    }
                                                </Text>
                                            </Flex>
                                        </Flex>
                                        :
                                        null
                                }
                            </Box>
                            :
                            null
                    }

                </Box>
            </div>
        </div>
    );
}
