// React
import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Button, useColorModeValue, Input, IconButton } from "@chakra-ui/react";
import axios from 'axios';
// Components
import '../../../css/main.css';
import Card from "../../../components/card/Card.js";
// Assets
import gradient3 from "../../../assets/img/service/gradient3.jpeg";
import SelectModal from "./where/SelectModal.js";
import SeatCard from "./where/SeatCard.js";
import MyCard from "./where/slide/MyCard.js";
import BookCard from "./where/slide/BookCard.js";
import { BsSearch } from "react-icons/bs";
import SearchModal from "./where/SearchModal.js";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function WhereCard({ userInfo, floreList, seatList, seatInfo, setSeatInfo, setSeatList, setUserUpdate, setWhereUpdate, whereUpdate }) {
    const [whereComponent, setWhereComponent] = useState();
    const [seatModalOpen, setSeatModalOpen] = useState(false);
    const [myModalOpen, setMyModalOpen] = useState(false);
    const [BookModalOpen, setBookModalOpen] = useState(false);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [mySeatList, setMySesatList] = useState([]);
    const [mySeatInfo, setMySeatInfo] = useState();
    const [userSeatInfo, setUserSeatInfo] = useState([]);
    const [searchUserInfo, setSearchUserInfo] = useState();
    const [floreInfo, setFloreInfo] = useState();
    const [inputs, setInputs] = useState({
        name: ""
    });
    const { name } = inputs;
    useEffect(() => {
        setInputs({
            name: ""
        });
    }, [])
    function onChange(e) {
        // focus input value
        const { value, name } = e.target;
        // inputs value change
        setInputs({
            ...inputs,
            [name]: value,
        });
    } // fn onChange end

    function modalChange1() {
        setWhereComponent('book');
    }
    function modalChange2() {
        setWhereComponent('myModal');
    }

    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );

    useEffect(() => {
        var list = [];
        axios.post('/api/seat/myInfo')
            .then((response) => {
                for (var i = 0; i < response.data.userSeat.length; i++) {
                    list.push({
                        "flore": response.data.userSeat[i].flore,
                        "seat_name": response.data.userSeat[i].seat_name,
                        "seat_number": response.data.userSeat[i].seat_number,
                        "start_date": response.data.userSeat[i].start_date,
                        "finish_date": response.data.userSeat[i].finish_date,
                        "start_time": response.data.userSeat[i].start_time,
                        "finish_time": response.data.userSeat[i].finish_time
                    })
                }
                if (response.data.nowInfo) {
                    setMySeatInfo({
                        "using": "1",
                        "user_seat_number": response.data.nowInfo.user_seat_number,
                        "email": response.data.nowInfo.email,
                        "start_date": response.data.nowInfo.start_date,
                        "start_time": response.data.nowInfo.start_time,
                        "seat_name": response.data.nowInfo.seat_name,
                        "flore": response.data.nowInfo.flore

                    })
                } else {
                    setMySeatInfo({
                        "using": "0"
                    })
                }
                // alert(response.data.nowInfo);
                setMySesatList(list);
            })
            .catch((error) => {

            })
    }, [whereUpdate])

    function seatModalInit(seat) {
        setSeatInfo(seat);
        if (seat.seat_active === '1') {
            axios.post('/api/seat/reservationInfo', {
                seat_number: seat.seat_number
            })
                .then((response) => {
                    setUserSeatInfo({
                        "name": response.data.name,
                        "email": response.data.email,
                        "start_date": response.data.start_date,
                        "start_time": response.data.start_time
                    })
                    setSeatModalOpen(true);
                })
                .catch((error) => {
                    // alert(error);
                })
        } else {
            setSeatModalOpen(true);
        }
    }

    function searchUser() {
        if (name) {

            axios.post('/api/seat/searchUser', {
                name: name
            })
                .then((response) => {
                    if (response.data.user.length > 1) {
                        var list = [];
                        for (var i = 0; i < response.data.user.length; i++) {
                            list.push({
                                "name": response.data.user[i].name,
                                "seat_name": response.data.user[i].seat_name,
                                "start_date": response.data.user[i].start_date,
                                "start_time": response.data.user[i].start_time,
                                "finish_date": response.data.user[i].finish_date,
                                "finish_time": response.data.user[i].finish_time,
                                "flore": response.data.user[i].flore
                            })
                        }
                        setSearchUserInfo(list);
                    } else {
                        setSearchUserInfo({
                            "name": response.data.user[0].name,
                            "seat_name": response.data.user[0].seat_name,
                            "start_date": response.data.user[0].start_date,
                            "start_time": response.data.user[0].start_time,
                            "finish_date": response.data.user[0].finish_date,
                            "finish_time": response.data.user[0].finish_time,
                            "flore": response.data.user[0].flore
                        })
                    }

                    setSearchModalOpen(true);
                })
                .catch((error) => {
                    alert("해당 직원의 예약 정보가 없습니다.");
                })
        }
    }
    function finishSeat(e) {
        e.preventDefault();
        if (window.confirm("현재 시간부로 종료하시겠습니까?")) {

            axios.post('/api/seat/closeSeat', {
                user_seat_number: mySeatInfo.user_seat_number
            })
                .then((response) => {
                    alert("완료되었습니다.");
                    setUserUpdate(true);
                    setWhereUpdate(true);
                    if (seatModalOpen) {
                        setSeatModalOpen(false);
                    }
                })
                .catch((error) => {
                    // alert(error);
                })
        }
    }


    return (
        <>
            {searchModalOpen && <SearchModal onClose={() => setSearchModalOpen(false)} searchUserInfo={searchUserInfo} />}
            {seatModalOpen && <SelectModal finishSeat={finishSeat} setWhereUpdate={setWhereUpdate} mySeatInfo={mySeatInfo} seatInfo={seatInfo} onClose={() => setSeatModalOpen(false)} setUserUpdate={setUserUpdate} userSeatInfo={userSeatInfo} userInfo={userInfo} />}
            {
                whereComponent === 'myModal' ?
                    <Box
                        overflow='hidden'
                        width='100vw'
                        height='100%'>
                        <BookCard onClose={() => setWhereComponent('')} BookModalOpen={BookModalOpen} mySeatList={mySeatList} setSearchModalOpen={setSearchModalOpen} setSearchUserInfo={setSearchUserInfo} />
                    </Box>
                    :
                    whereComponent === 'book' ?
                        <Box
                            overflow='hidden'
                            width='100vw'
                            height='100%'>
                            <MyCard onClose={() => setWhereComponent('')} myModalOpen={myModalOpen} userInfo={userInfo} setUserUpdate={setUserUpdate} mySeatInfo={mySeatInfo} finishSeat={finishSeat} />
                        </Box>
                        :

                        whereComponent === 'seat' ?
                            <SeatCard myModalOpen={myModalOpen} BookModalOpen={BookModalOpen} floreList={floreList} setSeatInfo={setSeatInfo} setWhereComponent={setWhereComponent} setSeatModalOpen={seatModalInit} setSeatList={setSeatList} seatList={seatList} floreInfo={floreInfo} setFloreInfo={setFloreInfo} />
                            :
                            <>
                                <Box
                                    align='center'
                                    position='sticky'
                                >
                                    <Flex
                                        my='5%'
                                        width='90%'
                                        align='center'
                                    >

                                        <Input
                                            width='90%'
                                            border='1px solid'
                                            borderColor='green.300'
                                            variant='auth'
                                            autoComplete="off"
                                            ms={{ base: "0px", md: "0px" }}
                                            placeholder='직원 이름을 검색하세요.'
                                            id="name"
                                            name="name"
                                            value={name}
                                            onChange={onChange}>
                                        </Input>
                                        <IconButton
                                            as={BsSearch}
                                            onClick={() => { searchUser() }}
                                            color="green.400"
                                            className="searchBtn"
                                            height='29px'
                                            width='29px'
                                        />


                                    </Flex>
                                    <Flex
                                        direction='column'>
                                        {
                                            floreList && floreList.map((flore, index) => (

                                                <Card
                                                    mt='3vh' h='7vh' minH='70px' w='90%' alignSelf='center' mx='auto' color='white'
                                                    backgroundColor={flore.active === flore.total ? 'red' : 'rgba(70, 209, 232, 1)'}
                                                    boxShadow={shadow}
                                                    bgPosition='10%'
                                                    key={index}
                                                    onClick={() => {
                                                        setFloreInfo(flore);
                                                        setWhereComponent('seat');
                                                    }}
                                                    isTruncated
                                                >
                                                    <Flex align='center'>
                                                        <Text mx='auto'>
                                                            {flore.flore}F
                                                        </Text>
                                                    </Flex>
                                                    <Text align='top' ml='auto'>
                                                        {flore.active}/{flore.total}
                                                    </Text>
                                                </Card>
                                            ))
                                        }
                                    </Flex>
                                </Box >
                                <Flex
                                    justifyContent='space-evenly'
                                    position='sticky'
                                    top='80vh'
                                    width='100%'
                                >
                                    <Button
                                        border='1px solid'
                                        borderColor='#C1B9B9'
                                        borderRadius='0px'
                                        width='40%'
                                        height='5vh'
                                        className="mainText"
                                        onClick={() => { modalChange1() }}
                                    >
                                        내 정보
                                    </Button>
                                    <Button
                                        border='1px solid'
                                        borderColor='#C1B9B9'
                                        borderRadius='0px'
                                        width='40%'
                                        height='5vh'
                                        className="mainText"
                                        onClick={() => { modalChange2(true) }}
                                    >
                                        예약 내역
                                    </Button>
                                </Flex>
                            </>
            }
        </>

    );
}
