// React
import React, { useEffect, useState } from "react";
import { Flex, Box, Text, Icon, useColorModeValue, Input, InputGroup, IconButton } from "@chakra-ui/react";
import axios from 'axios';
// Components
import Card from "../../../../components/card/Card.js";
// Assets
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { BsArrowLeft } from "react-icons/bs";
import { BsArrowCounterclockwise } from "react-icons/bs";
import OrderModal from "./modal/OrderModal.js";
/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function OrderDetail({ userInfo, setStoreComponent, sendOrder, orderOk, setOrderOk, optionDetail, discountList }) {


    const [orderModalOpen, setOrderModalOpen] = useState(false);
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    const [canPreviousPage, setCanPreviousPage] = useState(true);
    const [canNextPage, setCanNextPage] = useState(true);
    const [page, setPage] = useState(1);
    const [orderList, setOrderList] = useState([]);
    const [total, setTotal] = useState(1);
    function previousPage() {
        var now = page;
        if (page === 1) {
        } else {
            setPage(prevTxPage => prevTxPage - 1);
            rollPage(now - 1);
        }
    }
    function nextPage() {
        var now = page;
        if (page === total) {
        } else {
            setPage(prevTxPage => prevTxPage + 1);
            rollPage(now + 1);
        }
    }
    useEffect(() => {
        if (page === 1 && total === 1) {
            setCanNextPage(false);
            setCanPreviousPage(false);
        } else if (page === 1 && total > 1) {
            setCanPreviousPage(false);
            setCanNextPage(true);
        } else if (page === total) {
            setCanNextPage(false);
            setCanPreviousPage(true);
        } else {
            setCanNextPage(true);
            setCanPreviousPage(true);
        }
        if (total === 0) {
            setCanNextPage(false);
            setCanPreviousPage(false);
        }
    }, [page, total])
    useEffect(() => {
        axios.post('/api/product/totalOrder', {
            email: userInfo.email
        })
            .then((response) => {
                setTotal(response.data.totalPage);
            })
            .catch((error) => {
                // console.log(error);
            })
    }, [sendOrder, orderOk])
    useState(() => {
        var order = [];
        axios.post('/api/product/order', {
            email: userInfo.email,
            page: page
        })
            .then((response) => {
                for (var i = 0; i < response.data.order.length; i++) {
                    order.push({
                        "order_number": response.data.order[i].order.order_number,
                        "sender": response.data.order[i].order.sender,
                        "store_number": response.data.order[i].order.store_number,
                        "total_price": response.data.order[i].order.total_price,
                        "order_status": response.data.order[i].order.order_status,
                        "order_reg": response.data.order[i].order.order_reg,
                        "order_detail": response.data.order[i].orderDetail,
                        "order_comment": response.data.order[i].order.order_comment
                    })
                }
                setOrderList(order);
            })
            .catch((error) => {
                // alert(error);
            })
        if (orderOk) {
            setOrderOk(false);
        }
    }, [sendOrder, page, orderOk])

    const [orderInfo, setOrderInfo] = useState([]);
    const [orderDetailList, setOrderDetailList] = useState([]);
    const [orderOptionList, setOrderOptionList] = useState([]);
    const [orderProductOptionList, setOrderProductOptionList] = useState([]);
    
    useEffect(() => {
        if (orderInfo) {
            var detail = [];
            var productOption = [];

            axios.post('/api/product/orderDetail', {
                email: userInfo.email,
                order_number: orderInfo.order_number
            })
                .then((response) => {
                    for (var i = 0; i < response.data.orderDetail.length; i++) {
                        detail.push({
                            "product_name": response.data.orderDetail[i].product_name,
                            "product_number": response.data.orderDetail[i].product_number,
                            "product_quantity": response.data.orderDetail[i].product_quantity,
                            "price": response.data.orderDetail[i].price,
                            "category_name": response.data.orderDetail[i].category_name,
                            "category_big_name": response.data.orderDetail[i].category_big_name
                        })
                    }
                    setOrderDetailList(detail);
                    

                    for (var i = 0; i < response.data.orderProductOption.length; i++) {
                        productOption.push({
                            "order_number": response.data.orderProductOption[i].order_number,
                            "product_number": response.data.orderProductOption[i].product_number,
                            "option_name": response.data.orderProductOption[i].option_name,
                            "additional_price": response.data.orderProductOption[i].additional_price
                        })
                    }
                    setOrderProductOptionList(productOption);
                })
                .catch((error) => {
                    // alert(error);
                })

            var options = [];
            axios.post('/api/product/orderOption', {
                email: userInfo.email,
                order_number: orderInfo.order_number
            })
                .then((response) => {
                    if (response.data.orderOption) {
                        for (var i = 0; i < response.data.orderOption.length; i++) {
                            var option_name = "";
                            var additional_price = "";
                            for (var l = 0; l < optionDetail.length; l++) {
                                if (optionDetail[l].option_id === response.data.orderOption[i].option_id) {
                                    option_name = optionDetail[l].option_name;
                                    additional_price = optionDetail[l].additional_price;
                                }
                            }
                            options.push({
                                "order_number": response.data.orderOption[i].order_number,
                                "product_number": response.data.orderOption[i].product_number,
                                "option_id": response.data.orderOption[i].option_id,
                                "option_name": option_name,
                                "additional_price": additional_price
                            })
                        }
                    }
                    setOrderOptionList(options);
                })
                .catch((error) => {
                    alert(error);
                })
        }
    }, [orderInfo])
    function rollPage(pages) {
        var order = [];
        axios.post('/api/product/order', {
            email: userInfo.email,
            page: pages
        })
            .then((response) => {
                for (var i = 0; i < response.data.order.length; i++) {
                    order.push({
                        "order_number": response.data.order[i].order.order_number,
                        "sender": response.data.order[i].order.sender,
                        "store_number": response.data.order[i].order.store_number,
                        "total_price": response.data.order[i].order.total_price,
                        "order_status": response.data.order[i].order.order_status,
                        "order_reg": response.data.order[i].order.order_reg,
                        "order_detail": response.data.order[i].orderDetail,
                        "order_comment": response.data.order[i].order.order_comment
                    })
                }
                setOrderList(order);
            })
            .catch((error) => {
                // alert(error);
            })
    }
    function roll() {
        var order = [];
        axios.post('/api/product/order', {
            email: userInfo.email,
            page: page
        })
            .then((response) => {
                for (var i = 0; i < response.data.order.length; i++) {
                    order.push({
                        "order_number": response.data.order[i].order.order_number,
                        "sender": response.data.order[i].order.sender,
                        "store_number": response.data.order[i].order.store_number,
                        "total_price": response.data.order[i].order.total_price,
                        "order_status": response.data.order[i].order.order_status,
                        "order_reg": response.data.order[i].order.order_reg,
                        "order_detail": response.data.order[i].orderDetail,
                        "order_comment": response.data.order[i].order.order_comment
                    })
                }
                setOrderList(order);
            })
            .catch((error) => {
                // alert(error);
            })
    }
    function deleteOrder() {
        if (window.confirm("삭제하시겠습니까?")) {
            axios.post('/api/product/deleteOrder', {
                order_number: orderInfo.order_number
            })
                .then((response) => {
                    setOrderModalOpen(false);
                    roll();
                })
                .catch((error) => {
                    // alert(error);
                })
        }
    }

    const [searchList, setSearchList] = useState([]);

    // input data
    const [inputs, setInputs] = useState({
        order: ""
    });
    // input variable
    const { order } = inputs;
    function onChange(e) {
        if (e.target.value !== '<') {
            const { value, name } = e.target;
            setInputs({
                ...inputs,
                [name]: value,
            });
        } // if else end
    } // fn onChange end
    function findOrder() {
        const filteredList = orderList.filter((orders) =>
            orders.order_number.toLowerCase().includes(order.toLowerCase())
        );
        setSearchList(filteredList);
    }


    return (
        <>
            {orderModalOpen && <OrderModal titleText='주문 정보' orderInfo={orderInfo} orderDetailList={orderDetailList} userInfo={userInfo} onClose={() => setOrderModalOpen(false)} deleteOrder={deleteOrder} orderOptionList={orderOptionList} discountList={discountList} orderProductOptionList={orderProductOptionList} />}
            <Flex
                width='90%'
                p='10px'
                mx='auto'
                height='10vh'
                mb='2vh'
                direction='column'
            >
                <Flex>
                    <Icon className="backArrowOrder"
                        onClick={() => setStoreComponent('')}
                        as={BsArrowLeft}
                    />
                    <IconButton
                        className="storeRollBtn"
                        shadow={shadow}
                        backgroundColor='rgba(0,0,0,0)'
                        textColor='rgba(0, 100, 120, 1)'
                        // mr='5%'
                        ml='auto'
                        as={BsArrowCounterclockwise}
                        onClick={() => {
                            roll();
                        }}
                    >
                    </IconButton>
                </Flex>
                <InputGroup size='md'>
                    <Input
                        w='100%'
                        placeholder='주문번호 검색'
                        borderRadius='0'
                        border="1px solid" // 밑줄 두께 조절
                        borderColor="#008399" // 밑줄 색상 조절
                        variant='auth'
                        fontSize='md'
                        fontWeight='400'
                        id="order"
                        name="order"
                        value={order}
                        onChange={onChange}
                        onKeyUp={findOrder}>
                    </Input>
                </InputGroup>
            </Flex>
            <Card
                height='70vh'
                overflow='auto'
                backgroundColor='rgba(70, 209, 232, 0.5)'
                alignSelf='center'
                boxShadow={shadow}
                bgPosition='10%'
                mx='auto'
                width='90%'
                p='10px'>
                <Flex direction='column' h='100%' w='100%' justifyContent='space-between' overflow='auto'>
                    <Box overflowY='auto' className='table-box'>
                        {
                            order ?
                                searchList.map((order, index) => (
                                    <Card
                                        my='1%'
                                        borderRadius='5'
                                        alignSelf='center'
                                        mx='auto'
                                        width='100%'
                                        key={index}
                                        isTruncated
                                        onClick={() => {
                                            setOrderInfo(order);
                                            setOrderModalOpen(true);
                                        }}
                                    >
                                        <Flex direction='column'>
                                            <Text align='left'>
                                                주문시간&nbsp;{(order.order_reg).substring(0, 16).replace("_", " ")}
                                            </Text>

                                            <Flex justify='space-between'>
                                                {
                                                    order.order_detail.length > 0 ?
                                                        <>

                                                            <Text>

                                                                {order.order_detail[0].product_name}&nbsp; {order.order_detail[0].product_quantity}개
                                                            </Text>
                                                            {
                                                                order.order_detail.length > 1 ?
                                                                    <Text>
                                                                        &nbsp; 외, &nbsp;
                                                                        {order.order_detail.length - 1} 건
                                                                    </Text>
                                                                    :
                                                                    null
                                                            }
                                                        </>

                                                        :
                                                        null
                                                }
                                            </Flex>

                                            <Flex justify='space-between'>
                                                <Text>
                                                    {(order.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}원
                                                </Text>
                                                <Text>
                                                    {order.order_status === 'order' ?
                                                        "주문 접수"
                                                        :
                                                        order.order_status === 'ongoing' ?
                                                            "상품 제작중"
                                                            :
                                                            order.order_status === 'complete' ?
                                                                "픽업 완료"
                                                                :
                                                                order.order_status === 'reject' ?
                                                                    "주문 취소"
                                                                    :
                                                                    "완료"
                                                    }
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Card>
                                ))
                                :
                                orderList.map((order, index) => (
                                    <>
                                        <Card
                                            my='1%'
                                            borderRadius='5'
                                            alignSelf='center'
                                            mx='auto'
                                            width='100%'
                                            key={index}
                                            isTruncated
                                            onClick={() => {
                                                setOrderInfo(order);
                                                setOrderModalOpen(true);
                                            }}
                                        >
                                            <Flex direction='column'>
                                                <Flex justify='space-between' >
                                                    <Text align='left'>
                                                        주문번호
                                                    </Text>
                                                    <Text align='right'>
                                                        주문 시간
                                                    </Text>
                                                </Flex>

                                                <Flex justify='space-between' >
                                                    <Text align='left'>
                                                        {(order.order_number)}
                                                    </Text>
                                                    <Text align='right'>
                                                        {(order.order_reg).substring(0, 16).replace("_", " ")}
                                                    </Text>
                                                </Flex>

                                                <Flex justify='space-between'>
                                                    {
                                                        order.order_detail.length > 0 ?
                                                            <Flex>

                                                                <Text>

                                                                    {order.order_detail[0].product_name}&nbsp; {order.order_detail[0].product_quantity}개
                                                                </Text>
                                                                {
                                                                    order.order_detail.length > 1 ?
                                                                        <Text>
                                                                            &nbsp; 외, &nbsp;
                                                                            {order.order_detail.length - 1} 건
                                                                        </Text>
                                                                        :
                                                                        null
                                                                }
                                                            </Flex>

                                                            :
                                                            null
                                                    }
                                                </Flex>
                                                <Flex justify='space-between'>

                                                    <Text>
                                                        {(order.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}원
                                                    </Text>
                                                    <Text>
                                                        {order.order_status === 'order' ?
                                                            "주문 접수"
                                                            :
                                                            order.order_status === 'ongoing' ?
                                                                "상품 제작중"
                                                                :
                                                                order.order_status === 'complete' ?
                                                                    "픽업 완료"
                                                                    :
                                                                    order.order_status === 'reject' ?
                                                                        "주문 취소"
                                                                        :
                                                                        "완료"
                                                        }
                                                    </Text>
                                                </Flex>

                                            </Flex>
                                        </Card>
                                    </>
                                ))}
                    </Box>
                </Flex>
            </Card>
            <Flex align='center' justify='center'
                position='fixed'
                top='95%'
                left='50%'
                transform='translateX(-50%)' // Center the Flex container horizontally
            >
                <IconButton className="PageBtn" aria-label='Go to previous page' icon={<HiChevronLeft />} onClick={() => previousPage(0)} disabled={!canPreviousPage} />

                <Text>
                    {page} Page / {total}
                </Text>
                <IconButton className="PageBtn" aria-label='Go to next page' icon={<HiChevronRight />} onClick={() => nextPage(0)} disabled={!canNextPage} />
            </Flex>
        </>
    );
}
