// React
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Flex, Box, Text, Input, Icon, useColorModeValue } from "@chakra-ui/react";
import { BsArrowLeft } from "react-icons/bs";
// Components
import '../../../../css/main.css';
import { Button } from "react-bootstrap";
import Card from "../../../../components/card/Card";
import ScanLoading from "./ScanLoading";
import QRPayFinish from "./QRPayFinish.js";
import SecondPwd from "../confirm/SecondPwd.js";
import gradient3 from "../../../../assets/img/service/gradient3.jpeg";

/**
 * 
 * Props list
 *  - size: Refers to the screen size (pc/mobile)
 *  - qrSize: Refers to the size of QR code
 *  - accountContactList, setCheckAxios: for children
 * 
 */
export default function ScanPay({ setNowComponent, userInfo, transferReceiver }) {
    const [payComponent, setPayComponent] = useState('');
    const [secondPwdCheck, setSecondPwdCheck] = useState(false);
    const [alertText, setAlertText] = useState('');
    const shadow = useColorModeValue(
        "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
        "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
    );
    // input value to max 
    function autoMax() {
        setInputs({
            amount: (userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0
        });
        setAlertText('');
    }// fn autoMax end
    const [inputs, setInputs] = useState({
        amount: ""
    });
    // input variable
    const { amount } = inputs;
    function onChange(e) {
        if (e.target.value !== '<') {
            const pureKrc = e.target.value.replace(/[^0-9]/g, "").replaceAll(',', '');
            setInputs({
                ...inputs,
                amount: pureKrc.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            });
        } // if else end

    } // fn onChange end

    useEffect(() => {
        if (secondPwdCheck) {
            axios.post('/api/account/transfer', {
                amount: amount.replace(/[^0-9]/g, "").replaceAll(',', ''),
                target: transferReceiver.account_address
            }, {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))}`,
            }).then((response) => {         // api return
                setPayComponent('finish');
                setSecondPwdCheck(false);
            }).catch(error => {
                setAlertText('잠시 후 다시 시도해주세요.');
                setPayComponent('');
                setSecondPwdCheck(false);
            })
        }
    }, [secondPwdCheck])



    return (
        payComponent === 'finish' ?
            <QRPayFinish amount={amount} setNowComponent={setNowComponent} />
            :
            payComponent === 'second' ?
                <SecondPwd setComponent={setPayComponent} userInfo={userInfo} setSecondPwdCheck={setSecondPwdCheck} />

                :
                payComponent === 'loading' ?
                    <ScanLoading />
                    :
                    <>
                        <Box
                            width='100%'
                            height='100%'
                            align='center'
                        >
                            <Flex mt='3vh' ml='5vw'>
                                <Icon className="insideBackArrow" onClick={() => setNowComponent('')} color='black' as={BsArrowLeft} />
                            </Flex>

                            <Flex position='relative' top='3vh' w='90%' direction='column' align='center'>
                                <Card w='90%' backgroundImage={gradient3}
                                    backgroundRepeat='no-repeat'
                                    bgSize='cover'
                                    bgPosition='10%'>
                                    <Flex
                                        backgroundColor='white'
                                        direction='column'
                                        p='1%'>
                                        <Text
                                            align='left'
                                        >
                                            {transferReceiver.name}
                                        </Text>

                                        <Text
                                            align='left'
                                        >
                                            {(transferReceiver.account_address).substring(0, 15)}...
                                        </Text>
                                    </Flex>
                                </Card>

                                <Text
                                    className="qrScanPayMainText"
                                    my='5vh'
                                >
                                    송금금액
                                </Text>

                                <Flex align='flex-end'
                                    textAlign='end'
                                    mb='2vh'
                                    width='100%'
                                    mx='auto'
                                    justifyContent='center'>
                                    <Input
                                        className="mintAmountInput"
                                        w='70%'
                                        variant='auth'
                                        placeholder="금액을 입력하세요."
                                        textAlign='right'
                                        id="amount"
                                        name="amount"
                                        value={amount}
                                        onChange={onChange}>
                                    </Input>
                                    <Text
                                        className="qrScanCardText"
                                        ml='5px'
                                        mb='5px'
                                    >
                                        VETA
                                    </Text>
                                </Flex>

                                <Button className="payCardButtomBtn" align='center' boxShadow={shadow} onClick={() => autoMax()}>
                                    {
                                        alertText !== '' ?
                                            <Text className="alertText" color='red' pl='6px' align='left'>
                                                {alertText}
                                            </Text>
                                            :
                                            <Text pl='6px' color='#6F6F6F' align='left'>
                                                송금가능 : {(userInfo.amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0} VETA 입력
                                            </Text>
                                    }
                                </Button>

                            </Flex>
                            <Flex direction='column' className="qrScanPayNoticeBox" >

                                <Box
                                    textAlign='left'
                                    width='90%'
                                    mx='auto'>
                                    <Text
                                        width='90%'
                                        className="mintInsertSmallText"
                                    >
                                        송금 및 결제 시 유의사항
                                    </Text>
                                </Box>
                                <Box
                                    backgroundColor='rgba(217, 217, 217, 0.2)'
                                    className="mintNoticeText"
                                    align='center'
                                    p='3%'
                                >
                                    <Flex align='center' justify='center'>

                                        <Text>
                                            보내는 대상의&nbsp;
                                        </Text>
                                        <Text className="mintNoticeBoldText">
                                            이름 및 주소&nbsp;
                                        </Text>
                                        <Text>
                                            를
                                        </Text>
                                    </Flex>
                                    <Flex align='center' justify='center'>

                                        <Text>
                                            반드시 확인하세요.
                                        </Text>
                                    </Flex>
                                </Box>
                            </Flex>

                            <Flex
                                position='sticky'
                                top='70vh'
                                left='0'
                                width='100%'
                                height='7vh'
                                justify='center'
                                onClick={() => setPayComponent('second')}
                                backgroundColor={amount ? '#16CCEA' : '#C1B9B9'}
                            >
                                <Button
                                    textAlign=''
                                    height='5vh'
                                    width='80%'
                                    borderRadius='20px'
                                 
                                    disabled={!amount}
                                >
                                    <Text
                                        color='white'
                                        className="buttomBtnText"
                                    >
                                        송금
                                    </Text>
                                </Button>
                            </Flex>
                        </Box >
                    </>
    );
}
